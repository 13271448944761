import fetchData from '../../../utils/fetchData';
import updateData from '../../../utils/updateData';
import logError from '../../../utils/logError';

/**
 * Handles a single connection to the api
 */

class NotificationsApiHandler {
  constructor () {
    this.reset();
  }

  reset() {
    this.results = [];
    this.nextPageURL = null;
    this.connected = false;
    this.running = null;
  }

  /**
   * API method to get notifications
   * @param {boolean} next If api shuold call next page of results
   * @returns Promise
   */
  async call(next = false) {
    if (this.running) await this.running;
    if (this.connected && !next) return;
    this.connected = true;
    try {
      this.running = new Promise((resolve) => {
        this.resolve = resolve;
      });
      const url = this.nextPageURL || '/api/notification-messages/';
      const response = await fetchData(url);
      if (!response) return;
      this.results.push(...response.results);
      this.nextPageURL = response.next;
    } catch (e) {
      logError(e);
    }
    this.resolve();
  }

  /**
   * API method to mark notifications as read
   * @param {Array<number>} pkList List of pks of notifications to mark as read
   * @returns Promisse
   */
  async patchRead(pkList) {
    if (pkList.length === 0) return;
    try {
      const response = await updateData(
        `/api/notification-messages/mark-read/`,
        { json: { pks: pkList } },
        'PATCH',
        null,
        true
      );
      if (!response) return;
      if (response.code && response.code === 400) return;
      this.updateResults(response);
    } catch (e) {
      logError(e);
    }
  }

  /**
   * Method do update the results receivein a list of new results
   * @param {Array<any>} list List of new results
   */
  updateResults = (list) => {
    list.forEach((newItem) => {
      const index = this.results.findIndex(
        (oldItem) => newItem.pk === oldItem.pk
      );
      if (index > -1) {
        // original notification came through API
        this.results[index] = newItem;
      } else {
        // no original notification, it came through WebSocket and now is updated through API
        this.results.unshift(newItem);
      }
    });
  };
}

const NotificationsApi = new NotificationsApiHandler();

export default NotificationsApi;
