import {
  closeModal,
  handleButtonClick,
  trackSignupEvent,
} from '../utils/loginFunctions';

// Closes the modal when clicking away or on the X
function closeModalTrigger() {
  const modalBG = document.querySelector('.login-signup-modal__background');
  const modalClose = document.querySelectorAll('.login-signup-modal__close');

  if (modalBG) modalBG.addEventListener('click', closeModal);
  modalClose.forEach((closeBtn) => {
    closeBtn.addEventListener('click', closeModal);
  });
}
closeModalTrigger();

// Signup button
const loginSignupDialogSignupButton =
  document.querySelector('.js-signup-button');
if (loginSignupDialogSignupButton) {
  loginSignupDialogSignupButton.addEventListener('click', () => {
    trackSignupEvent(true); // signUp = true
  });
}

// Signin button
const loginSignupDialogSigninButton =
  document.querySelector('.js-signin-button');
if (loginSignupDialogSigninButton) {
  loginSignupDialogSigninButton.addEventListener('click', () => {
    trackSignupEvent(false); // signUp = false
  });
}

// SignUp event
const signUpForm = document.getElementById('signUpForm');
if (signUpForm) {
  signUpForm.addEventListener('accountCreated', () => {
    const url = new URL(window.location);
    url.searchParams.set('pendingMsg', true);
    window.location.assign(url);
  });
}

// Opens the 'Sign In to continue' modal
function startCTASignupFlowTrigger() {
  const startCTASignupFlowButtons = document.querySelectorAll(
    '.js-start-cta-signup-flow'
  );

  startCTASignupFlowButtons.forEach((button) => {
    button.addEventListener('click', handleButtonClick);
  });
}
startCTASignupFlowTrigger();

export default {
  startCTASignupFlowTrigger,
  closeModalTrigger,
};
