import React from 'react';
import useConversationList from './useConversationList';

/**
 * Custom hook to determine if a given conversation has been read or not
 * @param {*} conversation conversation to determine
 * @returns bool
 */
function useAllConversationReadState() {
  const conversations = useConversationList();
  const [conversationReadStates, setConversationReadStates] = React.useState([
    ...conversations.map((conversation) =>
      conversation.getIfConversationIsRead()
    ),
  ]);
  const [overallReadState, setOverallReadState] = React.useState(false);

  /**
   * Register necessary event listeners
   */
  React.useEffect(() => {
    function conversationReadHandler() {
      setConversationReadStates([
        ...conversations.map((conversation) =>
          conversation.getIfConversationIsRead()
        ),
      ]);
    }

    // Any time conversations change lets see if the new one is unread
    conversationReadHandler();

    conversations.forEach((conversation) => {
      conversation.on('conversation_read', conversationReadHandler);
    });

    return () => {
      conversations.forEach((conversation) => {
        conversation.off('conversation_read', conversationReadHandler);
      });
    };
  }, [conversations]);

  /**
   * Register necessary event listeners
   */
  React.useEffect(() => {
    const summary = conversationReadStates.indexOf(false) > -1;

    setOverallReadState(summary);
  }, [conversationReadStates]);

  return overallReadState;
}

export default useAllConversationReadState;
