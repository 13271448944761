import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';
import TagList from '../../../tags/tagList';

/**
 * Component for rendering brand contact request details for a Brand
 */
function ContactRequestMediaUserDetailsComponent({ conversation }) {
  const [requester, setRequester] = React.useState(null);

  React.useEffect(() => {
    setRequester(
      conversation
        .getOtherMembers()
        .find(
          (member) =>
            member.getUser().getPk() ===
              conversation.getContentObject().requested_by ||
            member.getUser().getPk() ===
              conversation.getContentObject().requested_by?.pk
        )
    );
  }, [conversation]);

  return requester ? (
    <div>
      <h3 className="conversation-detail-view__details-heading">Name:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-subject"
      >
        {requester.getUser().getFullName()}
      </span>
      {requester?.getUser()?.internalUserObject?.bio}
      {requester?.getUser()?.internalUserObject?.bio && (
        <h3 className="conversation-detail-view__details-heading">Bio:</h3>
      )}
      {requester?.getUser()?.internalUserObject?.bio && (
        <span
          className="conversation-detail-view__details-text"
          data-testid="brand-contact-subject"
        >
          {requester.getUser().internalUserObject.bio}
        </span>
      )}
      {requester?.getUser()?.internalUserObject?.media_outlets.length > 0}
      {requester?.getUser()?.internalUserObject?.media_outlets.length > 0 && (
        <h3 className="conversation-detail-view__details-heading">Outlets:</h3>
      )}
      {requester?.getUser()?.internalUserObject?.media_outlets.length > 0 && (
        <span
          className="conversation-detail-view__details-text"
          data-testid="brand-contact-deadline"
        >
          <TagList
            tags={requester.getUser().internalUserObject.media_outlets}
          />
        </span>
      )}
    </div>
  ) : null;
}

ContactRequestMediaUserDetailsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default ContactRequestMediaUserDetailsComponent;
