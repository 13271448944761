import React from 'react';
import PropTypes from 'prop-types';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Link,
  FormHelperText,
} from '@mui/material';
// eslint-disable-next-line max-len
import OptionsSelector from '../../../../../../../config/static/js/components/jsx/ui_elements/optionsSelector/OptionsSelector';
import fetchData from '../../../../../../../config/static/js/utils/fetchData';
import logError from '../../../../../../../config/static/js/utils/logError';

function PitchAIForm({ saveMethod, preSelectedMediaPk = null }) {
  const [errorMessage, setErrorMessage] = React.useState(null);

  // Pitch types states
  const pitchTypes = [
    { value: 'brand', label: 'Brand' },
    { value: 'product', label: 'Product' },
    { value: 'expert', label: 'Expert' },
  ];
  const [selectedPitchTypes, setSelectedPitchTypes] = React.useState([]);

  // Pitching options states
  const [pitchingOptionsIsLoading, setPitchingOptionsIsLoading] =
    React.useState(true);
  const [brands, setBrands] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [experts, setExperts] = React.useState([]);
  const [selectedPitchingOptions, setSelectedPitchingOptions] = React.useState(
    []
  );

  // Pitchinng tone states
  const [pitchToneOptions, setPitchToneOptions] = React.useState([]);
  const [pitchTone, setPitchTone] = React.useState('');

  // Other pitch states
  const [pitchReason, setPitchReason] = React.useState('');
  const [pitchStory, setPitchStory] = React.useState('');
  const [pitchCTA, setPitchCTA] = React.useState('');

  /**
   * Fetches the pitching options for the current user
   */
  React.useEffect(() => {
    setPitchingOptionsIsLoading(true);

    const getChoicesData = async () => {
      try {
        const response = await fetchData('/api/pitches/choices/');
        response.forEach((item) => {
          if (item.type === 'brand') setBrands(item.choices);
          if (item.type === 'product') setProducts(item.choices);
          if (item.type === 'expert') setExperts(item.choices);
        });
      } catch (error) {
        logError(error);
      } finally {
        setPitchingOptionsIsLoading(false);
      }
    };

    getChoicesData();
  }, []);

  /**
   * Get Pitching options objects formated for the OptionsSelector component
   */
  const getPitchingOptions = React.useCallback(() => {
    const options = [];

    if (selectedPitchTypes.includes('brand')) {
      brands.forEach((brand) => {
        options.push({
          value: `brand-${brand.id}`,
          label: brand.name,
          img: brand.image,
        });
      });
    }

    if (selectedPitchTypes.includes('expert')) {
      experts.forEach((expert) => {
        options.push({
          value: `expert-${expert.id}`,
          label: expert.name,
          img: expert.image,
        });
      });
    }

    if (selectedPitchTypes.includes('product')) {
      products.forEach((product) => {
        options.push({
          value: `product-${product.id}`,
          label: product.name,
          img: product.image,
        });
      });
    }

    return options;
  }, [brands, experts, products, selectedPitchTypes]);

  const pitchTypesIsSelected = React.useCallback(
    () => selectedPitchTypes.length > 0,
    [selectedPitchTypes]
  );
  const getPitchingOptionsHelperText = React.useCallback(
    () =>
      selectedPitchTypes.length === 0
        ? 'Select what you are pitching first'
        : undefined,
    [selectedPitchTypes]
  );

  /**
   * Get the selected pitching options objects
   * @returns {Array} The selected pitching options objects
   */
  const getSelectedPitchingOptions = React.useCallback(
    () => [
      ...selectedPitchingOptions.map((selected) => {
        const [type, pk] = selected.split('-');
        return { type, pk };
      }),
      { type: 'media-profile', pk: preSelectedMediaPk },
    ],
    [selectedPitchingOptions, preSelectedMediaPk]
  );

  /**
   * Validates form data before saving it
   */
  const validateFormData = React.useCallback(() => {
    if (!pitchTypesIsSelected()) {
      return 'Select what you are pitching first';
    }

    if (selectedPitchingOptions.length === 0) {
      return 'Select at least one brand, product or expert';
    }

    if (!pitchReason) {
      return 'Please tell us why you are pitching this';
    }

    if (!pitchStory) {
      return 'Please share what is unique about this pitch';
    }

    if (!pitchCTA) {
      return 'Please tell us why this is newsworthy now';
    }

    return null;
  }, [
    pitchTypesIsSelected,
    selectedPitchingOptions,
    pitchReason,
    pitchStory,
    pitchCTA,
  ]);

  /**
   * Save the form data
   */
  const saveFormData = React.useCallback(() => {
    const error = validateFormData();

    if (error) {
      setErrorMessage(error);
      return;
    }

    saveMethod({
      pitchingOptions: getSelectedPitchingOptions(),
      pitchReason,
      pitchStory,
      pitchCTA,
      pitchTone,
    });
  }, [validateFormData, getSelectedPitchingOptions]);

  /**
   * Fetches the tone options for pitching
   */
  React.useEffect(() => {
    const getToneData = async () => {
      try {
        const response = await fetchData('/api/pitches/tones/');
        setPitchToneOptions(response);
      } catch (error) {
        logError(error);
      }
    };

    getToneData();
  }, []);

  /**
   * Renders the select tone component
   * @returns {React.Component}
   */
  const getPitchingTones = React.useCallback(() => {
    const loadingState = pitchToneOptions.length === 0;
    const component = loadingState
      ? ''
      : pitchToneOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ));

    return (
      <FormControl fullWidth>
        <Select
          value={pitchTone}
          onChange={(event) => setPitchTone(event.target.value)}
          disabled={loadingState}
        >
          {component}
        </Select>
        {loadingState && <FormHelperText>Loading options...</FormHelperText>}
      </FormControl>
    );
  }, [pitchToneOptions, pitchTone]);

  /**
   * Selects first pitch tone by default
   * This is a workaround to avoid the user to submit the form without selecting a pitch tone
   */
  React.useEffect(() => {
    if (pitchToneOptions.length > 0) {
      setPitchTone(pitchToneOptions[0].value);
    }
  }, [pitchToneOptions]);

  const getLinkURL = React.useCallback(() => {
    if (preSelectedMediaPk) {
      return `/pitches/v2/create/?selectedmedia=${preSelectedMediaPk}`;
    }
    return '/pitches/v2/create/';
  }, [preSelectedMediaPk]);

  return (
    <>
      <DialogContent>
        <Box mb={1} sx={{ textAlign: 'right' }}>
          <Link href={getLinkURL()} variant="red">
            Pitch Manually
          </Link>
        </Box>
        <Typography variant="mediaDatabaseFilterTitle">
          What are you pitching?
        </Typography>
        <OptionsSelector
          options={pitchTypes}
          onChange={setSelectedPitchTypes}
        />
        <Typography variant="mediaDatabaseFilterTitle">
          Select your brands, products or experts
        </Typography>
        <OptionsSelector
          options={getPitchingOptions()}
          onChange={setSelectedPitchingOptions}
          loading={pitchingOptionsIsLoading}
          loadingText="Loading pitching options..."
          disabled={!pitchTypesIsSelected()}
          helperText={getPitchingOptionsHelperText()}
        />
        <Typography variant="mediaDatabaseFilterTitle">
          Why are you pitching this?
        </Typography>
        <TextField
          value={pitchReason}
          onChange={(event) => setPitchReason(event.target.value)}
          fullWidth
        />
        <Typography variant="mediaDatabaseFilterTitle">
          What is unique? What differentiates what you're doing from your
          competitors?
        </Typography>
        <TextField
          value={pitchStory}
          onChange={(event) => setPitchStory(event.target.value)}
          fullWidth
        />
        <Typography variant="mediaDatabaseFilterTitle">
          What makes this newsworthy now? Is it time sensitive or tied to
          seasonality or an event?
        </Typography>
        <TextField
          value={pitchCTA}
          required
          onChange={(event) => setPitchCTA(event.target.value)}
          fullWidth
        />
        <Typography variant="mediaDatabaseFilterTitle">
          Select the tone of the pitch
        </Typography>
        {getPitchingTones()}
        {errorMessage && (
          <Typography
            sx={{
              color: 'error.main',
              mt: 2,
              textAlign: 'center',
            }}
          >
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions variant="fullwidth">
        <Button
          onClick={saveFormData}
          variant="contained"
          color="primary"
          startIcon={<TipsAndUpdatesIcon />}
        >
          Generate Pitch
        </Button>
      </DialogActions>
    </>
  );
}

PitchAIForm.propTypes = {
  saveMethod: PropTypes.func.isRequired,
  preSelectedMediaPk: PropTypes.number,
};

export default PitchAIForm;
