import EventsClass from '../../../utils/Socketry/EventsClass';
import fetchData from '../../../../../utils/fetchData';
import logError from '../../../../../utils/logError';
import User from '../DataTypes/User';
import ErrorRouter from '../ErrorBanners/ErrorBanners';

/**
 * Implementation for identity retrieval.
 * Part of the EventsClass > PHIdentityLayer > ConversationsLayer > PHChatApi flow
 * These files are broken up for clarity
 */
export default class PHIdentityLayer extends EventsClass {
  constructor() {
    super();
    this.identity = {};
    this.identityStatus = 0;
  }

  /**
   * Async method to pull identity from the server
   */
  async determineIdentity() {
    this.fireEvent('user_identity_ready', 0);
    this.identityStatus = 0;
    try {
      const abortController = new AbortController();
      const res = await fetchData(
        '/api/users/current-user/',
        abortController.signal
      );
      this.setUserIdentity(res);
    } catch (e) {
      // report ready state up
      ErrorRouter.fireError(
        'general',
        'Failed to resolve user identity. Please try again.'
      );
      this.fireEvent('user_identity_ready', 2);
      this.identityStatus = 2;
      logError(e);
    }
  }

  /**
   * sets the user identity based on api res
   * @param {*} identity identity to set
   */
  setUserIdentity(identity) {
    this.internalUserIdentity = identity;
    this.identity = new User(identity, true);
    this.fireEvent('user_identity_ready', 1);
    this.identityStatus = 1;
  }

  /**
   * Get the identity of the currently logged in user
   * @returns User object
   */
  getIdentity() {
    return this.identity;
  }

  /**
   * Get the status of user identity
   * @returns integer, 0 = standby, 1 = ready, 2 = error
   */
  getIdentityStatus() {
    return this.identityStatus;
  }
}
