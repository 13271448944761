import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Sample request reason component
 */
function SampleRequestReasonComponent({ conversation }) {
  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="sample-request-reason"
    >
      {conversation.getContentObject().request_reason}
    </span>
  );
}

SampleRequestReasonComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SampleRequestReasonComponent;
