import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box } from '@mui/material';

/**
 * React component for the modal that allows users to add items to collection.
 * @param {object} props - React props.
 * @param {boolean} props.isOpen - Whether the modal is open.
 * @param {string} props.title - Title of the modal.
 * @param {boolean} props.closeOnClickOutside - Whether the modal can be closed by clicking outside.
 * @param {boolean} props.showCloseButton - Whether to show the close button.
 * @param {function} props.closeModal - Function to close the modal.
 * @param {React.ReactNode} props.children - The children of the modal.
 * @returns {JSX.Element}
 */
function CollectionModal({
  children,
  title = '',
  isOpen = false,
  setIsOpen = () => {},
  closeOnClickOutside = true,
  showCloseButton = true,
}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const handleModalClose = (_e, reason) => {
    if (reason === 'backdropClick' && !closeOnClickOutside) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Box
        sx={style}
        className="collections-modal__modal"
        data-testid="collectionsModal"
      >
        <div className="collections-modal__header">
          {showCloseButton && (
            <button
              className="collections-modal__close-btn"
              onClick={() => setIsOpen(false)}
            ></button>
          )}
          {title !== '' && (
            <div
              className={
                showCloseButton
                  ? 'collections-modal__title'
                  : 'collections-modal__title collections-modal__title--without-close-btn'
              }
            >
              {title}
            </div>
          )}
        </div>
        <div className="collections-modal__body">{children}</div>
      </Box>
    </Modal>
  );
}

CollectionModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  closeOnClickOutside: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

export default CollectionModal;
