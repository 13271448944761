import EmblaCarousel from 'embla-carousel';

const initSliders = () => {
  // sliders on home page and 404 page
  const sliders = document.querySelectorAll('.slider');
  sliders.forEach((slider) => {
    if (slider.classList.contains('slider--initialized')) return;

    const prevBtnNewProducts = slider.querySelector('.slider__previous');
    const nextBtnNewProducts = slider.querySelector('.slider__next');
    const emblaWrapNewProducts = slider.querySelector('.slider__content');
    const viewPortNewProducts =
      emblaWrapNewProducts.querySelector('.slider__viewport');
    const emblaNewProducts = EmblaCarousel(viewPortNewProducts, {
      loop: true,
      align: 'start',
      skipSnaps: false,
    });

    // Safely add event listeners if buttons are present
    if (prevBtnNewProducts) {
      prevBtnNewProducts.addEventListener(
        'click',
        emblaNewProducts.scrollPrev,
        false
      );
    }

    if (nextBtnNewProducts) {
      nextBtnNewProducts.addEventListener(
        'click',
        emblaNewProducts.scrollNext,
        false
      );
    }

    slider.classList.add('slider--initialized');
  });
};

initSliders();

document.body.addEventListener('htmx:load', () => {
  initSliders();
});
