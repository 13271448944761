/**
 * If an element <a> has the js-copy-href-on-click the default
 * behavior will be override and the href content will be copied
 * to the clipboard (it shows `copied!` text for a second inside the link)
 */

const link = document.querySelector('.js-copy-href-on-click');
if (link) {
  link.addEventListener('click', (event) => {
    event.preventDefault();

    const {href} = link;
    const currentContent = link.innerHTML;

    // Use the Clipboard API to copy the href
    navigator.clipboard
      .writeText(href)
      .then(() => {
        link.innerHTML = 'Link copied!';
        setTimeout(() => {
          link.innerHTML = currentContent;
        }, 1000);
      })
      .catch(() => {
        // fallbacks to open the link if this fails
        window.open(href, '_blank');
      });
  });
}
