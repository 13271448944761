import SocketConnection from '../Socketry/ChatSocketConnector';
import ConversationMessageBase from './ConversationLogic/ConversationMessageBase';
import ConversationMember from './ConversationMember';
import Message from './Message';

/**
 * Object representation of a Conversation
 */
export default class Conversation extends ConversationMessageBase {
  constructor(conversationObject) {
    super(conversationObject);

    this.members = conversationObject.members.map(
      (memberObject) => new ConversationMember(memberObject)
    );

    this.typeText = conversationObject.type_text;

    this.headerDetailText = '';

    this.createdAt = conversationObject.created_at;

    this.uuid = conversationObject.universal_id;

    this.typingUsers = [];

    this.setLastMessage(
      conversationObject?.last_message?.created_at
        ? new Message(conversationObject.last_message, this)
        : null
    );

    this.registerTypingMessageEvents();
    this.registerInvitationChangeEvents();

    this.detailSections = {};

    this.welcomeMessage = {
      title: 'This is the beginning of your conversation!',
      subtitle:
        "You'll be notified for every new message in this conversation.",
    };

    // format: { title: 'Link Title', href: 'https://example.org' }
    this.relatedLink = null;

    this.localInputContent = '';
  }

  /**
   * Sets the value for the local input
   * Allows it to be recalled when switching between conversations
   * @param {string} content
   */
  setLocalInputContent(content) {
    this.localInputContent = content;
  }

  /**
   * Get the local input content value
   * Used to recall the local input content when switching between conversations
   * @returns {string}
   */
  getLocalInputContent() {
    return this.localInputContent;
  }

  /**
   * Get the content of the conversation welcome message
   * @returns array an object with title, subtitle properties
   */
  // eslint-disable-next-line class-methods-use-this
  getWelcomeMessage() {
    return this.welcomeMessage;
  }

  /**
   * Change welcome message and fire event.
   */
  setWelcomeMessage(title = null, subtitle = null) {
    if (title) {
      this.welcomeMessage.title = title;
    }

    if (subtitle) {
      this.welcomeMessage.subtitle = subtitle;
    }

    this.fireEvent('welcome_message_updated', this);
  }

  /**
   * Returns related link for conversation
   * @returns
   */
  getRelatedLink(member) {
    const userProfileLink = member.user.getProfileURL()
      ? {
          title: 'View Profile',
          href: member.user.getProfileURL(),
        }
      : null;
    return this.relatedLink || userProfileLink;
  }

  /**
   * Get the detail sections for a given Conversation
   * @returns detail section object
   */
  getDetailSections() {
    return this.detailSections;
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns
   */
  getFlavorTextForMember(member) {
    if (member.getUser().getIfUserIsStaff()) {
      return 'Press Hook Staff';
    }

    if (this.getCustomFlavorTextForMember) {
      return this.getCustomFlavorTextForMember(member);
    }

    return 'Conversation Member';
  }

  /**
   * Returns the flavor text to be shown underneath the user's name inline in chat
   * @returns
   */
  getRoleTextForMember(member) {
    if (member.getUser().getIfUserIsStaff()) {
      return 'Press Hook';
    }

    if (this.getCustomRoleTextForMember) {
      return this.getCustomRoleTextForMember(member);
    }

    return '';
  }

  /**
   * Get the title of a conversation
   * @returns string
   */
  getTitle() {
    return this.getIdentity().getTitle();
  }

  /**
   * Get the subtitle of a conversation
   * @returns string
   */
  getSubtitle() {
    return this.getIdentity().getSubtitle();
  }

  /**
   * Get the icon URL of a conversation
   * @returns string
   */
  getIcon() {
    return this.getOtherActiveMembers().length === 1
      ? this.icon || this.getOtherMembers()[0].getUser().getProfileImage()
      : `${staticUrl}/images/svg/group_icon_large.svg`;
  }

  /**
   * Get the string describing the type of conversation
   * @returns string
   */
  getTypeText() {
    return this.typeText;
  }

  /**
   * Get the text to be displayed in the header of the conversation
   * @returns string
   */
  getHeaderDetailText() {
    // Return this no matter what if beyond 2 users to prevent erratic behavior.
    if (this.getMembers().length > 2) {
      return 'Group Conversation';
    }

    return this.headerDetailText;
  }

  /**
   * Get the UUID of a conversation
   * @returns string
   */
  getUUID() {
    return this.uuid;
  }

  /**
   * Get the members of this conversation
   * @returns array of ConversationMembers
   */
  getMembers() {
    return this.members;
  }

  /**
   * Get your own user identity
   * @returns ConversationMember
   */
  getIdentity() {
    return (
      this.getMembers().find((member) => member.getUser().getIsSelf()) || null
    );
  }

  /**
   * Get all users except self
   * @returns array of ConversationMember
   */
  getOtherMembers() {
    return this.getMembers().filter((member) => !member.getUser().getIsSelf());
  }

  /**
   * Get all users who are currently in some level of activity, INV or ACC
   * @returns array of ConversationMember
   */
  getActiveMembers() {
    return this.getMembers().filter((member) =>
      ['INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Get all users who are currently in some level of activity, INV or ACC
   * @returns array of ConversationMember
   */
  getOtherActiveMembers() {
    return this.getOtherMembers().filter((member) =>
      ['INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Gets the date that the conversation was last updated. (Either most recent message, or conversation creation date)
   * @returns Date object
   */
  getLastUpdatedDate() {
    let lastUpdatedDate;

    if (this.lastMessage?.getSendTime()) {
      lastUpdatedDate = new Date(this.lastMessage?.getSendTime());
    } else {
      lastUpdatedDate = new Date(this.createdAt);
    }

    return lastUpdatedDate;
  }

  /**
   * Send typing message
   */
  startTyping() {
    if (!this.nextTypingTime || this.nextTypingTime < Date.now()) {
      this.nextTypingTime = Date.now() + 8000;
    } else {
      return;
    }

    this.sendChannelEvent({
      action: 'user_typing',
      user_name: this.getIdentity().getUser().getFullName(),
      user_id: this.getIdentity().getPk(),
      expires_at: this.nextTypingTime + 2000,
    });
  }

  /**
   * Send typing message
   */
  stopTyping() {
    this.nextTypingTime = Date.now();
    this.sendChannelEvent({
      action: 'user_typing',
      user_name: this.getIdentity().getUser().getFullName(),
      user_id: this.getIdentity().getPk(),
      expires_at: Date.now() - 1000,
    });
  }

  /**
   * Send a payload to the channel events
   * @param {*} payload
   */
  sendChannelEvent(payload) {
    SocketConnection.send(
      JSON.stringify({
        type: 'chat_event',
        channel_id: this.getUUID(),
        payload: { ...payload, conversation: { universal_id: this.getUUID() } },
      })
    );
  }

  /**
   * Handler for received typing events
   * @param {*} payload
   * @returns void
   */
  userTypingHandler(event) {
    if (event.conversation.universal_id !== this.getUUID()) {
      return;
    }

    if (event.user_id === this.getIdentity().getPk()) {
      return;
    }
    const index = this.typingUsers.findIndex(
      (user) => user.user_id === event.user_id
    );

    if (index > -1) {
      this.typingUsers[index] = event;
    } else {
      this.typingUsers.push(event);
    }

    // Alert FE
    this.fireEvent('typing_users_changed', this);
  }

  /**
   * Get a list of currently typing users
   * @returns
   */
  getTypingUsers() {
    return this.typingUsers.filter((user) => user.expires_at > Date.now());
  }

  /**
   * Get the message to display at the bottom of the chat
   * area when a user is typing
   * @returns string
   */
  getNowTypingString() {
    const users = this.getTypingUsers();

    if (users.length === 0) {
      return '';
    }

    if (users.length > 1) {
      return 'Multiple users are typing...';
    }

    return `${users[0].user_name} is typing...`;
  }

  /**
   * Handler for if the user declines invitation.
   * @param {*} member
   */
  userInvitationStateHandler(member) {
    if (
      member.getActiveState() === 'REM' ||
      member.getActiveState() === 'DEC'
    ) {
      this.fireEvent('remove_conversation', this);
    }
  }

  /**
   * Register event listeners required for typing events to work
   */
  registerTypingMessageEvents() {
    SocketConnection.on('user_typing', this.userTypingHandler.bind(this));
  }

  memberStatusChangedHandler() {
    this.fireEvent('member_list_updated', this.getMembers());
  }

  /**
   * Handler for member additions/invites.
   * @param {*} event member add/invite socket event
   * @returns void
   */
  memberInvitedHandler(event) {
    if (event.conversation.universal_id !== this.getUUID()) {
      return;
    }

    const foundMember = this.getMembers().find(
      (member) => member.getPk() === event.member.pk
    );

    // If member already exists, update status.
    if (foundMember) {
      foundMember.setActiveState(event.member.status);
      return;
    }

    const newMember = new ConversationMember(event.member);

    newMember.on('status_changed', this.memberStatusChangedHandler.bind(this));

    this.members.push(newMember);
    this.fireEvent('member_list_updated', this.getMembers());
  }

  /**
   * Registers events required to track invitation/member changes.
   */
  registerInvitationChangeEvents() {
    SocketConnection.on(
      'member_invited_to_conversation',
      this.memberInvitedHandler.bind(this)
    );
    SocketConnection.on(
      'member_added_to_conversation',
      this.memberInvitedHandler.bind(this)
    );
    if (this.getIdentity()) {
      this.getIdentity().on(
        'status_changed',
        this.userInvitationStateHandler.bind(this)
      );
    }
    this.getMembers().forEach((member) => {
      member.on('status_changed', this.memberStatusChangedHandler.bind(this));
    });
  }
}
