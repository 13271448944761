import getCookie from './getCookie';

const deleteData = async (api, signal = null) => {
  if (!api) return undefined;
  const response = await fetch(api, {
    signal,
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
      'Content-Type': 'application/json',
    },
  });
  /*
    These status blocks are ignored because their logic is repetetive,
    and output will be tested elsewhere if an error handler is needed.
  */

  if (response.status === 400) {
    throw new Error(400);
  }

  if (response.status === 401) {
    window.location.assign(window.location);
    return undefined;
  }

  if (response.status === 403) {
    throw new Error(403);
  }

  if (response.status === 404) {
    throw new Error(404);
  }

  if (response.status === 500) {
    throw new Error(500);
  }
  return response;
};

export default deleteData;
