import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Sample request notes component
 */
function SampleRequestNotesComponent({ conversation }) {
  const content = `📝 ${
    conversation.getContentObject().request_comments
      ? conversation.getContentObject().request_comments
      : '-'
  }`;
  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="sample-request-notes"
    >
      {content}
    </span>
  );
}

SampleRequestNotesComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SampleRequestNotesComponent;
