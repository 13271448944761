import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import PitchAIModal from './PitchAIModal'; // Adjust the import path accordingly


function PitchButton({
  mediaPk = undefined,
  sx = {
    position: 'absolute',
    right: '19px',
    top: '57px',
  }
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Tooltip
        title="Pitch this media!"
        placement="right"
        arrow
      >
        <IconButton
          onClick={handleClickOpen}
          sx={sx}
          variant="border"
          color="red"
          aria-label="pitch media"
        >
          <CampaignIcon />
        </IconButton>
      </Tooltip>
      <PitchAIModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        preSelectedMediaPk={mediaPk}
      />
    </>
  );
}

PitchButton.propTypes = {
  mediaPk: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

export default PitchButton