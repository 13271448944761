import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Media Contact Request Media Outlets
 */
function MediaContactRequestMediaOutlets({ conversation }) {
  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="media-contact-request-outlets"
    >
      <div className="tags tags--card tags--wrap">
        {conversation
          .getContentObject()
          .media_user.media_outlets.map((outlet) => {
            return (
              <div className="tags__tag" key={outlet.pk}>
                {outlet.name}
              </div>
            );
          })}
      </div>
    </span>
  );
}

MediaContactRequestMediaOutlets.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default MediaContactRequestMediaOutlets;
