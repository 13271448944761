// Voting component can be inserted in any page
import React from 'react';
import ReactDOM from 'react-dom/client';
import VotingComponent from '../components/votingComponent';

const injectVotingComponent = () => {
  const reactTargets = document.getElementsByClassName('js-voting-component');
  for (let i = 0; i < reactTargets.length; i += 1) {
    const reactTarget = reactTargets[i];
    const { objectType, objectPk, getAction, mediaShareHash } =
      reactTarget.dataset;

    const root = ReactDOM.createRoot(reactTarget);
    root.render(
      <VotingComponent
        container={reactTarget}
        objectType={objectType}
        objectPk={parseInt(objectPk)}
        getAction={getAction}
        mediaShareHash={mediaShareHash}
      />
    );
  }
};

export default injectVotingComponent;
