import AdminIntervenableConversation from './AdminIntervenableConversation';
// eslint-disable-next-line max-len
import MediaContactRequestMediaSocialInfo from '../../ConversationDetailView/DetailsComponents/MediaContactRequestMediaSocialInfo';
import MediaContactRequestSubject from '../../ConversationDetailView/DetailsComponents/MediaContactRequestSubject';
// eslint-disable-next-line max-len
import MediaContactRequestMediaOutlets from '../../ConversationDetailView/DetailsComponents/MediaContactRequestMediaOutlets';
// eslint-disable-next-line max-len
import MediaContactRequestMediaInterests from '../../ConversationDetailView/DetailsComponents/MediaContactRequestMediaInterests';
// eslint-disable-next-line max-len
import BrandContactRequestBrandsAssociatedComponent from '../../ConversationDetailView/DetailsComponents/BrandContactRequestBrandsAssociatedComponent';
// eslint-disable-next-line max-len
import BrandContactRequestDetailsComponentRequestedByBrand from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentRequestedByBrand';
// eslint-disable-next-line max-len
import BrandContactRequestBrandTagsComponent from '../../ConversationDetailView/DetailsComponents/BrandContactRequestBrandTagsComponent';
// eslint-disable-next-line max-len
import BrandContactRequestDetailsComponentBrand from '../../ConversationDetailView/DetailsComponents/BrandContactRequestDetailsComponentBrand';

/**
 * Method for determining detail text for a Media Contact Request conversation
 * @param {*} contentObject contact request
 * @returns string
 */
function determineHeaderDetailText(contentObject) {
  return `Subject: ${contentObject.requested_by_brand.title}`;
}
/**
 * Class that implements custom header detail text and icon determination for Media Contact Request conversations.
 */
export default class MediaContactRequestConversation extends AdminIntervenableConversation {
  constructor(conversationObject) {
    super(conversationObject);

    const userIsRequester =
      this.getIdentity() &&
      this.getContentObject().requested_by.pk ===
        this.getIdentity().getUser().getPk();

    this.typeText = `Media Contact Request`;

    this.headerDetailText = determineHeaderDetailText(this.getContentObject());

    this.icon = this.getOtherMembersExcludingStaff()[0]
      .getUser()
      .getProfileImage();

    if (userIsRequester) {
      this.detailSections = {
        'Social Media': MediaContactRequestMediaSocialInfo,
        'Request Reason': MediaContactRequestSubject,
        Outlets: MediaContactRequestMediaOutlets,
        'Interested In': MediaContactRequestMediaInterests,
      };
      if (
        this.getContentObject().media_user.media_profile.has_social_media ===
        false
      ) {
        delete this.detailSections['Social Media'];
      }
    } else {
      this.detailSections = {
        // Conversation Details
        Details: BrandContactRequestDetailsComponentBrand,
        // HQ and Website
        'Brand Details': BrandContactRequestDetailsComponentRequestedByBrand,
        // Brand logo + name
        'Associated Brands': BrandContactRequestBrandsAssociatedComponent,
        // Associated Brand Tags
        'Brand Tags': BrandContactRequestBrandTagsComponent,
      };
    }

    // if user is requester, show media profile url
    if (userIsRequester) {
      const profileUrl = this.getContentObject().media_user.profile_url;
      this.relatedLink = profileUrl !== '' ? {
        title: 'View Media Profile',
        href: profileUrl,
      } : null;
    } else {
      this.relatedLink = {
        title: 'View Brand',
        href: `/brands/${this.getContentObject().requested_by_brand.slug}/`,
      };
    }
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns string
   */
  getCustomFlavorTextForMember(member) {
    return this.getContentObject().requested_by.pk === member.getUser().getPk()
      ? `Brand Rep @ ${this.getContentObject().requested_by_brand.title}`
      : 'Media Member';
  }

  /**
   * Returns the role text to be shown underneath a users name in the messaging area
   * @param {*} member
   * @returns string
   */
  getCustomRoleTextForMember(member) {
    return this.getContentObject().requested_by.pk === member.getUser().getPk()
      ? `Brand Rep @ ${this.getContentObject().requested_by_brand.title}`
      : 'Media Member';
  }
}
