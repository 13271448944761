import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function PriceTab({
  plan,
  isMostSelected,
  isLoading,
  billingPeriod,
  setSection,
  setSelectedPlan,
}) {
  return isLoading ? (
    <LoadingRectangle height={145} />
  ) : (
    <div
      className={
        !isMostSelected
          ? 'price-tab__card price-tab__card--selected-plan modal-detail-plan__price-tab-card'
          : 'price-tab__card price-tab__card--most-selected modal-detail-plan__price-tab-card'
      }
    >
      {isMostSelected && (
        <div className="price-tab__card-selected-label modal-detail-plan__price-tab-card-selected-label">
          <p className="price-tab__card-selected-label-text">
            our most selected plan
          </p>
        </div>
      )}

      <div
        className={
          !isMostSelected
            ? 'modal-detail-plan__card-content modal-detail-plan__card-content--always-columns'
            : 'modal-detail-plan__card-content'
        }
      >
        <div className={plan ? '' : 'modal-detail-plan__left'}>
          <h5 className="price-tab__card-title price-tab__card-title--no-starting modal-detail-plan__price-tab-card-title">
            {plan.name}
          </h5>

          {/* <!-- Card Price  --> */}
          <div
            className={
              billingPeriod === 'yearly'
                ? 'price-tab__card-price price-tab__card-price--annually'
                : 'price-tab__card-price'
            }
          >
            <h3 className="price-tab__card-price-text price-tab__card-price-text--month">
              ${(plan.monthly_price_usd_unit_amount * 1.0).toFixed(0)}
              <span className="price-tab__card-price-text-month">
                per month
              </span>
            </h3>
            <h3 className="price-tab__card-price-text price-tab__card-price-text--annual">
              ${(plan.yearly_price_usd_unit_amount * 1.0).toFixed(0)}
              <span className="price-tab__card-price-text-month">
                per month
              </span>
            </h3>
          </div>
          {plan.minimum_quantity > 1 && (
            <div className="price-tab__card-minimum">
              <span className="price-tab__card-minimum-text">
                (minimum {plan.minimum_quantity})
              </span>
            </div>
          )}
          {/* <!-- Card Price  -->*/}

          <p className="price-tab__card-desc modal-detail-plan__price-tab-card-desc">
            {plan.description}
          </p>
        </div>

        {/* <!-- Price Tab Card List --> */}
        <div
          className={
            !isMostSelected
              ? 'price-tab__card-lists price-tab__card-lists--hide-on-mobile'
              : 'price-tab__card-lists modal-detail-plan__right'
          }
        >
          <ul
            className={
              !isMostSelected
                ? 'price-tab__card-list'
                : 'price-tab__card-list price-tab__card-list--most-selected'
            }
          >
            {plan.features.map((feature, i) => (
              <li
                className={
                  !isMostSelected
                    ? 'price-tab__card-list-item'
                    : 'price-tab__card-list-item price-tab__card-list-item--most-selected'
                }
                key={i}
              >
                <img
                  className="price-tab__card-list-image"
                  src={`${staticUrl}/images/svg/price-tick.svg`}
                  alt="tick"
                />
                <p className="price-tab__card-list-text">
                  {feature.description}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {/* <!-- Price Tab Card List --> */}
      </div>

      {isMostSelected && (
        <a
          className="cta-btn cta-btn--wider cta-btn--full-width price-tab__card-btn price-tab__card-btn--most-selected modal-detail-plan__price-tab-card-btn--most-selected"
          onClick={() => {
            setSelectedPlan(plan);
            setSection('Checkout');
          }}
        >
          Get Started with {plan.name}
        </a>
      )}
    </div>
  );
}

export default PriceTab;
