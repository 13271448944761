import React from 'react';
import ReactDOM from 'react-dom/client';
import Toastify from 'toastify-js';
import BaseToast from './BaseToast';

/**
 * Shows the toast with a message
 */
const showToast = (
  message,
  gravity = 'top',
  position = 'center',
  stopOnFocus = true,
  duration = 5000
) => {
  const toastNode = document.createElement('div');
  toastNode.classList.add('ph-toast__inner');

  const root = ReactDOM.createRoot(toastNode);
  root.render(<BaseToast content={message} />);

  Toastify({
    node: toastNode,
    className: 'ph-toast',
    gravity,
    position,
    stopOnFocus,
    duration,
  }).showToast();
};

export default showToast;
