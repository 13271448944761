import SocketConnection from '../../Socketry/ChatSocketConnector';
import DjangoModel from '../DjangoModel';
import Message from '../Message';
import fetchData from '../../../../../../utils/fetchData';
import logError from '../../../../../../utils/logError';
import ErrorRouter from '../../ErrorBanners/ErrorBanners';

// We need a pk for newly created message, so we'll just go negative.
let lastPk = -2;

/**
 * Conversation message logic class.
 * Designed to be extended by Conversation
 */
export default class ConversationMessageBase extends DjangoModel {
  constructor(conversationObject) {
    super(conversationObject);

    this.uuid = conversationObject.universal_id;

    this.lastMessage = null;

    this.messages = [];
    this.reachedEndOfMessages = false;
    this.messagesReady = false;
    this.conversationRead = true;
    this.registerMessageListeners();
  }

  /**
   * Get if the conversation has been read or not
   * @returns boolean
   */
  getIfConversationIsRead() {
    return this.conversationRead;
  }

  /**
   * Set a new last message and update read status
   * @param {*} message message sto set
   * @returns void
   */
  setLastMessage(message) {
    if (!message) {
      return;
    }

    this.lastMessage = message;
    this.conversationRead = this.lastMessage.getIfReadBySelf();
    this.fireEvent('conversation_read', this.getIfConversationIsRead());
  }

  /**
   * Get the last message in this conversation
   * @returns message or null
   */
  getLastMessage() {
    return this.lastMessage;
  }

  /**
   * Reset messages and request new ones
   */
  resetMessages() {
    this.messages = [];
    this.messagesReady = false;
    this.reachedEndOfMessages = false;
    this.requestMessages();
  }

  /**
   * Get the currently loaded messages for this conversation
   * @returns list of messages
   */
  getMessages() {
    return this.messages;
  }

  /**
   * Returns boolean indicating if all available messages have been loaded
   * @returns boolean
   */
  getIfEndOfConversationReached() {
    return this.reachedEndOfMessages;
  }

  /**
   * Method to request messages from the API.
   * @param {*} more boolean indicating if we're summoning for the first time or subsequently
   */
  requestMessages(more = false) {
    let page = 1;
    if (more) {
      if (this.reachedEndOfMessages) {
        return;
      }
      this.fireEvent('messages_requested', true);
      page += Math.floor(this.messages.length / 30);
    } else if (this.messagesReady) {
      return;
    }

    this.callApiForMessage(page);
  }

  /**
   * async method to retrieve messages from API
   * @param {*} page
   */
  async callApiForMessage(page = 1) {
    try {
      const abortController = new AbortController();
      const apiRes = await fetchData(
        `/api/chat/messages/?universal_id=${this.getUUID()}&p=${page}`,
        abortController.signal
      );
      if (!this.messagesReady) {
        this.messagesReady = true;
        this.fireEvent('messages_ready', true);
      }
      if (!apiRes.next) {
        this.reachedEndOfMessages = true;
      }
      if (page === 1) {
        this.messages = [];
      }
      this.importMessages(apiRes.results);
    } catch (e) {
      // Messages failed
      if (!this.messagesReady) {
        // If initial request autoretry
        ErrorRouter.fireError(
          'general',
          'Failed to retrieve messages. Retrying...'
        );
        setTimeout(this.resetMessages.bind(this), 2000);
      } else {
        // Otherwise request retry.
        ErrorRouter.fireError(
          'general',
          'Failed to retrieve messages. Please try again.'
        );
      }
      this.reportMessageChanges();
      logError(e);
    }
  }

  /**
   * Import messages from API without duplicating existing messages.
   * The assumption is made the the correct page is being passed to this method.
   * It will do the logic of figuring out which messages from the API are already in the internal list.
   * @param {*} messages messages to import
   */
  importMessages(messages) {
    const messageObjects = messages
      .map((message) => new Message(message, this))
      .reverse();
    const currentRemainder = this.messages.length % 30;
    messageObjects.splice(0, currentRemainder);
    this.messages.unshift(...messageObjects);
    this.reportMessageChanges();
  }

  /**
   * Add a new message to the list
   * @param {*} message message to add
   */
  newMessageHandler(message) {
    if (message.conversation.universal_id !== this.getUUID()) {
      return;
    }

    const convertedMessage = new Message(message, this);

    if (convertedMessage.getSender().getUser().getIsSelf()) {
      return;
    }

    this.addMessage(convertedMessage);
  }

  /**
   * Handler for message being read
   * @param {*} event
   * @returns
   */
  handleMessageRead(event) {
    if (event.member.pk !== this.getIdentity()?.getPk()) {
      return;
    }
    if (event.message.pk !== this.getLastMessage()?.getPk()) {
      return;
    }

    this.conversationRead = true;
    this.fireEvent('conversation_read', this.getIfConversationIsRead());
  }

  /**
   * Add message to the list without converting to object
   * @param {*} Message message to add
   */
  addMessage(message) {
    this.messages.push(message);
    this.reportMessageChanges();
  }

  /**
   * Method to register event listeners for socket message events
   */
  registerMessageListeners() {
    SocketConnection.on('new_message', this.newMessageHandler.bind(this));
    SocketConnection.on('message_read', this.handleMessageRead.bind(this));
  }

  /**
   * Fire the event to report messages have been updated.
   */
  reportMessageChanges() {
    this.setLastMessage(
      this.messages.length > 0 ? this.messages[this.messages.length - 1] : null
    );
    this.fireEvent('new_message', this.getLastMessage());
    this.fireEvent('new_message_toast', {
      message: this.getLastMessage(),
      conversation: this,
    });
    this.fireEvent('messages_updated', this.getMessages());
  }

  /**
   * Get if messages from the API are ready yet.
   * @returns
   */
  getIfMessagesReady() {
    return this.messagesReady;
  }

  /**
   * Send a message as the current user
   * @param {*} content content to send
   */
  sendMessage(content) {
    const newMessage = new Message(
      { message: content, pk: (lastPk -= 1), sender: this.getIdentity() },
      this,
      true
    );

    this.addMessage(newMessage);

    newMessage.postSelf();
  }
}
