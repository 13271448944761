const pricingSwitch = document.getElementById('pricingToggleSwitch');

if (pricingSwitch) {
  pricingSwitch.checked = false;
  pricingSwitch.addEventListener('change', togglePricingInterval);
}

function togglePricingInterval(event) {
  const targets = [
    ...document.getElementsByClassName('pricing-cards__tier-price'),
    ...document.getElementsByClassName(
      'pricing-cards__signup-btn--tier-selector'
    ),
    ...document.getElementsByClassName('pricing-modal__interval-target'),
  ];

  for (const target of targets) {
    if (event.target.checked) {
      target.classList.add('toggled');
    } else {
      target.classList.remove('toggled');
    }
  }

  const optionLabel1 = document.getElementById('pricingGridOption1');
  const optionLabel2 = document.getElementById('pricingGridOption2');

  if (event.target.checked) {
    optionLabel1.classList.add('pricing-grid-options__option--deselect');
    optionLabel2.classList.remove('pricing-grid-options__option--deselect');
  } else {
    optionLabel2.classList.add('pricing-grid-options__option--deselect');
    optionLabel1.classList.remove('pricing-grid-options__option--deselect');
  }
}
