// handles expanding footer nav items
const mediaLink = document.getElementById('mediaLink');
const brandLink = document.getElementById('brandLink');
const companyLink = document.getElementById('companyLink');

if (mediaLink && brandLink && companyLink) {
  //temporary fix so new SCSS pages don't break

  mediaLink.addEventListener('click', (event) => {
    const links = mediaLink.querySelector('.media-links');
    if (links.classList.contains('mobile-hide')) {
      links.classList.remove('mobile-hide');
      links.classList.add('mobile-show');
    } else if (links.classList.contains('mobile-show')) {
      links.classList.remove('mobile-show');
      links.classList.add('mobile-hide');
    }
  });

  brandLink.addEventListener('click', (event) => {
    const links = brandLink.querySelector('.brand-links');
    if (links.classList.contains('mobile-hide')) {
      links.classList.remove('mobile-hide');
      links.classList.add('mobile-show');
    } else if (links.classList.contains('mobile-show')) {
      links.classList.remove('mobile-show');
      links.classList.add('mobile-hide');
    }
  });

  companyLink.addEventListener('click', (event) => {
    const links = companyLink.querySelector('.company-links');
    if (links.classList.contains('mobile-hide')) {
      links.classList.remove('mobile-hide');
      links.classList.add('mobile-show');
    } else if (links.classList.contains('mobile-show')) {
      links.classList.remove('mobile-show');
      links.classList.add('mobile-hide');
    }
  });
}

// hide footer on certain pages
const footerContainer = document.querySelector('.footer');
if (
  window.location.href.indexOf('brand-account') > -1 ||
  window.location.href.indexOf('register') > -1 ||
  window.location.href.indexOf('login') > -1
) {
  footerContainer.classList.add('dnone');
}

// New JS for SCSS refactoring
const footerLinks = document.querySelectorAll('.footer__col');

footerLinks.forEach((el) => {
  el.addEventListener('click', (event) => {
    const list = el.querySelector('.footer__list');
    if (list) list.classList.toggle('footer__list--active');
  });
});

