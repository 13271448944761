// new modal logic below should be abstracted more to handle more than sample requests

// TODO: This is a new reusable pattern for modals in general. Lets use it and update other places to use it.
// just add js-open-modal class and data-modal=".someSelector" to whatever should open the modal
// can add data-modal-title="some title" to set the title of the modal

const body = document.querySelector('body');

function bindModalTriggers(context = document) {
  const modalOpeners = context.querySelectorAll('.js-open-modal');
  Array.from(modalOpeners).forEach((triggerElement) => {
    const targetModalSelector = triggerElement.dataset.modal;
    const title = triggerElement.dataset.modalTitle;
    const modalElement = document.querySelector(targetModalSelector);
    if (modalElement) {
      const pageBody = body;
      triggerElement.addEventListener('click', () => {
        modalElement.style.display = 'block';
        pageBody.classList.add('body-sticky');

        const closeButtons = modalElement.querySelectorAll('.close');
        Array.from(closeButtons).forEach((closeButton) => {
          closeButton.addEventListener('click', () => {
            modalElement.style.display = 'none';
            pageBody.classList.remove('body-sticky');
          });
        });
        if (title) {
          const modalTitleElement =
            modalElement.querySelector('.js-modal-title');
          modalTitleElement.innerHTML = title;
        }
      });
    }
  });
}

// Initial binding of modal triggers on page load
bindModalTriggers();

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('htmx:afterOnLoad', (event) => {
    bindModalTriggers(event.detail.elt); // event.detail.elt refers to the newly loaded content
  });
});

// Contact brand modal
function contactBrandButtonComponent() {
  const theBody = body || document.querySelector('body');

  const contactBrandBtn = document.querySelectorAll(
    '.contact-brand-modal-anchor'
  );
  const contactBrandModal = document.getElementById('contactBrandModal');

  if (contactBrandBtn) {
    contactBrandBtn.forEach((item) => {
      item.addEventListener('click', () => {
        contactBrandModal.style.display = 'block';
        theBody.classList.add('body-sticky');
      });
    });
  }

  if (contactBrandModal) {
    const closeButtons = contactBrandModal.querySelectorAll('.close2');
    Array.from(closeButtons).forEach((closeButton) => {
      closeButton.addEventListener('click', () => {
        contactBrandModal.style.display = 'none';
        theBody.classList.remove('body-sticky');
      });
    });
  }

  // If the user clicks anywhere outside of the modal, close it
  if (contactBrandModal) {
    window.addEventListener('click', (event) => {
      if (event.target === contactBrandModal) {
        contactBrandModal.style.display = 'none';
        theBody.classList.remove('body-sticky');
      }
    });
  }
}

// triggers the component
contactBrandButtonComponent();
export default contactBrandButtonComponent;

// Contact media modal
const contactMediaBtn = document.querySelectorAll(
  '.contact-media-modal-anchor'
);
const contactMediaModal = document.getElementById('contactMediaModal');

if (contactMediaBtn) {
  contactMediaBtn.forEach((item) => {
    item.addEventListener('click', () => {
      contactMediaModal.style.display = 'block';
      body.classList.add('body-sticky');
    });
  });
}

if (contactMediaModal) {
  const closeButtons = contactMediaModal.querySelectorAll('.close2');
  Array.from(closeButtons).forEach((closeButton) => {
    closeButton.addEventListener('click', () => {
      contactMediaModal.style.display = 'none';
      body.classList.remove('body-sticky');
    });
  });
}

// If the user clicks anywhere outside of the modal, close it
if (contactMediaModal) {
  window.addEventListener('click', (event) => {
    if (event.target === contactMediaModal) {
      contactMediaModal.style.display = 'none';
      body.classList.remove('body-sticky');
    }
  });
}

const triggerUpgradeBrandModal = document.getElementById(
  'upgradeBrandModalTrigger'
);

if (triggerUpgradeBrandModal) {
  const upgradeModal = document.getElementById('upgradeBrandModal');
  upgradeModal.classList.add('ph-modal--open');
  body.classList.add('body-sticky');
}
