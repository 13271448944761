import React from 'react';
import PropTypes from 'prop-types';

/**
 * A menu that can be positioned anywhere on the page.
 * For dropdowns for conversation/message options.
 */
function AbsoluteMenu({
  x,
  y,
  show,
  handleDismiss,
  children,
  buttonRef,
  blank = false,
  selfAlign = false,
}) {
  const container = React.useRef(null);

  /**
   * Handler for click events. If outside menu container, trigger dismissal.
   * @param {*} event click event to handle
   */
  function handleClicks(event) {
    if (
      container.current &&
      !container.current.contains(event.target) &&
      !buttonRef.current.contains(event.target) &&
      show
    ) {
      event.preventDefault();
      handleDismiss();
    }
  }

  /**
   * Effect to register click event handlers
   */
  React.useEffect(() => {
    document.addEventListener('click', handleClicks, true);
    window.addEventListener('resize', () => handleDismiss(), true);
    return () => {
      document.removeEventListener('click', handleClicks, true);
      window.removeEventListener('resize', () => handleDismiss(), true);
    };
  }, [container, show, handleDismiss, buttonRef]);

  return (
    show && (
      <div
        ref={container}
        className={`chat-absolute-menu__container
          ${blank && 'chat-absolute-menu__container--blank'}
          ${selfAlign && 'chat-absolute-menu__container--self-align'}
        `}
        data-testid="absolute-position-container"
        style={{ top: `${y}px`, left: `${x}px` }}
      >
        {children}
      </div>
    )
  );
}

AbsoluteMenu.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  blank: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  selfAlign: PropTypes.bool,
};

export default AbsoluteMenu;
