// upgradeBrandModal.js

const upgradeBrandModal = document.getElementById('upgradeBrandModal');
const upgradeBrandBtn = document.querySelectorAll('.js-upgrade-brand');

if (upgradeBrandModal) {
  const modalClose = upgradeBrandModal.querySelector('#modalClose');

  modalClose.addEventListener('click', () => {
    upgradeBrandModal.classList.remove('ph-modal--open');
  });

  // If the user clicks the button, open the modal
  if (upgradeBrandBtn) {
    upgradeBrandBtn.forEach((button) => {
      button.addEventListener('click', () => {
        upgradeBrandModal.classList.add('ph-modal--open');
      });
    });
  }

  // If the user clicks anywhere outside of the modal, close it
  window.onclick = (event) => {
    if (event.target === upgradeBrandModal) {
      upgradeBrandModal.classList.remove('ph-modal--open');
    }
  };
}
