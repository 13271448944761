import SocketConnector from '../../../utils/Socketry/SocketConnector';

/**
 * Class that connects to socket chat server
 * Exported only for testing purposes.
 * Live version will use a single, global version
 */
export class ChatSocketConnector extends SocketConnector {
  constructor(testUrl = null) {
    super('chat-server', 'chat_event', testUrl);
  }

  /**
   * Router for front-end events
   * @param {*} payload
   */
  handleFrontEndCommunication(payload) {
    if (payload.action === 'user_typing') {
      this.fireEvent('user_typing', payload);
    }
  }

  /**
   * Router for channel events
   * @param {*} event
   */
  handleChannelEvent(event) {
    if (event.action === 'new_message') {
      this.fireEvent('new_message', event.payload);
    } else if (event.action === 'conversation_created') {
      this.fireEvent('conversation_created', event.payload);
    } else if (event.action === 'member_invited_to_conversation') {
      this.fireEvent('member_invited_to_conversation', {
        member: event.payload,
        conversation: event.payload.conversation,
      });
    } else if (event.action === 'member_added_to_conversation') {
      this.fireEvent('member_added_to_conversation', {
        member: event.payload,
        conversation: event.payload.conversation,
      });
    } else if (event.action === 'member_accepted_invite') {
      this.fireEvent('member_accepted_invite', {
        member: event.payload,
        conversation: event.payload.conversation,
      });
    } else if (event.action === 'member_declined_invite') {
      this.fireEvent('member_declined_invite', {
        member: event.payload,
        conversation: event.payload.conversation,
      });
    } else if (event.action === 'member_removed_from_conversation') {
      this.fireEvent('member_removed_from_conversation', {
        member: event.payload,
        conversation: event.payload.conversation,
      });
    } else if (event.action === 'message_read') {
      this.fireEvent('message_read', {
        message: event.payload.message,
        member: event.payload.read_by,
      });
    }
  }
}

// One global instance to share
const SocketConnection = new ChatSocketConnector();

export default SocketConnection;
