import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Media Contact Request Media Interests
 */
function MediaContactRequestMediaInterests({ conversation }) {
  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="media-contact-request-interests"
    >
      <div className="tags tags--card tags--wrap">
        {conversation
          .getContentObject()
          .media_user.media_profile.interests.map((interest) => {
            return (
              <div className="tags__tag" key={interest.pk}>
                {interest.name}
              </div>
            );
          })}
      </div>
    </span>
  );
}

MediaContactRequestMediaInterests.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default MediaContactRequestMediaInterests;
