import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import fetchData from '../../../../../../config/static/js/utils/fetchData';
import updateData from '../../../../../../config/static/js/utils/updateData';
import '../../scss/index.scss';
import logError from '../../../../../../config/static/js/utils/logError';
import { handleButtonClick } from '../../../../../../config/static/js/utils/loginFunctions';

/**
 * React component to embed thumbs up/thumbs down voting.
 *
 * It loads any time you add a DOM
 * element with the `voting-component` css class, data-object-pk and
 * data-object-type on it's attributes.
 *
 * You can also add an optional 'data-target-selector' attribute to the element
 * to specify the selector of the element that will be updated with the new vote
 * value, so we can target it with effects in the dom.
 *
 * Example:
 * <div class="voting-component"
 *    data-object-pk="1"
 *    data-object-type="pitch"
 *    data-target-selector="#parentElement">
 * </div>
 *
 * It also can be rendered being externally controlled, passing values to its props.
 *
 * Example:
 * <VotingComponent objectPk={1} objectType="pressrelease" />
 *
 */

function VotingComponent({ objectPk, objectType, getAction, mediaShareHash }) {
  const [scopeObjectType] = React.useState(objectType);
  const [scopeObjectPk] = React.useState(objectPk);
  const [targetSelector] = React.useState(targetSelector);
  const [vote, setVote] = React.useState(0);
  const [action, setAction] = React.useState(getAction);

  const fetchVote = async () => {
    const getParams = mediaShareHash ? `?hash=${mediaShareHash}` : '';
    const url = `/api/votes/${scopeObjectType}/${scopeObjectPk}/${getParams}`;
    const abortController = new AbortController();
    const returnErrors = true;
    return await fetchData(url, abortController.signal, returnErrors);
  };

  const putVote = async (vote) => {
    const url = `/api/votes/${scopeObjectType}/${scopeObjectPk}/`;
    const abortController = new AbortController();
    const returnErrors = false;
    return await updateData(
      url,
      {
        json: {
          object_id: scopeObjectPk,
          content_type: scopeObjectType,
          vote: vote,
        },
      },
      'PUT',
      abortController.signal,
      returnErrors
    );
  };

  const handleVote = (vote) => async () => {
    // Do not vote if media shared
    if (mediaShareHash) return;

    const res = await putVote(vote);
    if (res.vote) {
      setVote(res.vote);
      if (targetSelector) {
        const target = document.querySelector(targetSelector);
        if (target) {
          // Do something to the target element
        }
      }
    } else {
      logError(res);
    }
  };

  useEffect(() => {
    (async () => {
      if (!mediaShareHash && action && action.startsWith('vote')) {
        if (action === 'voteup') handleVote(1)();
        if (action === 'votedown') handleVote(-1)();
        setAction(null);
      } else {
        try {
          const res = await fetchVote();
          if (res.vote) {
            setVote(res.vote);
          }
        } catch (error) {
          // Vote doesn't exist yet. No error message needed.
          if (error.message === 404) return;
        }
      }
    })();
  }, [scopeObjectPk]);

  return (
    <div className="voting">
      <a
        type="button"
        data-testid="votingButtonUp"
        className={`voting__button voting__button--up ${
          vote === 1 ? 'voting__button--active' : ''
        } ${mediaShareHash ? 'js-start-cta-signup-flow' : ''}`}
        onClick={mediaShareHash ? handleButtonClick : handleVote(1)}
        data-vote="1"
        data-action="voteup"
      >
        <img
          src={`${staticUrl}/images/svg/icon-thumbs-up.svg`}
          data-action="voteup"
          loading="lazy"
          decoding="async"
        ></img>
      </a>
      <a
        type="button"
        data-testid="votingButtonDown"
        className={`voting__button voting__button--down ${
          vote === -1 ? 'voting__button--active' : ''
        } ${mediaShareHash ? 'js-start-cta-signup-flow' : ''}`}
        onClick={mediaShareHash ? handleButtonClick : handleVote(-1)}
        data-vote="-1"
        data-action="votedown"
      >
        <img
          src={`${staticUrl}/images/svg/icon-thumbs-down.svg`}
          data-action="votedown"
          loading="lazy"
          decoding="async"
        ></img>
      </a>
    </div>
  );
}

VotingComponent.propTypes = {
  objectPk: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
  targetSelector: PropTypes.string,
  getAction: PropTypes.string,
  mediaShareHash: PropTypes.string,
};

export default VotingComponent;
