import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Skeleton, Button, Modal } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'; // For the bookmark outline icon
import BookmarkIcon from '@mui/icons-material/Bookmark'; // For the bookmark filled icon
import AddToCollectionModal from './AddToCollectionModal';
import fetchData from '../../../../../../../config/static/js/utils/fetchData';
import { useCollectionContext } from '../CollectionDetail/CollectionContextProvider';
import { ModalContextProvider } from './AddToCollectionContext';

/**
 * Component for the "Add to Collection" button
 * Be aware that this component can be used in a CollectionDetail view (with a context provider)
 * or outside of it (without a context provider).
 * If used outside of a CollectionDetail view, the isMarked state will be used to determine
 * if the item is in any collection (or not).
 * If used inside a CollectionDetail view, besides the isMarked state, the refreshCollectionData
 * function will be used to refresh the collection data and view.
 *
 * @param {number} objectPk - The primary key of the object
 * @param {string} objectType - The type of the object
 * @param {object} style - The style object
 * @returns {JSX.Element}
 */
export default function AddToCollectionButton({
  objectPk,
  objectType,
  style = {},
  isActive,
  hasText = false,
  color = 'black',
  inline = false,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMarked, setIsMarked] = useState(isActive);
  const refreshCollectionData = useCollectionContext();

  /**
   * Fetches the status of the item in the collection from the API and sets the isMarked state
   * so it only makes sense outside of the collectionDetail view.
   */
  const getStatusFromAPI = React.useCallback(() => {
    setLoading(true);
    const apiURL = `/api/foldering/${objectType}/${objectPk}/is_a_foldered_item/`;
    fetchData(apiURL)
      .then((res) => setIsMarked(res.is_a_foldered_item))
      .finally(() => setLoading(false));
  }, [objectPk, objectType]);

  /**
   * Handles the modal close event
   * @param {boolean} changed - Indicates if the form has changed
   * @returns {void}
   */
  const handleModalClose = React.useCallback((changed) => {
    // Close the modal
    setModalIsOpen(false);
    // If there the form has changed, refresh the isMarked state
    if (changed) {
      // Update the isMarked state
      getStatusFromAPI();
      // If there is a collection context, refresh the collection data
      if (refreshCollectionData)
        refreshCollectionData({ objectPk, objectType });
    }
  }, []);

  /**
   * First-render effect to get the isMarked status of the item in a collection
   */
  React.useEffect(() => {
    // If the isActive prop is not defined, fetch the status from the API
    if (isActive === undefined) getStatusFromAPI();
    else setLoading(false);
  }, []);

  const defaultIconStyle = {
    position: 'absolute',
    right: '19px',
    top: '17px',
  };

  const defaultTextStyle = {
    padding: 0,
    margin: '12px',
    marginLeft: '0',
  };

  const defaultStyle = inline ? defaultTextStyle : defaultIconStyle;

  const sx = {
    ...defaultStyle,
    ...style,
  };

  const handleOnClick = React.useCallback((event) => {
    event.preventDefault();
    setModalIsOpen(true);
  }, []);

  const getRenderedComponent = () => {
    if (hasText) {
      return (
        <Button
          data-testid="addToCollectionButton"
          startIcon={isMarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
          onClick={handleOnClick}
          aria-label={isMarked ? 'remove from collection' : 'add to collection'}
          variant="text-only"
          color={color}
          sx={sx}
        >
          {isMarked ? 'Saved' : 'Save'}
        </Button>
      );
    } else {
      return (
        <IconButton
          data-testid="addToCollectionButton"
          onClick={handleOnClick}
          aria-label={isMarked ? 'remove from collection' : 'add to collection'}
          variant="border"
          color="black"
          sx={sx}
        >
          {isMarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </IconButton>
      );
    }
  };

  const getLoadingComponent = () => {
    if (hasText) {
      return (
        <Skeleton
          variant="text"
          animation="wave"
          width={64}
          sx={{ ...sx, fontSize: '12px' }}
          border="1px solid #e4e4e4"
        />
      );
    }
    return (
      <Skeleton
        variant="circular"
        animation="wave"
        width={32}
        height={32}
        sx={sx}
        border="1px solid #e4e4e4"
      />
    );
  };

  return (
    <ModalContextProvider
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      onClose={handleModalClose}
    >
      {loading ? (
        getLoadingComponent()
      ) : (
        <>
          {getRenderedComponent()}
          <AddToCollectionModal objectPk={objectPk} objectType={objectType} />
        </>
      )}
    </ModalContextProvider>
  );
}

AddToCollectionButton.propTypes = {
  objectPk: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sytle: PropTypes.object,
  isActive: PropTypes.bool,
  hasText: PropTypes.bool,
  color: PropTypes.string,
};
