import React from 'react';
import PropTypes from 'prop-types';
import { animated, config, useTransition } from '@react-spring/web';

export default function NotificationsDropdown({
  active,
  containerRef,
  children,
}) {
  const entryTransition = useTransition(active, {
    from: { opacity: 0, transform: 'scaleY(0%) translateY(-60%)' },
    enter: { opacity: 1, transform: 'scaleY(100%) translateY(0%)' },
    config: config.nobounce,
  });

  return (
    active &&
    entryTransition(
      (styles, show) =>
        show && (
          <animated.div
            ref={containerRef}
            style={styles}
            data-testid="notificationsContainer"
            className="notifications__container"
          >
            {children}
          </animated.div>
        )
    )
  );
}

NotificationsDropdown.propTypes = {
  active: PropTypes.bool.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
