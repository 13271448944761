import React from 'react';
import PropTypes from 'prop-types';
import TagSelector from './tagSelector';
import fetchData from '../../../utils/fetchData';
import logError from '../../../utils/logError';

// This is a wrapper for the TagSelector component that allows it to be used
// without a parent component. This is useful for when you want to use the
// TagSelector in a modal or other component that doesn't have a parent or is in a django form
function StandaloneTagSelector({
  tagType,
  formInputName,
  filterFor,
  placeHolder,
  existingValue = '',
  hybrid = false,
  containerStyle = {},
  withSuggestions = false,
  submitWhenChanged = false,
  onChange,
}) {
  const inputElement = React.useRef();
  const [tags, setTags] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState([]);

  const handleClickSuggestedTag = (tag) => {
    setTags([...tags, tag]);
  };

  let debounceTimer = null;

  const tagsChanged = (tags) => {
    setTags(tags);
    if (onChange) {
      onChange(tags);
    }
    if (submitWhenChanged) {
      const elem = inputElement.current;
      if (elem && elem.form) {
        // Clear any pending timeouts to prevent previous form submissions
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }

        // Set a new timeout to submit the form after 200 milliseconds to allow multiple selections
        debounceTimer = setTimeout(() => {
          elem.form.submit();
          debounceTimer = null; // Reset the timer once the form is submitted
        }, 200);
      }
    }
  };

  React.useEffect(() => {
    // Fetch suggested tags
    if (withSuggestions) {
      try {
        fetchData(
          `/api/${tagType}/suggested/${
            filterFor ? `?filterFor=${filterFor}` : ``
          }`
        ).then((data) => {
          setSuggestions(data);
        });
      } catch (e) {
        logError(e);
        setSuggestions([]);
      }
    }

    // Fetch existing tags if there are any
    if (existingValue) {
      try {
        fetchData(`/api/${tagType}/?pk_string=${existingValue}`).then(
          (data) => {
            setTags(data);
          }
        );
      } catch (e) {
        logError(e);
      }
    }
  }, []);

  return (
    <>
      <input
        type="hidden"
        name={formInputName}
        value={tags.map((tag) => tag.pk).join(',')}
        ref={inputElement}
      />
      <TagSelector
        tagType={tagType}
        filterFor={filterFor}
        tags={tags}
        onChange={tagsChanged}
        placeholder={placeHolder}
        hybrid={hybrid}
        containerStyle={containerStyle}
      />
      {withSuggestions && suggestions.length > 0 && (
        <div className="row">
          <div className="react-tag-suggestions-container">
            Suggestions:&nbsp;&nbsp;
            {suggestions.map((suggestion, index) => (
              <div
                className="tag tag-suggestion"
                key={index}
                onClick={() => handleClickSuggestedTag(suggestion)}
              >
                {suggestion.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

StandaloneTagSelector.propTypes = {
  tagType: PropTypes.string.isRequired,
  formInputName: PropTypes.string.isRequired,
  existingValue: PropTypes.string,
  placeHolder: PropTypes.string,
  filterFor: PropTypes.string,
  submitWhenChanged: PropTypes.bool,
  hybrid: PropTypes.bool,
  onChange: PropTypes.func,
};

export default StandaloneTagSelector;
