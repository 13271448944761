import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';

function PaymentMethodSelector({
  methods,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  showPaymentForm = () => {},
}) {
  const stripe = useStripe();

  const getMethodDisplayName = (method) => {
    // Stripe's payment method object uses the method's type to name the field with all its data
    // https://docs.stripe.com/api/payment_methods/object and then check "More attributes"
    const data = method[method.type];
    let method_display_name = '';

    // Display the card brand name with the last 4 digits following stripe's display_brand
    // https://docs.stripe.com/api/payment_methods/object#payment_method_object-card-display_brand
    if (method.type === 'card') method_display_name = data.display_brand || data.brand;
    // Display the bank name with the last 4 digits following stripe's bank_name
    // https://docs.stripe.com/api/payment_methods/object#payment_method_object-us_bank_account-bank_name
    else if (method.type === 'us_bank_account')
      method_display_name = data.bank_name;
    // Fallback to the type if we don't have a specific display name
    else method_display_name = data.type;

    // Let's format to uppercase the first letter of the method_display_name, and replace the _ with a space
    method_display_name =
      method_display_name.charAt(0).toUpperCase() +
      method_display_name.slice(1).toLowerCase();
    method_display_name = method_display_name.replace(/_/g, ' ');

    return `${method_display_name} •••• ${data.last4}`;
  };

  /**
   * Select the first payment method by default on mount
   */
  React.useEffect(() => {
    // It's possible that the selectedPaymentMethodId is not in the list of methods to be selected,
    // so we need to check if it's in the list of methods and if not, select the first one
    const methodsIDs = methods.map((method) => method.id);
    if (methods.length > 0 && !methodsIDs.includes(selectedPaymentMethodId)) {
      setSelectedPaymentMethodId(methods[0].id);
    }
  }, [methods]);

  return (
    <>
      <h3 className="checkout__subtitle">Select a payment method</h3>
      <div className="checkout__existing-cards">
        {methods.map((method, i) => (
          <div className="checkout__existing-card" key={i}>
            <label className="checkbox">
              <input
                type="radio"
                className="checkmark"
                name="existingPaymentMethod"
                value={method.id}
                onChange={(e) => {
                  if (e.target.checked) setSelectedPaymentMethodId(method.id);
                }}
                checked={selectedPaymentMethodId === method.id}
              />{' '}
              <span className="checkmark filter-check"></span>
              <span className="selection">{getMethodDisplayName(method)}</span>
            </label>
          </div>
        ))}
      </div>
      <div className="checkout__add-new">
        or{' '}
        <span
          className="checkout__add-new--link"
          onClick={() => showPaymentForm()}
        >
          add new
        </span>
      </div>
    </>
  );
}

export default PaymentMethodSelector;
