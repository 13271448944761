import React from 'react';
import PropTypes from 'prop-types';
import Toastify from 'toastify-js';
import NavInboxDropdown from './NavInboxDropdown';
import AbsoluteMenu from '../FloatingMenus/AbsoluteMenu';
import ApiConnection from '../PHChatApi/PHChatApi';
import SocketConnection from '../PHChatApi/Socketry/ChatSocketConnector';
import useAllConversationsReadState from '../PHChatApi/ReactHooks/useAllConversationsReadState';
import MessageNotificationToasts from '../MessageNotificationToasts/MessageNotificationToasts';
import getCookie from '../../../../utils/getCookie';
import setCookie from '../../../../utils/setCookie';

/**
 * Messaging Inbox for Navbar
 * Shows button that opens dropdown with 4 most recent conversations.
 * Red dot illuminates if there are any unread messages
 */
function NavInbox({ showToasts = false, targetElement = null }) {
  const inboxButton = React.useRef(null);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [inboxX, showInboxX] = React.useState(0);
  const [inboxY, showInboxY] = React.useState(0);

  const unread = useAllConversationsReadState();

  /**
   * Logic for showing/hiding options menu.
   */
  const showInbox = React.useCallback(
    (event) => {
      if (showDropDown) {
        setShowDropDown(false);
        return;
      }

      if (!event) {
        return;
      }

      const buttonRect = inboxButton.current.getBoundingClientRect();
      const mobileWidth = window.innerWidth < 500;

      showInboxX(mobileWidth ? window.innerWidth / 2 : buttonRect.left - 89);
      showInboxY(mobileWidth ? 65 : buttonRect.top + 26.5);
      setShowDropDown(true);
    },
    [showDropDown, inboxX, inboxY, inboxButton]
  );

  // Connect to API
  React.useEffect(() => {
    ApiConnection.init(); // it's getting only 4 most recent conversations
    SocketConnection.init();
  }, []);

  React.useEffect(() => {
    // Shows a toast in the dashboard to remind the user they have unread messages
    // it shows once a day only to be less annoying
    if (
      unread &&
      window.location.pathname.includes('/dashboard') &&
      targetElement?.offsetParent
    ) {
      const alreadyShown = getCookie('unreadMessagesToastShown');
      if (alreadyShown !== '1') {
        setCookie('unreadMessagesToastShown', 1, 1);
        Toastify({
          text: 'You have unread messages! Click the message icon in the navbar to view them.',
          class: 'ph-toast',
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          offset: {
            // Toastify auto adds 15 to these values
            x: 48 - 15,
            y: 34 - 15,
          },
          duration: 5000,
        }).showToast();
      }
    }
  }, [unread]);

  return (
    <div>
      <button
        type="button"
        className="chat-nav-inbox__button"
        data-testid="chat-nav-inbox-button"
        onClick={showInbox}
        ref={inboxButton}
      >
        <img
          src={`${staticUrl}/images/svg/email_black.svg`}
          alt="messaging icon"
        />
        {unread && (
          <div
            className="conversation-list__unread-dot conversation-list__unread-dot--inbox"
            data-testid="inbox-master-unread-dot"
          />
        )}
      </button>
      <AbsoluteMenu
        x={inboxX}
        y={inboxY}
        show={showDropDown}
        handleDismiss={showInbox}
        buttonRef={inboxButton}
        blank
      >
        <NavInboxDropdown />
      </AbsoluteMenu>
      {showToasts && <MessageNotificationToasts />}
    </div>
  );
}

NavInbox.propTypes = {
  showToasts: PropTypes.bool,
  targetElement: PropTypes.object,
};

export default NavInbox;
