import React from 'react';
import LoadingRectangle from '../../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function CollectionLoadingCard() {
  return (
    <div className="collection-card__container">
      <div className="collection-card__images">
        <LoadingRectangle height={204} />
      </div>
      <div className="collection-card__title">
        <LoadingRectangle height={20} width={120} />
      </div>
      <div className="collection-card__subtitle">
        <LoadingRectangle height={20} width={60} />
      </div>
    </div>
  );
}

export default CollectionLoadingCard;
