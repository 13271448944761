import React from 'react';
import { animated, config, useTransition } from '@react-spring/web';
import ConversationListItem from '../ConversationList/ConversationListItem';
import useConversationList from '../PHChatApi/ReactHooks/useConversationList';
import useApiReadyState from '../PHChatApi/ReactHooks/useApiReadyState';

/**
 * Dropdown for Navbar Inbox
 */
function NavInboxDropdown() {
  const conversations = useConversationList();

  const apiReadyState = useApiReadyState();

  const totalConversations = conversations.length;

  const entryTransition = useTransition(true, {
    from: { opacity: 0, transform: 'scaleY(0%) translateY(-60%)' },
    enter: { opacity: 1, transform: 'scaleY(100%) translateY(0%)' },
    config: config.nobounce,
  });

  return entryTransition(
    (styles, show) =>
      show && (
        <animated.div
          style={styles}
          className="chat-nav-inbox__container"
          data-testid="chat-nav-dropdown-container"
        >
          <img
            src={`${staticUrl}/images/svg/dropdown_caret.svg`}
            className="chat-nav-inbox__caret"
            alt="caret"
          />
          <div className="chat-nav-inbox__body-container">
            <span className="chat-nav-inbox__title">Recent Messages</span>
            {(conversations.length === 0 || apiReadyState !== 1) && (
              <span
                className="chat-nav-inbox__empty-text"
                data-testid="inbox-empty-text"
              >
                {apiReadyState === 1 &&
                  'No messages yet! We’ll notify you when we got something for you.'}
                {apiReadyState === 0 && 'Loading...'}
                {apiReadyState === 2 && 'Something went wrong...'}
              </span>
            )}
            {apiReadyState === 1 &&
              conversations
                .slice(0, 4)
                .map((conversation) => (
                  <ConversationListItem
                    key={conversation.getPk()}
                    conversation={conversation}
                    inbox
                  />
                ))}
            <div className="chat-nav-inbox__footer">
              <a
                className="chat-nav-inbox__footer-link"
                href="/messaging/"
                data-testid="inbox-view-more-link"
              >
                {totalConversations > 4
                  ? `View ${totalConversations - 4} More in Messaging`
                  : 'Go to Messages'}
              </a>
            </div>
          </div>
        </animated.div>
      )
  );
}

export default NavInboxDropdown;
