import EventsClass from '../../../utils/Socketry/EventsClass';
import ConversationMember from '../DataTypes/ConversationMember';
import SocketConnection from './ChatSocketConnector';
import createConversation from '../util/createConversation';

/**
 * Given a conversation, returns True if the conversation is invalid (not enough members for our logic to work)
 * This should prevent crashes due to corrupted conversations from data deletion.
 * @param {*} conversation raw conversation api response to check
 * @returns boolean
 */
function detectInvalidConversation(conversation) {
  if (!conversation || !Array.isArray(conversation.members) || conversation.members.length < 2) {
    return true;
  }
  return false;
}

/**
 * Wrapper for SocketConnector that relays certain events with their data converted to object instances
 */
class SocketObjectCreationWrapperClass extends EventsClass {
  constructor() {
    super();
    this.registerEventListeners();
  }

  registerEventListeners() {
    SocketConnection.on(
      'conversation_created',
      (payload) =>
        !detectInvalidConversation(payload) &&
        this.fireEvent('conversation_created', createConversation(payload))
    );
    SocketConnection.on(
      'member_invited_to_conversation',
      (payload) =>
        !detectInvalidConversation(payload.conversation) &&
        this.fireEvent('member_invited_to_conversation', {
          member: new ConversationMember(payload.member),
          conversation: createConversation(payload.conversation),
        })
    );
    SocketConnection.on(
      'member_added_to_conversation',
      (payload) =>
        !detectInvalidConversation(payload.conversation) &&
        this.fireEvent('member_added_to_conversation', {
          member: new ConversationMember(payload.member),
          conversation: createConversation(payload.conversation),
        })
    );
    SocketConnection.on(
      'member_removed_from_conversation',
      (payload) =>
        !detectInvalidConversation(payload.conversation) &&
        this.fireEvent('member_removed_from_conversation', {
          member: new ConversationMember(payload.member),
          conversation: createConversation(payload.conversation),
        })
    );
    SocketConnection.on('user_typing', (payload) =>
      this.fireEvent('user_typing', payload)
    );
    SocketConnection.on('socket_ready', (value) =>
      this.fireEvent('socket_ready', value)
    );
  }
}

const SocketObjectCreationWrapper = new SocketObjectCreationWrapperClass();

export default SocketObjectCreationWrapper;
