import SocketConnection from '../Socketry/ChatSocketConnector';
import restOperations from '../util/restOperations';
import DjangoModel from './DjangoModel';
import User from './User';

/**
 * Object representing a member of a conversation
 */
export default class ConversationMember extends DjangoModel {
  constructor(memberObject) {
    super(memberObject);
    this.user = new User(memberObject.user);
    this.activeState = memberObject.status;
    this.isInvited = this.activeState === 'INV';
    this.title = memberObject.title;
    this.subtitle = memberObject.subtitle;
    this.registerStatusEventListeners();
  }

  /**
   * Get the active state of a user (ACC, DEC, REM, INV, PEN)
   * @returns string
   */
  getActiveState() {
    return this.activeState;
  }

  /**
   * Set the active state of a user and fire events accordingly
   * @returns
   */
  setActiveState(newState) {
    this.activeState = newState;
    this.isInvited = this.activeState === 'INV';
    this.fireEvent('status_changed', this);
  }

  /**
   * Get if a user is waiting to join a conversation
   * @returns string
   */
  getIsInvited() {
    return this.isInvited;
  }

  /**
   * Get the django user object for a Member
   * @returns User
   */
  getUser() {
    return this.user;
  }

  /**
   * Event listener for conversation status change.
   * @param {*} event event to handle
   * @returns void
   */
  handleStatusChanged(event) {
    if (event.member.pk !== this.getPk()) {
      return;
    }
    this.setActiveState(event.member.status);
  }

  /**
   * Registers event listeners for conversation status changes.
   */
  registerStatusEventListeners() {
    SocketConnection.on(
      'member_invited_to_conversation',
      this.handleStatusChanged.bind(this)
    );
    SocketConnection.on(
      'member_accepted_invite',
      this.handleStatusChanged.bind(this)
    );
    SocketConnection.on(
      'member_declined_invite',
      this.handleStatusChanged.bind(this)
    );
    SocketConnection.on(
      'member_removed_from_conversation',
      this.handleStatusChanged.bind(this)
    );
  }

  /**
   * Accept invitation to a chat
   */
  acceptInvitation() {
    if (!this.getIsInvited()) {
      return;
    }
    restOperations.changeActiveStatus('ACC', this.getPk()).then((value) => {
      if (value) {
        this.setActiveState(value.status);
      }
    });
  }

  /**
   * Decline invitation to a chat
   */
  declineInvitation() {
    if (!this.getIsInvited()) {
      return;
    }
    restOperations.changeActiveStatus('DEC', this.getPk()).then((value) => {
      if (value) {
        this.setActiveState(value.status);
      }
    });
  }

  /**
   * Leave a conversation
   */
  leaveConversation() {
    restOperations.changeActiveStatus('REM', this.getPk()).then((value) => {
      if (value) {
        this.setActiveState(value.status);
      }
    });
  }

  /**
   * Gets member specific conversation title
   */
  getTitle() {
    return this.title;
  }

  /**
   * Gets the member specific conversation subtitle
   */
  getSubtitle() {
    return this.subtitle;
  }
}
