import logError from '../../../utils/logError';
import fetchData from '../../../utils/fetchData';

export default async function asyncFetch(
  url,
  stateHandler,
  setError,
  errorMessage
) {
  try {
    const abortController = new AbortController();
    const res = await fetchData(url, abortController.signal);
    if (!res) {
      throw new Error('No Data Found');
    }
    stateHandler(res);
    return res;
  } catch (e) {
    setError(errorMessage);
    logError(e);
  }
  return [];
}
