import updateData from '../../../../../utils/updateData';
import logError from '../../../../../utils/logError';
import ErrorRouter from '../ErrorBanners/ErrorBanners';

/**
 * Common pattern on these:
 * Return api response if succeeded, null if failed.
 */

/**
 * Method to send a new message
 * @param {*} message message to send
 * @param {*} conversation conversation to send to
 * @returns sent message or null if failed
 */
async function postMessage(message, conversation) {
  try {
    const abortController = new AbortController();
    const newMessage = await updateData(
      '/api/chat/messages/',
      {
        json: {
          message: message.getContent(),
          conversation: conversation.getPk(),
          sender: conversation.getIdentity().getPk(),
        },
      },
      'POST',
      abortController.signal
    );
    return newMessage;
  } catch (e) {
    ErrorRouter.fireError(
      'general',
      'Last message failed to send, please try again.'
    );
    logError(e);
    return null;
  }
}

/**
 * Method for responding to chat invitations.
 * @param {*} newStatus status to set
 * @param {*} memberPk pk of member to edit
 * @returns
 */
async function changeActiveStatus(newStatus, memberPk) {
  try {
    const abortController = new AbortController();
    const member = await updateData(
      `/api/chat/conversation-members/${memberPk}/`,
      { json: { status: newStatus } },
      'PATCH',
      abortController.signal
    );
    return member;
  } catch (e) {
    ErrorRouter.fireError('general', 'Something went wrong, please try again.');
    logError(e);
    return null;
  }
}

/**
 * Method for flagging a message
 * @param {*} message message to flag
 * @returns message
 */
async function flagMessage(message) {
  try {
    const abortController = new AbortController();
    const updatedMessage = await updateData(
      `/api/chat/messages/${message.getPk()}/`,
      { json: { flagged_status: 'FLAGGED' } },
      'PATCH',
      abortController.signal
    );
    return updatedMessage;
  } catch (e) {
    ErrorRouter.fireError(
      'general',
      'Failed to report message. Please try again.'
    );
    logError(e);
    return null;
  }
}

/**
 * Method for resolving a message flag
 * @param {*} pk of message to resolve flag
 * @returns message
 */
async function resolveMessageFlag(pk) {
  try {
    const abortController = new AbortController();
    const updatedMessage = await updateData(
      `/api/chat/messages/${pk}/`,
      { json: { flagged_status: 'RESOLVED' } },
      'PATCH',
      abortController.signal
    );
    return updatedMessage;
  } catch (e) {
    ErrorRouter.fireError('general', 'Failed to resolve message flag.');
    logError(e);
    return null;
  }
}

/**
 * Method for moderation removal of message (edits contents)
 * @param {*} pk of message to remove
 * @param {*} flagged boolean indicating if the message was flagged before removal or not
 * @returns message
 */
async function removeMessage(pk, flagged) {
  try {
    const abortController = new AbortController();
    const updatedMessage = await updateData(
      `/api/chat/messages/${pk}/`,
      {
        json: {
          message: flagged
            ? 'This message was flagged and has been removed by a moderator.'
            : 'This message has been removed by a moderator.',
        },
      },
      'PATCH',
      abortController.signal
    );
    return updatedMessage;
  } catch (e) {
    ErrorRouter.fireError('general', 'Failed to remove message.');
    logError(e);
    return null;
  }
}

/**
 * Method for marking a message as read
 * @param {*} message message to read
 * @returns read receipt
 */
async function readMessage(message, identity) {
  try {
    const abortController = new AbortController();
    const readReceipt = await updateData(
      `/api/chat/read-receipts/`,
      { json: { message: message.getPk(), read_by: identity.getPk() } },
      'POST',
      abortController.signal
    );
    return readReceipt;
  } catch (e) {
    // These fail silently because they can be spammy.
    // It'll still be reported to our error tracking, however.
    logError(e);
    return null;
  }
}

/**
 * Method for injecting an admin into a conversation
 * @param {*} userPk pk of user to insert
 * @param {*} conversationPk pk of conversation to join
 * @returns conversation member
 */
async function forciblyJoinConversation(userPk, conversationPk) {
  try {
    const abortController = new AbortController();
    const conversationMember = await updateData(
      `/api/chat/conversation-members/`,
      { json: { conversation: conversationPk, user: userPk, status: 'ACC' } },
      'POST',
      abortController.signal
    );
    return conversationMember;
  } catch (e) {
    ErrorRouter.fireError('general', 'Failed to forcibly join conversation.');
    logError(e);
    return null;
  }
}

const restOperations = {
  postMessage,
  changeActiveStatus,
  flagMessage,
  resolveMessageFlag,
  removeMessage,
  readMessage,
  forciblyJoinConversation,
};

export default restOperations;
