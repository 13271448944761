import EventsClass from '../../../utils/Socketry/EventsClass';

/**
 * Class for storing and accesing Django Model pk
 */
export default class DjangoModel extends EventsClass {
  constructor(object) {
    super();
    this.pk = object.pk;
  }

  /**
   * Get the DB Pk of this object
   * @returns number
   */
  getPk() {
    return this.pk;
  }
}
