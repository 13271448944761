import React, { act } from 'react';
import ReactDOM from 'react-dom/client';
import CollectionListPage from '../CollectionList/CollectionListPage';
import CollectionDetailPage from '../CollectionDetail/CollectionDetailPage';
import AddToCollectionModal from '../AddToCollection/AddToCollectionModal';
import getPageSetting from '../../../../../../../config/static/js/utils/getPageSetting';

/**
 * This file is for injecting the collections into the page.
 */
export default function injectCollectionsComponent() {
  const collectionListReactTarget = document.querySelector(
    'main.react-collection-list-view'
  );

  const collectionDetailReactTarget = document.querySelector(
    'main.react-collection-detail-view'
  );
  const collectionSlug = getPageSetting('collectionSlug', null);

  const collectionModalContainer = document.getElementById(
    'addToCollectionModalContainer'
  );

  if (collectionListReactTarget) {
    const root = ReactDOM.createRoot(collectionListReactTarget);
    root.render(<CollectionListPage />);
  }

  if (collectionDetailReactTarget) {
    const root = ReactDOM.createRoot(collectionDetailReactTarget);
    root.render(<CollectionDetailPage collectionSlug={collectionSlug} />);
  }

  if (collectionModalContainer) {
    const root = ReactDOM.createRoot(collectionModalContainer);
    root.render(<AddToCollectionModal />);
  }
}

function toggleActiveIcon(itemType, itemPk, active) {
  const saveButtons = document.querySelectorAll('.add-to-collection-button');
  saveButtons.forEach((button) => {
    if (
      button.dataset.objectType === itemType &&
      button.dataset.objectPk === `${itemPk}`
    ) {
      if (active) {
        button.classList.add('add-to-collection-button--active');
      } else {
        button.classList.remove('add-to-collection-button--active');
      }
    }
  });
}

document.addEventListener('folderingUncheckIcon', (e) => {
  const { itemType, itemPk } = e.detail;
  toggleActiveIcon(itemType, itemPk, false);
});

document.addEventListener('folderingCheckIcon', (e) => {
  const { itemType, itemPk } = e.detail;
  toggleActiveIcon(itemType, itemPk, true);
});

document.addEventListener('DOMContentLoaded', () => {
  injectCollectionsComponent();
});
