// handles the collapsing BIO section for Brands Details view
const bioContainer = document.getElementById('biosContainer');

if (bioContainer) {
  const bios = bioContainer.querySelectorAll('.js-collapsible-bio');

  if (bios) {
    bios.forEach((bio) => {
      const content = bio.querySelector('.bio-collapsible-content');
      const chevrons = bio.getElementsByClassName('bio-chevron');
      if (chevrons && content) {
        Array.from(chevrons).forEach((chevron) => {
          chevron.addEventListener('click', () => {
            // toggling display instead of maxheight so we can use pad/margin
            if (bio.classList.contains('expanded')) {
              bio.classList.remove('expanded');
              chevron.setAttribute('aria-expanded', 'true');
              content.style.display = 'none';
              chevron.style.transform = 'none';
            } else {
              bio.classList.add('expanded');
              chevron.setAttribute('aria-expanded', 'false');
              content.style.display = 'block';
              chevron.style.transform = 'rotate(180deg)';
            }
          });
        });
      }
    });
  }
}
