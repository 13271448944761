import React from 'react';

/**
 * Custom hook to determine if a given conversation has been read or not
 * @param {*} conversation conversation to determine
 * @returns bool
 */
function useConversationReadState(conversation) {
  const [conversationRead, setConversationRead] = React.useState(
    conversation.getIfConversationIsRead()
  );

  /**
   * Register necessary event listeners
   */
  React.useEffect(() => {
    function conversationReadHandler(value) {
      setConversationRead(value);
    }

    conversation.on('conversation_read', conversationReadHandler);

    return () => {
      conversation.off('conversation_read', conversationReadHandler);
    };
  }, [conversation]);

  return conversationRead;
}

export default useConversationReadState;
