import React from 'react';
import ReactDOM from 'react-dom/client';
import { debounce, throttle } from 'throttle-debounce';
import cx from 'classnames';
import fetchData from '../../../utils/fetchData';
import boldString from '../../../utils/boldString';
import { getImageURL } from '../utils/wagtailImageUtils';

/*
  Homepage Universal Search Component

  Listens on input changes from homepage search field
  Throttle/Debounce autocomplete from /api/search?query=foo
*/

function Search({ inputRef = null }) {
  const ref = React.useRef(null);
  const [results, setResults] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [beanValue, setBeanValue] = React.useState('');
  const [expertFlag, setExpertFlag] = React.useState(false);
  const [mediaFlag, setMediaFlag] = React.useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);

  /**
   * Handle click outside
   * Thank you: https://blog.logrocket.com/detect-click-outside-react-component-how-to/
   * @param {e} evt document click or touch event
   * @returns
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (results.length > 0) setResults([]);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  // Abort signal for aborting premature requests
  const mainAbortController = new AbortController();
  const { signal } = mainAbortController;

  // Async function for fetching search data
  async function runSearch(value, sig) {
    try {
      setLoading(true);
      const res = await fetchData(
        `/api/search?query=${encodeURIComponent(value)}`,
        sig
      );
      if (!res || res[0] === undefined) return;
      setResults(res);
      setExpertFlag(res[0]['experts_flag']);
      setMediaFlag(res[0]['media_flag']);
      setBeanValue(value);
      setLoading(false);
    } catch (e) {
      // ignore aborted errors
      if (e.name === 'AbortError') {
        return;
      }
      console.log(e);
    }
  }

  // Click handler that is added to each search result that does not yield a link to a search view with a search query string.
  // For these clicks, we need to send Search Result Click & Virtual Page View events to segment.
  function trackSearchClick(inputValue, item = null) {
    if (window.analytics && item) {
      const trackData = {
        object_type: item.type,
        object_title: item.title,
        object_url: item.url,
        object_pk: item.pk,
      };
      window.analytics.track('Search Result Click', trackData);
      window.analytics.page({
        title: 'Search - Presshook',
        url: `${window.location.protocol}//${window.location.host}/search/universal/?search=${inputValue}`,
        path: `/search/universal/`,
        search: `?search=${inputValue}`,
      });
    }
  }

  // callback function for handling input changes
  const handleInputChange = React.useCallback((event) => {
    const { target } = event;
    const { value } = target;

    setInputValue(value);
  }, []);

  // initialize Event Listener for input value changes
  React.useEffect(() => {
    if (inputRef) inputRef.addEventListener('input', handleInputChange);
    return () => {
      if (inputRef) {
        inputRef.removeEventListener('input', handleInputChange);
      }
    };
  }, [handleInputChange]);

  // Listen on input value changes and throttle or debounce accordingly
  React.useEffect(() => {
    const throttled = throttle(300, (value, sig) => runSearch(value, sig));
    const debounced = debounce(300, (value, sig) => runSearch(value, sig));

    if (inputValue.length < 5 && inputValue.length !== 0) {
      throttled(inputValue, signal);
    } else if (inputValue.length !== 0) {
      debounced(inputValue, signal);
    } else {
      setResults([]);
    }

    return () => {
      mainAbortController.abort();
      throttled.cancel();
      debounced.cancel();
    };
  }, [inputValue]);

  return (
    <div
      ref={ref}
      className={cx('search__results-container', {
        'search__results-container--active': results.length,
        active: results.length, // this must be removed after SCSS refactoring
      })}
    >
      <div className="search__pills">
        <a
          className="search__pill"
          href={`/search/products?search=${inputValue}`}
        >
          Products with
          <b className="search__bean-value">{` ${beanValue}`}</b>
        </a>
        <a
          className="search__pill"
          href={`/search/brands?search=${inputValue}`}
        >
          Brands with
          <b className="search__bean-value">{` ${beanValue}`}</b>
        </a>
        {expertFlag && (
          <a
            className="search__pill"
            href={`/search/experts?search=${inputValue}`}
          >
            Experts with
            <b className="search__bean-value">{` ${beanValue}`}</b>
          </a>
        )}
        {mediaFlag && (
          <a
            className="search__pill"
            href={`/search/media_profiles?search=${inputValue}`}
          >
            Media with
            <b className="search__bean-value">{` ${beanValue}`}</b>
          </a>
        )}
      </div>
      <div className="search__results">
        <a
          className="search__results-item"
          href={`/search/universal/?search=${inputValue}`}
        >
          {`${inputValue}`}
        </a>
        {results.map((item) => (
          <a
            key={item.url}
            className="search__results-item"
            href={`${item.url}`}
            onClick={() => trackSearchClick(inputValue, item)}
          >
            {item.logo && (
              <img
                alt="brand logo"
                className="brand-icon brand__logo"
                src={getImageURL(item.logo)}
              />
            )}
            <div className="search__results-content">
              {/* eslint-disable-next-line react/no-danger */}
              <p
                className="search__result"
                dangerouslySetInnerHTML={{
                  __html: `${boldString(item.title, beanValue)}`,
                }}
              />
              <span className="search__result-type">{item.type}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

// home page elements
const renderEl = document.querySelector('.js-react-search');
const inputRef = document.querySelector('.js-universal-search');

// header desktop elements
const headerEl = document.querySelector('.js-react-header-search');
const headerRef = document.querySelector('.js-header-search');

// header mobile elements
const mobileEl = document.querySelector('.js-react-mobile-search');
const mobileRef = document.querySelector('.js-mobile-search');

if (renderEl && inputRef) {
  const root = ReactDOM.createRoot(renderEl);
  root.render(<Search inputRef={inputRef} />);
}

if (headerEl && headerRef) {
  const root = ReactDOM.createRoot(headerEl);
  root.render(<Search inputRef={headerRef} />);
}

if (mobileEl && mobileRef) {
  const root = ReactDOM.createRoot(mobileEl);
  root.render(<Search inputRef={mobileRef} />);
}
