import React from 'react';
import ReactDOM from 'react-dom/client';
import UserMenu from './UserMenu';

document.addEventListener('DOMContentLoaded', () => {
  const reactTarget = document.querySelector('#js-user-menu');

  if (reactTarget) {
    // convert dataset values to user object (saves an API request)
    const user = {
      id: reactTarget.dataset.userId,
      isMedia: reactTarget.dataset.userIsMedia === 'True',
      isBrand: reactTarget.dataset.userIsBrand === 'True',
      isExpert: reactTarget.dataset.userIsExpert === 'True',
      isStaff: reactTarget.dataset.userIsStaff === 'True',
      isApproved: reactTarget.dataset.userIsApproved === 'True',
      pathwrightId: reactTarget.dataset.userPathwrightId,
      firstName: reactTarget.dataset.userFirstName,
      lastName: reactTarget.dataset.userLastName,
      email: reactTarget.dataset.userEmail,
      profileImageUrl: reactTarget.dataset.userProfileImageUrl,
      shopifyUrl: reactTarget.dataset.userShopifyUrl,
      hasPrToolbox: reactTarget.dataset.userHasPrToolbox === 'True',
      hasMultipleUsers: reactTarget.dataset.userHasMultipleUsers === 'True',
    };

    const featureFlags = {
      flagSubscriptionManagement:
        reactTarget.dataset.flagSubscriptionManagement === 'True',
    };

    const root = ReactDOM.createRoot(reactTarget);
    root.render(<UserMenu user={user} featureFlags={featureFlags} />);
  }
});
