import React from 'react';
import ReactDOM from 'react-dom/client';
import OrganizationSelector from './OrganizationSelector';

/**
 * This file is for injecting the Organization Selection component in the navbar.
 */
document.addEventListener('DOMContentLoaded', () => {
  const reactTarget = document.querySelector('#js-organization-selector');

  if (reactTarget) {
    const root = ReactDOM.createRoot(reactTarget);
    root.render(<OrganizationSelector />);
  }
});
