import React from 'react';
import PropTypes from 'prop-types';

function CollectionCardCoverImages({ image, extraClassModifier }) {
  const styles = {
    backgroundImage: `url(${image.url})`,
  };

  return (
    <div
      className={
        extraClassModifier
          ? `collection-card__image-container collection-card__image-container${extraClassModifier}`
          : 'collection-card__image-container'
      }
      style={styles}
      data-testid="collectionCardImage"
    ></div>
  );
}

CollectionCardCoverImages.protoTypes = {
  image: PropTypes.string.isRequired,
  extraClassModifier: PropTypes.string,
};

export default CollectionCardCoverImages;
