/**
 * Implements all the behaviors of moment.fromNow(). Pass a
 * valid JavaScript Date object and the method will return the
 * time that has passed since that date in a human-readable
 * format. Passes the moment test suite for `fromNow()`.
 * See: https://momentjs.com/docs/#/displaying/fromnow/
 *
 * Copied from https://gist.github.com/davidrleonard/259fe449b1ec13bf7d87cde567ca0fde
 *
 * @example
 *
 *     var pastDate = new Date('2017-10-01T02:30');
 *     var message = fromNow(pastDate);
 *     //=> '2 days ago'
 *
 * @param  {Date} Native JavaScript Date object
 * @return {string}
 */
function fromNow(date, abbreviate = false) {
  const seconds = Math.floor((new Date() - date) / 1000);
  const years = Math.floor(seconds / 31536000);
  const months = Math.floor(seconds / 2592000);
  const days = Math.floor(seconds / 86400);

  if (days > 548) {
    return abbreviate ? `${years}yr` : `${years} years ago`;
  }
  if (days >= 320 && days <= 547) {
    return abbreviate ? '1yr' : 'a year ago';
  }
  if (days >= 45 && days <= 319) {
    return abbreviate ? `${months}mo` : `${months} months ago`;
  }
  if (days >= 26 && days <= 45) {
    return abbreviate ? '1mo' : 'a month ago';
  }

  const hours = Math.floor(seconds / 3600);

  if (hours >= 36 && days <= 25) {
    const roundedDay = days === 1 ? 2 : days;  // Handling '1 days ago'
    return abbreviate ? `${days}d` : `${roundedDay} days ago`;
  }
  if (hours >= 22 && hours <= 35) {
    return abbreviate ? '1d' : 'a day ago';
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes >= 90 && hours <= 21) {
    return abbreviate ? `${hours}h` : `${hours} hours ago`;
  }
  if (minutes >= 45 && minutes <= 89) {
    return abbreviate ? '1h' : 'an hour ago';
  }
  if (seconds >= 90 && minutes <= 44) {
    return abbreviate ? `${minutes}m` : `${minutes} minutes ago`;
  }
  if (seconds >= 45 && seconds <= 89) {
    return abbreviate ? '1m' : 'a minute ago';
  }
  if (seconds >= 0 && seconds <= 45) {
    return abbreviate ? 'now' : 'a few seconds ago';
  }

  return abbreviate ? 'later' : 'in the future';
}

export default fromNow;
