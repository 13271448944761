// handles "Filter By" collapse-able sidenav sections on Products + Brands pages
// this targets old SCSS classes and must be removed in the future

const filterChoices = document.querySelectorAll('.filter-li');

if (filterChoices) {
  filterChoices.forEach((item) => {
    item.addEventListener('click', () => {
      if (
        item.querySelector('.sub-filter').classList.contains('dnone') &&
        item.firstChild
      ) {
        item.querySelector('.sub-filter').classList.remove('dnone');
        if (item.classList.contains('closed')) {
          item.classList.remove('closed');
        }
        if (!item.classList.contains('opened')) {
          item.classList.add('opened');
        }
      } else if (
        !item.querySelector('.sub-filter').classList.contains('dnone')
      ) {
        item.querySelector('.sub-filter').classList.add('dnone');
        if (item.classList.contains('opened')) {
          item.classList.remove('opened');
        }
        if (!item.classList.contains('closed')) {
          item.classList.add('closed');
        }
      }
    });
  });
}

// this targets new SCSS classes and must replaces the old one
const filterChoicesNew = document.querySelectorAll('.filter-form__list-item');

if (filterChoicesNew) {
  filterChoicesNew.forEach((item) => {
    item.addEventListener('click', () => {
      const subFilter = item.querySelector('.filter-form__sub-filter');
      if (subFilter)
        subFilter.classList.toggle('filter-form__sub-filter--hidden');
      item.classList.toggle('filter-form__list-item--expanded');
    });
  });
}
