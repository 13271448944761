import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Skeleton, TextField, ThemeProvider } from '@mui/material';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';
import useLocalStorage from '../../../../../../../config/static/js/utils/useLocalStorage';

export default function OrganizationSelector() {
  const [loading, setLoading] = React.useState(true);
  const [activeOrg, setActiveOrg] = React.useState({});
  const [organizations, setOrganizations] = React.useState([]);
  const [_selectedProfile, setSelectedProfile] = useLocalStorage(
    'selectedProfile',
    {}
  );

  React.useEffect(() => {
    fetch(`/api/users/current-user/organizations/?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        const orgs = [];
        data.forEach((org) => {
          orgs.push({ label: org.name, value: org.pk });
          if (org.is_active_for_user) {
            setActiveOrg(org);
          }
        });
        setOrganizations(orgs);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Skeleton variant="rectangular" width={200} height={50} />
  ) : (
    <ThemeProvider theme={PressHookTheme}>
      <Autocomplete
        size="small"
        onChange={(_event, newValue) => {
          fetch(
            `/api/users/current-user/active-organization/?time=${new Date().getTime()}`,
            {
              method: 'PATCH',
              body: JSON.stringify({ organization: newValue.value }),
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCookie('csrftoken'),
              },
            }
          )
            .then((response) => {
              if (response.ok) {
                setSelectedProfile({}); // empty selected profile
                return response.json();
              } else {
                alert('Failed to update active organization');
              }
            })
            .then((data) => {
              if (data.user.is_brand_rep) {
                window.location.href = '/dashboard/b/';
              } else {
                window.location.href = '/dashboard/m/';
              }
            });
        }}
        autoHighlight
        defaultValue={activeOrg.name}
        id="organization-selector"
        options={organizations}
        sx={{ width: 200 }}
        renderInput={(params) => <TextField {...params} label="Organization" />}
      />
    </ThemeProvider>
  );
}
