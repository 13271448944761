import React from 'react';
import AddBrandForm from './addBrandForm';
import PlanSelectionList from '../../../../../../subscriptions/static/subscriptions/js/components/planSelection/PlanSelectionList';
import Checkout from '../../../../../../subscriptions/static/subscriptions/js/components/checkout/Checkout';
import getValueFromQueryParams from '../../../../../../../config/static/js/components/jsx/utils/getValueFromQueryParams';

function AddBrandModal({ isOpenExternal, setIsOpenExternal }) {
  const outsideModalRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [section, setSection] = React.useState('AddBrandForm');
  const [objectId, setObjectId] = React.useState(brand?.pk || '');
  const [brand, setBrand] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [selectedBillingPeriod, setSelectedBillingPeriod] =
    React.useState(null);
  const [initialPromoCode, setInitialPromoCode] = React.useState(null);
  const [initialSlotPk, setInitialSlotPk] = React.useState(null);

  /**
   * Synching external and internal isOpen states
   */
  React.useEffect(() => {
    if (isOpenExternal === undefined) return;
    setIsOpen(isOpenExternal);
  }, [isOpenExternal]);

  React.useEffect(() => {
    if (setIsOpenExternal === undefined) return;
    setIsOpenExternal(isOpen);
  }, [isOpen]);

  /**
   * Handles closing the modal when clicking outside of it
   */
  const handleClickOutsideModalArea = (event) => {
    if (outsideModalRef.current && outsideModalRef.current == event.target) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutsideModalArea);
    return () =>
      window.removeEventListener('click', handleClickOutsideModalArea);
  });

  /**
   * Clicking on an element with .js-add-brand-modal-open will trigger the modal
   */
  const triggerButtonClickHandler = (event) => {
    const target = event.target.closest('.js-add-brand-modal-open');
    if (target && !isOpen) {
      event.stopImmediatePropagation();
      setIsOpen(true);
      let promoCode =
        target.dataset.promoCode || getValueFromQueryParams('promo');
      setInitialPromoCode(promoCode);
      let slotPk = target.dataset.slotPk;
      setInitialSlotPk(slotPk);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', triggerButtonClickHandler);
    return () => {
      document.removeEventListener('click', triggerButtonClickHandler);
    };
  }, []);

  /**
   * Lets redirect to the brand editor when the subscription is created
   */
  React.useEffect(() => {
    if (section === 'CheckoutSuccess' && objectId) {
      window.location.href = `/portal/brands/edit/${objectId}/`;
    }
  }, [section]);

  /**
   * Used to set the brand's pk after the checkout success
   */
  React.useEffect(() => {
    setObjectId(brand?.pk || '');
  }, [brand]);

  return (
    isOpen && (
      <div className="modal modal--active" ref={outsideModalRef}>
        <div
          className={
            section == 'AddBrandForm'
              ? 'modal__box add-brand-modal'
              : 'modal__box'
          }
        >
          <button className="modal__close-btn">
            <img
              className="modal__close-btn-img"
              onClick={() => setIsOpen(false)}
              src={`${staticUrl}/images/svg/modal-close-black.svg`}
              alt="close"
            />
          </button>
          <div className="add-brand-modal__content">
            {section == 'AddBrandForm' && (
              <AddBrandForm
                brand={brand}
                setSection={setSection}
                setBrand={setBrand}
                initialSlotPk={initialSlotPk}
              />
            )}

            {section == 'PlanSelectionList' && (
              <PlanSelectionList
                objectType={'brand'}
                objectId={objectId}
                setSection={setSection}
                setSelectedPlan={setSelectedPlan}
                setSelectedBillingPeriod={setSelectedBillingPeriod}
                backButtonSection={'AddBrandForm'}
              />
            )}

            {section === 'Checkout' && (
              <Checkout
                objectType={'brand'}
                objectId={objectId}
                setObjectId={setObjectId}
                selectedPlan={selectedPlan}
                selectedBillingPeriod={selectedBillingPeriod}
                setSection={setSection}
                initialPromoCode={initialPromoCode}
                isNewSubscription={true}
                backButtonSection={'PlanSelectionList'}
                newObjectData={brand}
              />
            )}

            {section === 'CheckoutSuccess' && (
              <div className="price__top-content">
                <h2 className="price__title modal-detail-plan__price-title">
                  Operation succeeded!
                </h2>

                <p className="subhead price__desc modal-detail-plan__price-desc">
                  Thanks for being part of the Press Hook!
                </p>

                <a
                  className="cta-btn cta-btn--wider price-tab__card-btn price__cta-btn"
                  onClick={() => {
                    window.location.href = `/portal/brands/edit/${objectId}/`;
                  }}
                >
                  Go to your Brand editor
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default AddBrandModal;
