import React from 'react';
import ReactDOM from 'react-dom/client';
import AddBrandModal from './addBrandModal';

/**
 * Injects the add brand modal into its container
 */
export default function injectAddBrandModalComponent() {
  const addBrandModalContainer = document.getElementById(
    'addBrandModalContainer'
  );

  if (addBrandModalContainer) {
    const rootContainerForAddBrandModal = ReactDOM.createRoot(
      addBrandModalContainer
    );
    rootContainerForAddBrandModal.render(<AddBrandModal />);
  }
}

document.addEventListener('DOMContentLoaded', injectAddBrandModalComponent);
