import React from 'react';

/**
 * React hook for getting details about a given conversation
 */
function useConversationDetails(conversation) {
  const [title, setTitle] = React.useState(conversation.getTitle());
  const [iconURL, setIconURL] = React.useState(conversation.getIcon());

  React.useEffect(() => {
    function handleUpdate() {
      setTitle(conversation.getTitle());
      setIconURL(conversation.getIcon());
    }

    handleUpdate();

    conversation.on('member_list_updated', handleUpdate);

    return () => {
      conversation.off('member_list_updated', handleUpdate);
    };
  }, [conversation]);

  return [title, iconURL];
}

export default useConversationDetails;
