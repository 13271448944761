import React, { useState, useEffect, use } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Skeleton,
  Badge,
  Box,
  Tooltip,
  Chip,
  Avatar,
} from '@mui/material';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
/* eslint-disable max-len */
import AddToCollectionButton from '../../../../../../foldering/static/foldering/js/collections/AddToCollection/AddToCollectionButton';
import UpgradeSubscriptionModal from '../../../../../../subscriptions/static/subscriptions/js/components/upgradeSubscriptionModal/UpgradeSubscriptionModal';
/* eslint-enable max-len */
import PitchButton from '../../../../../../pitches/static/pitches/js/components/pitchAIDialog/PitchButton';
import logError from '../../../../../../../config/static/js/utils/logError';
import { useLoadingState } from '../../../../../../core/static/core/js/utils/globalLoadingState';

function MediaProfileCard({
  mediaProfilePk,
  hasMediaDatabase,
  pitchFormReactFlag = false,
  loadingNamespace = null,
  cardStyle = {},
}) {
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Assuming initial load
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  if (loadingNamespace) {
    const [_isRendering, setIsRendering] = useLoadingState(
      loadingNamespace,
      `media-profile-${mediaProfilePk}`,
      true
    );

    // Set the "rendering" loading state to false when profile is loaded/rendered
    useEffect(() => {
      if (!isLoading && Object.keys(profile).length > 0) {
        setIsRendering(false);
      }
    }, [loadingNamespace, isLoading, profile]);
  }

  useEffect(() => {
    fetch(`/api/media-profiles/${mediaProfilePk}/?view=card`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
        'Cache-Control': 'no-cache',
      },
    })
      .then((res) => {
        if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
        return res.json();
      })
      .then((data) => {
        setProfile(data);
      })
      .catch((error) => {
        logError('Unable to fetch profile:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [mediaProfilePk]);

  const defaultCardStyle = {
    position: 'relative',
    height: '240px',
    width: '296px',
  };

  const finalCardStyle = { ...defaultCardStyle, ...cardStyle };
  const [elevation, setElevation] = useState(1);

  return (
    <>
      {isLoading ? (
        <Card
          className="grid-layout__card grid-layout__card--white"
          sx={finalCardStyle}
        >
          <CardContent className="grid-layout__card-text-container">
            <Skeleton
              variant="circular"
              width={56}
              height={56}
              animation="wave"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '16px' }}
              width="80%"
              animation="wave"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '12px' }}
              width="60%"
              animation="wave"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '12px' }}
              width="50%"
              animation="wave"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: '12px' }}
              width="70%"
              animation="wave"
            />
          </CardContent>
        </Card>
      ) : (
        <Card
          sx={finalCardStyle}
          elevation={elevation}
          onMouseOver={() => setElevation(5)}
          onMouseOut={() => setElevation(1)}
        >
          <AddToCollectionButton
            objectPk={profile.id}
            objectType="mediaprofile"
          />
          {pitchFormReactFlag && <PitchButton mediaPk={profile.id} />}
          <Box
            sx={{ textDecoration: 'none', color: 'inherit' }}
            component="a"
            onClick={(e) => {
              if (!hasMediaDatabase) {
                e.preventDefault();
                setShowUpgradeModal(true);
              }
            }}
            href={hasMediaDatabase ? `/media-profiles/${profile.slug}` : '#'}
          >
            <CardContent className="grid-layout__card-text-container">
              <Box
                className="grid-layout__card-section grid-layout__card-section--header"
                sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
              >
                <Box>
                  <Badge
                    overlap="circular"
                    badgeContent={
                      profile.message_on_presshook && (
                        <Tooltip
                          title="Uses Press Hook Messaging"
                          placement="right-start"
                          sx={{ fontSize: 10 }}
                        >
                          <MarkEmailReadOutlinedIcon
                            sx={{ fontSize: 15, padding: '1px' }}
                          />
                        </Tooltip>
                      )
                    }
                    sx={{
                      '.MuiBadge-badge': {
                        backgroundColor: '#fff',
                        color: '#262121',
                        border: '1px solid #e4e4e4',
                        top: '20%',
                        right: '20%',
                        padding: 0,
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor: '#e7e7e7',
                        border: '1px solid #262121',
                        marginRight: '8px',
                        color: '#6c6c6c',
                        fontSize: 20,
                      }}
                      src={profile.profile_image}
                      alt={hasMediaDatabase ? profile.name : ''}
                      slotProps={{
                        img: { loading: 'lazy', decoding: 'async' },
                      }}
                    >
                      {!profile.profile_image &&
                        profile?.name
                          ?.split(' ')
                          .map((n) => n[0])
                          .slice(0, 2)
                          .join('')
                          .toUpperCase()}
                    </Avatar>
                  </Badge>
                </Box>
                <Box className="grid-layout__card-text grid-layout__card-text--media-profile-title">
                  {hasMediaDatabase ? (
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="grid-layout__card-text grid-layout__card-text--username"
                      sx={{
                        fontFamily:
                          'Chiswick Grotesque Web, Helvetica Neue, arial, sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '18px',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <span
                        style={{
                          maxWidth: 160,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          display: 'inline-block',
                        }}
                      >
                        {profile.name}
                      </span>
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '16px' }}
                      width="80%"
                      animation="wave"
                    />
                  )}
                  {profile.title && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="div"
                      className="grid-layout__card-text grid-layout__card-text--media-title"
                      sx={{
                        color: '#9f9f9f',
                        fontFamily: 'DM Mono,monospace',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        letterSpacing: '-.02em',
                        lineHeight: '16px',
                        maxHeight: '32px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {profile.title}
                    </Typography>
                  )}
                </Box>
              </Box>
              {profile.industries && profile.industries.length > 0 && (
                <Box className="grid-layout__card-section">
                  <Typography
                    className="grid-layout__card-text--section-title"
                    sx={{
                      color: '#a8a6a6',
                      fontFamily:
                        'Chiswick Grotesque Web, Helvetica Neue, arial, sans-serif',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      letterSpacing: '.1em',
                      textTransform: 'uppercase',
                    }}
                  >
                    Beats
                  </Typography>
                  <Box className="tags">
                    {profile.industries.slice(0, 5).map((industry) => (
                      <Chip
                        key={industry.pk}
                        className="tags__tag"
                        label={industry.name}
                        sx={{
                          borderRadius: '4px',
                          color: '#262121',
                          display: 'inline-block',
                          fontFamily:
                            'Chiswick Grotesque Web,Helvetica Neue,arial,sans-serif',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '17px',
                          padding: '6px',
                          textDecoration: 'none',
                          width: 'max-content',
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              )}
              {profile.media_outlets && profile.media_outlets.length > 0 && (
                <Box className="grid-layout__card-section">
                  <Typography
                    className="grid-layout__card-text--section-title"
                    sx={{
                      color: '#a8a6a6',
                      fontFamily:
                        'Chiswick Grotesque Web, Helvetica Neue, arial, sans-serif',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      letterSpacing: '.1em',
                      textTransform: 'uppercase',
                    }}
                  >
                    Outlets
                  </Typography>
                  <Box
                    className="grid-layout__card-media-outlets"
                    sx={{
                      gap: '6px',
                      justifyContent: 'flex-start',
                      flexFlow: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {profile.media_outlets.slice(0, 3).map((outlet) => (
                      <Box
                        key={outlet.pk}
                        className="grid-layout__media-outlet"
                        sx={{
                          gap: '2px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100%',
                        }}
                      >
                        {outlet.logo ? (
                          <Tooltip
                            title={outlet.name}
                            placement="right-start"
                            sx={{ fontSize: 10 }}
                          >
                            <Avatar
                              src={outlet.logo}
                              alt={outlet.name}
                              slotProps={{
                                img: { loading: 'lazy', decoding: 'async' },
                              }}
                              className="grid-layout__media-outlet-image"
                              sx={{
                                width: '24px',
                                height: '24px',
                                bgcolor: '#fff',
                                color: '#262121',
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Box className="grid-layout__media-outlet-image">
                            <Typography variant="caption">N/A</Typography>
                          </Box>
                        )}
                        <Typography
                          variant="body2"
                          className="grid-layout__card-text"
                          component="div"
                          sx={{
                            fontFamily:
                              'Chiswick Grotesque Web,Helvetica Neue,arial,sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            color: '#262121',
                            lineHeight: '14px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxHeight: '28px',
                          }}
                        >
                          {outlet.name}
                        </Typography>
                      </Box>
                    ))}
                    {profile.media_outlets.length > 3 && (
                      <Avatar
                        sx={{
                          color: '#262121',
                          bgcolor: '#fff',
                          width: '24px',
                          height: '24px',
                        }}
                      >
                        +{profile.media_outlets.length - 3}
                      </Avatar>
                    )}
                  </Box>
                </Box>
              )}
            </CardContent>
          </Box>
        </Card>
      )}
      {showUpgradeModal && (
        <UpgradeSubscriptionModal
          selectedFeatureExternal="pitchable-media-database"
          setIsOpenExternal={setShowUpgradeModal}
          isOpenExternal={showUpgradeModal}
        />
      )}
    </>
  );
}

MediaProfileCard.propTypes = {
  mediaProfilePk: PropTypes.number.isRequired,
  hasMediaDatabase: PropTypes.bool.isRequired,
  pitchFormReactFlag: PropTypes.bool,
};

export default MediaProfileCard;
