import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering sample request address
 */
function SampleRequestAddressComponent({ conversation }) {
  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">Address:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="sample-request-shipping-name"
      >
        {conversation.getContentObject().shipping_name}
      </span>
      <span
        className="conversation-detail-view__details-text"
        data-testid="sample-request-shipping-line-1"
      >
        {conversation.getContentObject().shipping_address_line_1}
      </span>
      <span
        className="conversation-detail-view__details-text"
        data-testid="sample-request-shipping-line-2"
      >
        {conversation.getContentObject().shipping_address_line_2}
      </span>
      <span
        className="conversation-detail-view__details-text"
        data-testid="sample-request-shipping-city-state-code"
      >
        {conversation.getContentObject().shipping_city},{' '}
        {conversation.getContentObject().shipping_state}{' '}
        {conversation.getContentObject().shipping_postal_code}
      </span>
    </div>
  );
}

SampleRequestAddressComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SampleRequestAddressComponent;
