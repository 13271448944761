import React from 'react';
import LoadingRectangle from '../../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import LoadingCircle from '../../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingCircle';

function CollectionItemLoadingCard() {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Randomly changing the height of the cards
  // for masonry effect on the grid
  const randomInt = getRandomInt(235, 350);
  const styles = { height: `${randomInt}px` };

  return (
    <div
      className="collection-item-card__container"
      style={styles}
      data-testid="collection-item-loading-card"
    >
      <div className="collection-item-card__image">
        <LoadingRectangle height={randomInt - 36} />
      </div>
      <div className="collection-item-card__icon">
        <LoadingCircle diameter={32} />
      </div>
      <div className="collection-item-card__title">
        <LoadingRectangle height={22} width={120} />
      </div>
      <div className="collection-item-card__subtitle">
        <LoadingRectangle height={14} width={60} />
      </div>
    </div>
  );
}

export default CollectionItemLoadingCard;
