import ConversationsLayer from './BaseClasses/ConversationsLayer';

/**
 * PHChatApi object
 */
class PHChatApi extends ConversationsLayer {
  initialized = false;

  init() {
    if (!this.initialized) {
      this.initialized = true;
      this.determineIdentity();
    }
  }

  reset() {
    this.initialized = false;
  }
}

/**
 * Instantiate globally and export the global instance.
 */
const ApiConnection = new PHChatApi();

export default ApiConnection;
