import React from 'react';
import CollectionListRender from './CollectionListRender';
import CollectionBaseModal from '../CommonComponents/CollectionModal';
import CollectionForm from '../CommonComponents/CollectionForm';
import updateData from '../../../../../../../config/static/js/utils/updateData';
import utils from '../utils';

function CollectionListPage() {
  const { showMessage } = utils;
  const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);
  const [refreshSignal, setRefreshSignal] = React.useState(0);

  // Handles create new collection form submitting
  const createNewCollectionHandler = async (event) => {
    const target = event.target;
    if (target.classList.contains('collections-modal__form')) {
      const url = `/api/foldering/folders/`;
      const formTextInput = target.querySelector('input');
      const newCollectionTitle = formTextInput.value;
      const formSubmitButton = target.querySelector('button');

      // Disabling the submit button while loading
      formSubmitButton.disabled = true;

      try {
        const res = await updateData(
          url,
          { json: { title: newCollectionTitle } },
          'POST',
          null,
          true
        );

        if (res?.code === 400) {
          showMessage(res.body.title[0], true);
        }

        // Cleaning the form
        formTextInput.value = '';

        setCreateModalIsOpen(false);
        showMessage(`Collection ${newCollectionTitle} created.`);
        setRefreshSignal(refreshSignal + 1);
      } catch (error) {
        showMessage(`There was an error trying to create item. ${error}`, true);
      }

      // Re-enabling the button after finish
      formSubmitButton.disabled = false;
    }
  };

  return (
    <div className="collections__container">
      <CollectionBaseModal
        isOpen={createModalIsOpen}
        setIsOpen={setCreateModalIsOpen}
        title={'New Collection'}
      >
        <CollectionForm createHandler={createNewCollectionHandler} />
      </CollectionBaseModal>
      <div className="collections__header">
        <h3 className="collections__header-text">Saved Collections</h3>
        <div className="collections__header-button">
          <img
            className="collections__button"
            src={`${staticUrl}/foldering/images/circle-plus.svg`}
            onClick={() => setCreateModalIsOpen(true)}
            alt="New Collection"
          />
        </div>
      </div>
      <div className="collections__body">
        <CollectionListRender refreshSignal={refreshSignal} />
      </div>
    </div>
  );
}

export default CollectionListPage;
