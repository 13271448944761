// handles changing of border on Membership Tiers (Brand Modal)
const membershipTiers = document.querySelectorAll('.select-level > .card');

if (membershipTiers) {
  membershipTiers.forEach((item) => {
    const itemInput = item.querySelector('.inner > input[type="radio"]');

    item.onclick = function () {
      membershipTiers.forEach((el) => {
        el.style.border = '1px dashed #262121';
      });

      if (itemInput.checked) {
        item.style.border = '1px solid #D2281A';
      }
    };
  });
}
