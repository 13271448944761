import React from 'react';
import PropTypes from 'prop-types';

function EmptyStateCollection(props) {
  const explorePresshook = () => {
    window.location.href = '/';
  };

  return (
    <div className="container empty-collection-container">
      <div className="empty-collection-container__book">
        <img src={`${staticUrl}/foldering/images/book-icon.svg`} />
      </div>
      {props.component === 'component-list' ? (
        <>
          <div className="empty-collection-container__title-list">
            Nothing here yet!
          </div>
          <p className="empty-collection-container__message">
            You don't have any collection created. Explore the
            <br />
            variety we have for you and start saving great stuff!
          </p>
        </>
      ) : (
        <>
          <div className="empty-collection-container__title-item">
            No items added
          </div>
          <p className="empty-collection-container__message">
            There are no items added to this collection yet.
            <br />
            Explore all the options for you and save awesome stuff!
          </p>
        </>
      )}
      <button
        className="empty-collection-container__explore-presshook"
        onClick={explorePresshook}
      >
        Explore Press Hook
      </button>
    </div>
  );
}

EmptyStateCollection.propTypes = {
  component: PropTypes.string.isRequired,
  explorePresshook: PropTypes.func,
};

export default EmptyStateCollection;
