import { createTheme } from '@mui/material/styles';

// JavaScript Object for theme colors
export const themeColors = {
  red: '#d2281a', // $btn and $red
  lightRed: 'rgba(210, 40, 26, 20%)',
  lightestRed: 'rgba(210, 40, 26, 8%)',
  orange: '#ffb46f', // Secondary color
  green: 'rgba(57, 177, 91, 87%)', // $green-transparent
  beige: 'rgba(255, 250, 245, 100%)',
  white: '#ffffff', // $white
  black: '#262121', // $black
  darkGrey: '#5a5a5a', // $price-dark-grey
  lightBlack: 'rgba(38, 33, 33, 50%)', // Subtitle2 color
  greyAlpha: 'rgba(0, 0, 0, 50%)', // For MuiSelect and other components
  bodyTextColor: 'rgba(0, 0, 0, 87%)', // For body1 text
  pureBlack: '#000000', // For MuiAvatar border
  grey: 'rgb(216, 216, 216)',
};

// JavaScript Object for theme fonts
export const themeFonts = {
  primary: '"Chiswick Grotesque Web", "Helvetica Neue", arial, sans-serif',
  secondary: "'Canela', serif",
};

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: themeColors.red,
    },
    secondary: {
      main: themeColors.orange,
    },
    success: {
      main: themeColors.green,
    },
    background: {
      default: themeColors.beige,
      paper: themeColors.white,
    },
    text: {
      primary: themeColors.black,
    },
  },
  typography: {
    fontFamily: themeFonts.primary,
    h1: {
      fontSize: 48,
      lineHeight: 1,
      fontFamily: themeFonts.secondary,
    },
    h2: {
      fontSize: 40,
      fontFamily: themeFonts.secondary,
    },
    h3: {
      fontSize: 28,
      fontFamily: themeFonts.secondary,
      lineHeight: '38px',
      fontWeight: 400,
    },
    h5: {
      fontSize: 28,
      fontFamily: themeFonts.secondary,
      lineHeight: '38px',
    },
    h6: {
      color: themeColors.darkGrey,
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    subtitle2: {
      color: themeColors.lightBlack,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    subtitle3: {
      color: themeColors.lightBlack,
      fontSize: '16px',
      fontWeight: 400,
      textAlign: 'center',
    },
    body1: {
      color: themeColors.bodyTextColor,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '28px',
    },
    body2: {
      color: themeColors.greyAlpha,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '28px',
    },
    mediaDatabaseFilterTitle: {
      color: themeColors.lightBlack,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.15px',
      textTransform: 'uppercase',
      mt: '20px',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          textTransform: 'uppercase',
          color: themeColors.greyAlpha,
          fontFamily: themeFonts.primary,
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '24px',
          letterSpacing: '0.17px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: themeColors.greyAlpha,
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorDefault': {
            background: themeColors.beige,
            color: themeColors.black,
          },
          fontFamily: themeFonts.primary,
          fontSize: '13px',
          fontWeight: 600,
          lineHeight: '18px',
        },
        outlined: {
          '&.MuiChip-colorDefault': {
            borderColor: themeColors.black,
            backgroundColor: themeColors.white,
          },
          '& .MuiChip-deleteIcon': {
            color: themeColors.black,
            fontSize: '14px',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: '100%',
          border: `1px solid ${themeColors.pureBlack}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily:
            '"Chiswick Grotesque Web", "Helvetica Neue", arial, sans-serif',
          fontWeight: 400,
          lineHeight: '16px',
          wordWrap: 'break-word',
          borderRadius: 2,
          padding: '10px 20px',
          // minHeight: '50px',
          textTransform: 'none',
        },
        uppercased: {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: themeColors.red,
            borderColor: themeColors.red,
          },
        },
        {
          props: { variant: 'outlined', color: 'black' },
          style: {
            color: themeColors.black,
            borderColor: themeColors.black,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            color: themeColors.white,
            background: themeColors.red,
          },
        },
        {
          props: { color: 'green' },
          style: {
            color: themeColors.green,
            borderColor: themeColors.green,
          },
        },
        {
          // This is used by the AddToCollectionButton component in the brand/product detail page header
          props: { variant: 'text-only' },
          style: {
            minHeight: 'auto',
            color: themeColors.black,
            borderColor: themeColors.black,
          },
        },
        {
          // This is used by the AddToCollectionButton component in the brand/product detail page header
          props: { variant: 'text-only', color: 'white' },
          style: {
            minHeight: 'auto',
            color: themeColors.white,
            borderColor: themeColors.white,
          },
        },
      ],
    },
    MuiModal: {
      styleOverrides: {
        '&:focus-visible': {
          outline: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        variants: [
          {
            props: { variant: 'modal-description' },
            style: {
              fontSize: 16,
              fontWeight: '300',
              lineHeight: 20,
              wordWrap: 'break-word',
            },
          },
        ],
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: 'center',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: `1px solid ${themeColors.black}`,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          /* used by the AddToCollectionButton and PitchButton components */
          props: { variant: 'border' },
          style: {
            border: '1px solid',
            backgroundColor: themeColors.white,
            height: '32px',
            width: '32px',
            '&:hover': {
              backgroundColor: themeColors.white,
              boxShadow: `0 0 3px 3px ${themeColors.grey}`,
              '& .MuiSvgIcon-root': {
                transform: 'scale(1.2)',
              },
            },
            '& .MuiSvgIcon-root': {
              fontSize: '15px',
            },
          },
        },
        {
          /* used by the PitchButton component */
          props: { color: 'red' },
          style: {
            borderColor: themeColors.red,
            color: themeColors.red,
            '&:hover': {
              backgroundColor: themeColors.red,
              color: themeColors.white,
            },
          },
        },
        {
          props: { variant: 'with-text' },
        },
        {
          props: { variant: 'modal-close' },
          style: {
            position: 'absolute',
            right: 8,
            top: 20,
            fontSize: 24,
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '540px',
        },
      },
    },
    MuiDialogTitle: {
      variants: [
        {
          /* This can be used by a modal where the title have a beige background */
          props: { variant: 'modal' },
          style: {
            fontSize: 28,
            fontFamily: themeFonts.secondary,
            lineHeight: '32px',
            fontWeight: 300,
            backgroundColor: themeColors.beige,
            padding: 24,
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            border: 0,
            borderBottom: `1px dashed ${themeColors.lightBlack}`,
          },
        },
      ],
    },
    MuiDialogActions: {
      variants: [
        {
          /* This can be used by a modal where all action buttons are spread across the full width of the modal */
          props: { variant: 'fullwidth' },
          style: {
            justifyContent: 'space-between',
            padding: '16px 24px',
            '& > *': {
              flex: 1,
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '16px',
          color: themeColors.black,
        },
      },
    },
    MuiLink: {
      variants: [
        {
          /* This must override the default color of the link */
          props: { variant: 'red' },
          style: {
            color: themeColors.red,
            '&:hover': {
              color: themeColors.red,
            },
            '&:visited': {
              color: themeColors.red,
            },
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'no-paper' },
          style: {
            boxShadow: 'none',
            borderRadius: 0,
            backgroundColor: 'transparent',
            padding: 0,
          },
        },
      ],
    },
  },
};
let theme = createTheme(themeOptions);

// Additional color palettes
theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    black: theme.palette.augmentColor({
      color: {
        main: themeColors.bodyTextColor,
      },
      name: 'black',
    }),
    green: theme.palette.augmentColor({
      color: {
        main: themeColors.green,
      },
      name: 'green',
    }),
  },
});

export const PressHookTheme = theme;
