import showToast from '../../../../../../config/static/js/utils/jsx/showToast';
import fetchData from '../../../../../../config/static/js/utils/fetchData';
import Collection from './DataModels/Collection';

/**
 * Returns paginated content from API
 */
const getContentFromAPI = async (
  url,
  isRefresh,
  collections,
  setIsLoading,
  setNextPageURL,
  setCollections
) => {
  setIsLoading(true);

  if (isRefresh) setCollections([]);

  const abortController = new AbortController();
  const res = await fetchData(url, abortController.signal);
  setNextPageURL(res.next);

  if (isRefresh) {
    // Even though collections are set to empty above, it still needs to do that because of the
    // nature of React.useState as shown in this SO question https://stackoverflow.com/a/54069332
    setCollections(
      res.results?.map((collection) => new Collection(collection))
    );
  } else {
    setCollections([
      ...collections,
      ...res.results?.map((collection) => new Collection(collection)),
    ]);
  }

  setIsLoading(false);
};

/**
 * Shows messages using a toast
 * @param {string} message
 * @param {boolean} isError
 */
const showMessage = (message, isError) => {
  const icon = isError ? '❌' : '✅';
  showToast(`${icon}&nbsp;&nbsp;&nbsp;${message}`);
};

// Exporting the functions
export default {
  showToast,
  getContentFromAPI,
  showMessage,
};
