import CollectionItem from './CollectionItem';

/**
 * This is the data model for collections.
 *
 * The goal is to have one data model for all collections, that's why some fields are optional:
 *  - cover_images and item_count are from collection list API serializer
 *  - items is from collection detail API serializer
 */
export default class Collection {
  constructor(data) {
    this.pk = data.pk;
    this.slug = data.slug;
    this.title = data.title;
    this.folderedItemPk = data.foldered_item_pk;

    if (Object.hasOwn(data, 'cover_images'))
      this.coverImages = data.cover_images;

    if (Object.hasOwn(data, 'item_count')) this.itemCount = data.item_count;

    if (Object.hasOwn(data, 'items'))
      this.items = data.items.map((item) => new CollectionItem(item));
  }

  getSlug() {
    return this.slug;
  }

  getTitle() {
    return this.title;
  }

  getFolderedItemPk() {
    return this.folderedItemPk;
  }

  /**
   * List of the last 3 cover images for the collection list card item
   *   (returns empty if is a collection detail response)
   * @returns Array
   */
  getCoverImages() {
    return Object.hasOwn(this, 'coverImages')
      ? this.coverImages.slice(0, 3)
      : [];
  }

  /**
   * Number of items in a collection
   *   (items.length if is a collection detail response
   *    and itemCount for collection list response)
   * @returns Array
   */
  getItemCount() {
    let itemCount = 0;

    if (Object.hasOwn(this, 'itemCount')) {
      itemCount = this.itemCount;
    } else if (Object.hasOwn(this, 'items')) {
      itemCount = this.items.length;
    }

    return itemCount !== 1 ? `${itemCount} items` : `${itemCount} item`;
  }

  /**
   * List of items in the collection
   *   (returns empty if is a collection list response)
   * @returns Array
   */
  getItems() {
    return Object.hasOwn(this, 'items') ? this.items : [];
  }
}
