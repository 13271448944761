import React from 'react';
import PropTypes from 'prop-types';
import NavInboxDropdown from './NavInboxDropdown';
import AbsoluteMenu from '../FloatingMenus/AbsoluteMenu';
import ApiConnection from '../PHChatApi/PHChatApi';
import SocketConnection from '../PHChatApi/Socketry/ChatSocketConnector';
import useAllConversationsReadState from '../PHChatApi/ReactHooks/useAllConversationsReadState';
import MessageNotificationToasts from '../MessageNotificationToasts/MessageNotificationToasts';

/**
 * Messaging Inbox for Navbar
 * Shows button that opens dropdown with 4 most recent conversations.
 * Red dot illuminates if there are any unread messages
 */
function NavInbox({ showToasts = false }) {
  const inboxButton = React.useRef(null);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [inboxX, showInboxX] = React.useState(0);
  const [inboxY, showInboxY] = React.useState(0);

  const unread = useAllConversationsReadState();

  /**
   * Logic for showing/hiding options menu.
   */
  const showInbox = React.useCallback(
    (event) => {
      if (showDropDown) {
        setShowDropDown(false);
        return;
      }

      if (!event) {
        return;
      }

      if (!loaded) {
        ApiConnection.init();
        setLoaded(true);
      }

      const buttonRect = inboxButton.current.getBoundingClientRect();
      const mobileWidth = window.innerWidth < 500;

      showInboxX(mobileWidth ? window.innerWidth / 2 : buttonRect.left - 89);
      showInboxY(mobileWidth ? 65 : buttonRect.top + 26.5);
      setShowDropDown(true);
    },
    [showDropDown, loaded, inboxX, inboxY, inboxButton]
  );

  // Connect to API
  React.useEffect(() => {
    SocketConnection.init();
  }, []);

  return (
    <div>
      <button
        type="button"
        className="chat-nav-inbox__button"
        data-testid="chat-nav-inbox-button"
        onClick={showInbox}
        ref={inboxButton}
      >
        <img
          src={`${staticUrl}/images/svg/email_black.svg`}
          alt="messaging icon"
        />
        {unread && (
          <div
            className="conversation-list__unread-dot conversation-list__unread-dot--inbox"
            data-testid="inbox-master-unread-dot"
          />
        )}
      </button>
      <AbsoluteMenu
        x={inboxX}
        y={inboxY}
        show={showDropDown}
        handleDismiss={showInbox}
        buttonRef={inboxButton}
        blank
      >
        <NavInboxDropdown />
      </AbsoluteMenu>
      {showToasts && <MessageNotificationToasts />}
    </div>
  );
}

NavInbox.propTypes = {
  showToasts: PropTypes.bool,
};

export default NavInbox;
