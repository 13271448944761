// Automatically submit form on change of any value in the form.
function autoSubmitFormsInit() {
  const autoSubmitForms = document.querySelectorAll('.js-auto-submit-form');
  if (autoSubmitForms) {
    autoSubmitForms.forEach((form) => {
      const formInputs = form.querySelectorAll('input');
      const formSelects = form.querySelectorAll('select');

      formInputs.forEach((item) => {
        item.addEventListener('change', () => {
          form.submit();
        });
      });

      formSelects.forEach((item) => {
        const options = item.querySelectorAll('option');
        options.forEach((opt) => {
          opt.addEventListener('click', () => {
            form.submit();
          });
        });
      });
    });
  }
}

autoSubmitFormsInit();
