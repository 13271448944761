import DjangoModel from './DjangoModel';

// Globals
let selfPk = null;
const profileImages = {};

/**
 * Representation of a website User Account
 */
export default class User extends DjangoModel {
  constructor(userObject, identity = false) {
    super(userObject);
    if (identity) {
      selfPk = this.getPk();
    }
    this.isSelf = identity || selfPk === this.getPk();
    this.internalUserObject = userObject;
  }

  /**
   * Get the first name of a user
   * @returns string
   */
  getFirstName() {
    return this.internalUserObject.first_name;
  }

  /**
   * Get the last name of a user
   * @returns string
   */
  getLastName() {
    return this.internalUserObject.last_name;
  }

  /**
   * Get the full name of a user
   * @returns string
   */
  getFullName() {
    // Trim this because users with a missing name, primarily media users with display names, will have a space
    return `${this.internalUserObject.first_name} ${this.internalUserObject.last_name}`.trim();
  }

  /**
   * Get the url of a users profile image
   * @returns string
   */
  getProfileImage() {
    const storedProfileImage = profileImages[`${this.getPk()}`];

    if (storedProfileImage) {
      return storedProfileImage;
    }

    const image =
      this.internalUserObject.profile_image ||
      `${staticUrl}/images/svg/default_profile_thin.svg`;

    profileImages[`${this.getPk()}`] = image;

    return image;
  }

  /**
   * Return if the user object is the same as the user viewing the chat area
   * @returns bool
   */
  getIsSelf() {
    return this.isSelf;
  }

  /**
   * Get if the user is a staff member
   * @returns bool
   */
  getIfUserIsStaff() {
    return this.internalUserObject.is_staff;
  }

  /**
   * Get if the user is a media user
   * @returns bool
   */
  getIfUserIsMediaRep() {
    return (
      this.internalUserObject.groups.filter(
        (group) => group.name === 'Media Users'
      ).length > 0
    );
  }

  /**
   * Get if the user is a media user
   * @returns bool
   */
  getIfUserIsBrandRep() {
    return (
      this.internalUserObject.groups.filter(
        (group) => group.name === 'Brand Reps'
      ).length > 0
    );
  }

  /**
   * Get's a Brand Reps flavor text for Brand
   * to Brand contact requests
   * @returns string
   */
  getBrandRepFlavorText() {
    const brandsCount = this.internalUserObject.brands.length;
    const firstBrand = this.internalUserObject.brands[0];
    let flavorText = 'Brand Rep';

    if (firstBrand) {
      flavorText = `Brand Rep @ ${firstBrand.title}`;
      if (brandsCount > 1) {
        flavorText += ` + ${brandsCount - 1} More`;
      }
    }
    return flavorText;
  }

  /**
   * Get's a Brand Reps minimal brand list
   * for Brand to Brand contact request details
   * @returns list
   */
  getBrands() {
    return this.internalUserObject.brands;
  }

  /**
   * Get's a user profile url
   * used to link to media users' profile
   * @returns string
   */
  getProfileURL() {
    return this.internalUserObject.profile_url;
  }
}
