import React from 'react';

function TierQuantityField({
  minimumQuantity,
  quantity,
  setQuantity,
  setHasValidationError,
  quantityError,
  setQuantityError,
}) {
  return (
    <div className="registration-info-row registration-info-row--mt">
      <div className="registration-info-row__column">
        <label className="registration-info-input-element__title">
          Number of Brands
        </label>
        <input
          className="registration-info-input-element"
          type="text"
          placeholder="1"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
            if (e.target.value < minimumQuantity) {
              setQuantityError(
                'Quantity must be at least ' + minimumQuantity + '.'
              );
              setHasValidationError(true);
            } else {
              setQuantityError('');
              setHasValidationError(false);
            }
          }}
        />
        {quantityError && (
          <div className="registration-info-input-element__error-text">
            {quantityError}
          </div>
        )}
      </div>
    </div>
  );
}

export default TierQuantityField;
