import User from './User';

/**
 * Object representation of a Pitch
 */
export default class Pitch {
  constructor(pitchObject) {
    this.createdBy = new User(pitchObject.created_by);

    this.createdAt = pitchObject.created_at;

    this.pk = pitchObject.pk;
    this.url = pitchObject.url;
    this.title = pitchObject.title;
    this.pitchText = pitchObject.pitch_text;

    this.pitchedBrands = pitchObject.pitched_brands;
    this.pitchedProducts = pitchObject.pitched_products;
    this.pitchedExperts = pitchObject.pitched_experts;
  }

  /**
   * Get the pitch primary key
   * @returns integer
   */
  getPk() {
    return this.pk;
  }

  /**
   * Get the pitch title and if there's none, fallsback to an item title
   * @returns string
   */
  getTitle() {
    return this.title || this.getFirstItemTitle();
  }

  /**
   * Get the title for the first item it can find
   * @returns string
   */
  getFirstItemTitle() {
    if (this.pitchedBrands.length > 0) {
      return this.pitchedBrands[0].brand.title;
    }
    if (this.pitchedProducts.length > 0) {
      return this.pitchedProducts[0].product.title;
    }
    if (this.pitchedExperts.length > 0) {
      return this.pitchedExperts[0].expert.name;
    }
    return 'Unknown';
  }

  /**
   * Get the icon URL using the items, if there's none, it fallsback to the createdBy profile image
   * @returns string
   */
  getIconURL() {
    if (this.pitchedBrands.length > 0) {
      const logo = this.pitchedBrands[0].brand.logo;
      if (logo) return logo.rendition_image_url || logo.original_image_url;
    }
    if (this.pitchedProducts.length > 0) {
      const image = this.pitchedProducts[0].image;
      if (image) return image.rendition_image_url || image.original_image_url;
    }
    if (this.pitchedExperts.length > 0) {
      const headshots = this.pitchedExperts[0].expert.headshots;
      const headshot = headshots.length > 0 ? headshots[0].image : null;
      if (headshot)
        return headshot.rendition_image_url || headshot.original_image_url;
    }
    return this.createdBy.getProfileImage();
  }

  /**
   * Get the URL of a pitch
   * @returns string
   */
  getURL() {
    return this.url;
  }

  /**
   * Get your user identity
   * @returns User
   */
  getCreatedBy() {
    return this.createdBy;
  }

  /**
   * Get the date a pitch was createdq
   * @returns Date
   */
  getCreatedAt() {
    return new Date(this.createdAt);
  }
}
