import React from 'react';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function PromoCodeField({
  promoCode,
  setPromoCode,
  setCouponData,
  setHasValidationError,
  setCheckoutLoading,
}) {
  const [code, setCode] = React.useState(promoCode);
  const [promoCodeError, setPromoCodeError] = React.useState('');

  React.useEffect(() => {
    const abortController = new AbortController();
    validatePromoCode(abortController.signal);
    return () => {
      try {
        abortController.abort();
      } catch (e) {
        // ignore AbortError: signal is aborted without reason
        console.log(e);
      }
    };
  }, [code]);

  async function validatePromoCode(signal) {
    if (code && code.length > 0) {
      setCheckoutLoading(true);
      fetch(`/api/stripe/promo-codes/validate/`, {
        signal: signal,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({
          code: code,
        }),
      })
        // Return the response as JSON
        .then((res) => res.json())
        .then((data) => {
          if (!data.is_valid) {
            setPromoCodeError(
              'Promo code is not valid. Please enter another one or clear this field to continue.'
            );
            setCouponData({});
            setHasValidationError(true);
            setCheckoutLoading(false);
            return;
          } else if (
            data.restrictions?.minimum_amount !== null &&
            data.restrictions?.minimum_amount > rawPriceTotal
          ) {
            setPromoCodeError(
              'Your selected plan does not meet the required minimum amount for this promotion.'
            );
            setCouponData({});
            setHasValidationError(true);
            setCheckoutLoading(false);
            return;
          }
          setPromoCode(code);
          setPromoCodeError('');
          setCouponData(data.coupon_data);
          setHasValidationError(false);
          setCheckoutLoading(false);
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            setPromoCode('');
            setCouponData({});
            setPromoCodeError('');
            setHasValidationError(false);
          } else {
            setCheckoutLoading(false);
            setPromoCodeError(
              'An error occurred while validating the promo code. Please try again later.'
            );
            setCouponData({});
            setHasValidationError(true);
          }
        });
    } else {
      setPromoCode('');
      setCouponData({});
      setPromoCodeError('');
      setHasValidationError(false);
      setCheckoutLoading(false);
    }
  }

  return (
    <div className="registration-info-row registration-info-row--mt">
      <div className="registration-info-row__column">
        <label className="registration-info-input-element__title">
          Promo Code
        </label>
        <input
          className="registration-info-input-element"
          type="text"
          placeholder="Promo Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        {promoCodeError && (
          <div className="registration-info-input-element__error-text">
            {promoCodeError}
          </div>
        )}
      </div>
    </div>
  );
}

export default PromoCodeField;
