import PropTypes from 'prop-types';
import Conversation from '../PHChatApi/DataTypes/Conversation';
import ConversationMember from '../PHChatApi/DataTypes/ConversationMember';
import Message from '../PHChatApi/DataTypes/Message';
import Pitch from '../PHChatApi/DataTypes/Pitch';
import PitchSelectedMedia from '../PHChatApi/DataTypes/PitchSelectedMedia';

/**
 * This file contains the custom prop type definitions for Messaging.
 */

const ConversationType = PropTypes.instanceOf(Conversation);

const MessageType = PropTypes.instanceOf(Message);

const ConversationMemberType = PropTypes.instanceOf(ConversationMember);

const User = PropTypes.instanceOf(Object);

const SocketConnection = PropTypes.instanceOf(WebSocket);

const PitchType = PropTypes.instanceOf(Pitch);

const PitchSelectedMediaType = PropTypes.instanceOf(PitchSelectedMedia);

const ChatPropTypes = {
  Conversation: ConversationType,
  SocketConnection,
  User,
  Message: MessageType,
  ConversationMember: ConversationMemberType,
  Pitch: PitchType,
  PitchSelectedMedia: PitchSelectedMediaType,
};

export default ChatPropTypes;
