import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import pricingHandlers from '../../../../../../home/static/home/js/pricing';
import { getPricingPlanDetails } from '../../../../../../accounts/static/accounts/js/utils';

function PlanSelectionList({
  objectType,
  objectId,
  setSection,
  setSelectedPlan,
  setSelectedBillingPeriod,
  backButtonSection,
  isUpgrade,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [html, setHTML] = React.useState({ __html: '' });

  React.useEffect(() => {
    async function createMarkup() {
      let response;
      response = await fetch(
        `/pricing/cards?is_plan_selection_component=1&product_type=${objectType}&object_type=${objectType}&object_id=${objectId}${
          isUpgrade ? '&is_upgrade=1' : ''
        }`
      );
      const backendHtmlString = await response.text();
      return { __html: backendHtmlString };
    }
    createMarkup().then((result) => setHTML(result));
    setIsLoading(false);
  }, []);

  const externalCtaButtonClickHandler = (event) => {
    event.preventDefault();
    const { pricingPlanYearlyPriceId } = event.target.dataset;
    getPricingPlanDetails(pricingPlanYearlyPriceId, true).then((data) => {
      setSelectedPlan(data);
      setSection('Checkout');
    });
  };

  const externalBillingPeriodButtonClickHandler = (event) => {
    if (event.target.checked) {
      setSelectedBillingPeriod('yearly');
    } else {
      setSelectedBillingPeriod('monthly');
    }
  };

  React.useEffect(() => {
    pricingHandlers();
    const ctaBtns = document.querySelectorAll('.price-tab__card .cta-btn');
    for (let i = 0; i < ctaBtns.length; i += 1) {
      let btn = ctaBtns[i];
      btn.addEventListener('click', externalCtaButtonClickHandler);
    }

    const billingPeriodSlider = document.querySelector(
      '.price-billed-checkbox__input'
    );
    if (billingPeriodSlider) {
      if (billingPeriodSlider.checked) {
        setSelectedBillingPeriod('yearly');
      } else {
        setSelectedBillingPeriod('monthly');
      }
      billingPeriodSlider.addEventListener(
        'click',
        externalBillingPeriodButtonClickHandler
      );
    }
  }, [html]);

  return (
    <>
      {isLoading ? (
        <div className="modal-detail-plan">
          <LoadingRectangle height={145} />
        </div>
      ) : (
        <>
          <div
            className="price__external-list-container"
            dangerouslySetInnerHTML={html}
          />
          {backButtonSection === 'AddBrandForm' && (
            <div
              className="checkout__back-btn"
              onClick={() => setSection(backButtonSection)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <circle
                  cx="16"
                  cy="16"
                  r="15.5"
                  transform="rotate(-180 16 16)"
                  fill="white"
                  stroke="#262121"
                />
                <path
                  d="M18.3994 10.3984L13.0661 16.2651L18.3994 21.5984"
                  stroke="#262121"
                  strokeWidth="2"
                />
              </svg>
              back
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PlanSelectionList;
