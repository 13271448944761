import React from 'react';
import PropTypes from 'prop-types';
import ChatPropTypes from '../ChatPropTypes/ChatPropTypes';
import fromNow from '../../utils/fromNow';
import useLatestMessage from '../PHChatApi/ReactHooks/useLatestMessage';
import useConversationReadState from '../PHChatApi/ReactHooks/useConversationReadState';
import useConversationDetails from '../PHChatApi/ReactHooks/useConversationDetails';

/**
 * Displays overview details of a conversation, to be within a ConversationList
 */
function ConversationListItem({ conversation, selected, onClick, inbox }) {
  const [title, iconURL] = useConversationDetails(conversation);
  const latestMessage = useLatestMessage(conversation);
  const conversationRead = useConversationReadState(conversation);

  const clickHandler = React.useCallback(() => {
    // If we're in the inbox, act as a link
    if (inbox) {
      window.location.href = `/messaging/?conversation=${conversation.getUUID()}`;
    }
    onClick(conversation);
  }, [conversation]);

  return (
    <button
      type="button"
      className={`conversation-list__item ${
        selected ? 'conversation-list__item--selected' : ''
      } ${inbox && 'conversation-list__item--inbox'}`}
      onClick={clickHandler}
    >
      <div
        className={`chat-profile__icon ${
          inbox && 'chat-profile__icon--tiny chat-profile__icon--inbox'
        }`}
        style={{
          backgroundImage: `url(${iconURL})`,
        }}
      />
      <div className="conversation-list__text-column">
        <div className="conversation-list__title-row">
          <span
            className="conversation-list__title"
            data-testid="conversation-list-item-title"
          >
            {title}
            {!conversationRead && !selected && (
              <div className="conversation-list__unread-dot" />
            )}
          </span>
          <span className="conversation-list__time">
            {fromNow(conversation.getLastUpdatedDate(), true)}
          </span>
        </div>
        <span
          className="conversation-list__subtitle"
          data-testid="conversation-list-item-subtitle"
        >
          {conversation.getSubtitle()}
        </span>
        {(latestMessage || conversation.getIdentity().getIsInvited()) && (
          <span
            className="conversation-list__last-message"
            data-testid="conversation-list-item-recent-message"
          >
            {conversation.getIdentity().getIsInvited()
              ? 'New Conversation Invitation'
              : `${latestMessage
                  ?.getSender()
                  .getUser()
                  .getFullName()}: ${latestMessage?.getContent()}`}
          </span>
        )}
      </div>
    </button>
  );
}

ConversationListItem.defaultProps = {
  selected: false,
  inbox: false,
  onClick: () => {},
};

ConversationListItem.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  inbox: PropTypes.bool,
};

export default ConversationListItem;
