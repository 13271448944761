import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Source request details component
 */
function SourceRequestDetailsComponent({ conversation }) {
  const deadline = new Date(
    conversation.getContentObject().source_request.deadline
  );

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">
        Source Request:
      </h3>
      <span className="conversation-detail-view__details-text">
        {conversation.getContentObject().source_request.title}
      </span>
      <h3 className="conversation-detail-view__details-heading">Requesting:</h3>
      <span className="conversation-detail-view__details-text">
        {conversation.getContentObject().source_request.request_type_display}
      </span>
      <h3 className="conversation-detail-view__details-heading">Deadline:</h3>
      <span className="conversation-detail-view__details-text">
        {deadline.toLocaleDateString()}
      </span>
      <h3 className="conversation-detail-view__details-heading">
        Description:
      </h3>
      <span className="conversation-detail-view__details-text">
        {conversation.getContentObject().source_request.body}
      </span>
    </div>
  );
}

SourceRequestDetailsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SourceRequestDetailsComponent;
