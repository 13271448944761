import React from 'react';

function BaseToast({ content, title, headline }) {
  return (
    <div className="ph-toast__container" data-testid="phToast">
      {title && <h1 className="ph-toast__title">{title}</h1>}
      <div className="ph-toast__item">
        {headline && <p className="ph-toast__headline">{headline}</p>}
        <p
          className="ph-toast__content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
}

export default BaseToast;
