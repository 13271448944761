import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Single pitch detail component
 */
function PitchDetailsComponent({ conversation }) {
  const brandsTitles = conversation
    .getContentObject()
    .pitchedBrands.map((pitchedBrand) => pitchedBrand.brand.title);
  const productsTitles = conversation
    .getContentObject()
    .pitchedProducts.map((pitchedProduct) => pitchedProduct.product.title);
  const expertNames = conversation
    .getContentObject()
    .pitchedExperts.map((pitchedExpert) => pitchedExpert.expert.name);

  const pitchHasBrands = brandsTitles.length > 0;
  const pitchHasProducts = productsTitles.length > 0;
  const pitchHasExperts = expertNames.length > 0;

  return (
    <div>
      {pitchHasBrands && (
        <h3 className="conversation-detail-view__details-heading">Brands:</h3>
      )}
      {pitchHasBrands && (
        <span className="conversation-detail-view__details-text">
          {brandsTitles.join(', ')}
        </span>
      )}

      {pitchHasProducts && (
        <h3 className="conversation-detail-view__details-heading">Products:</h3>
      )}
      {pitchHasProducts && (
        <span className="conversation-detail-view__details-text">
          {productsTitles.join(', ')}
        </span>
      )}

      {pitchHasExperts && (
        <h3 className="conversation-detail-view__details-heading">Experts:</h3>
      )}
      {pitchHasExperts && (
        <span className="conversation-detail-view__details-text">
          {expertNames.join(', ')}
        </span>
      )}
    </div>
  );
}

PitchDetailsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default PitchDetailsComponent;
