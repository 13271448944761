import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../../../config/static/js/utils/logError';

function CheckoutDetails({
  objectType,
  objectId,
  priceId,
  quantity,
  trialLength,
  rawPriceTotal,
  rawTotalDue,
  rawTotalWithDiscount,
  couponData,
  annualSelected,
  isLoading,
  isNewSubscription,
  sixMonthCommitment = false,
  agreedToCommitment = true,
  setAgreedToCommitment = () => {},
}) {
  const [rawTotalDueScope, setRawTotalDueScope] = React.useState(rawTotalDue);
  const [rawTotalDueWithDiscountScope, setRawTotalDueWithDiscountScope] =
    React.useState(rawTotalWithDiscount);
  const [prorationDueToday, setProrationDueToday] = React.useState(null);
  const [prorationLoading, setProrationLoading] = React.useState(false);

  /**
   * Converts a number to a currency format
   * @param {integer} number
   * @param {boolean} isCents if not set, defaults to true
   * @returns
   */
  const currencyFormat = (number, isCents) => {
    if (!number) {
      return 'FREE';
    }

    const convertFromCents = isCents || isCents === undefined;
    const finalNumber = convertFromCents ? number / 100 : number;

    return (
      '$' +
      finalNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    if (!rawTotalDue && rawPriceTotal) {
      // If there is a coupon and no trial length, do some math
      if (couponData) {
        let rawDiscount = 0;
        if (couponData.amount_off) {
          rawDiscount += couponData.amount_off;
        }
        if (couponData.percent_off) {
          rawDiscount += rawPriceTotal * (couponData.percent_off / 100);
        }
        setRawTotalDueScope(rawPriceTotal - rawDiscount);
        setRawTotalDueWithDiscountScope(rawPriceTotal - rawDiscount);
      } else {
        setRawTotalDueScope(rawPriceTotal);
      }

      if (trialLength) {
        setRawTotalDueScope(0);
      }

      // Get proration due today
      if (!isNewSubscription) {
        const data = {
          object_type: objectType,
          object_id: objectId,
          price_id: priceId,
          quantity: quantity,
          coupon_id: couponData?.id,
        };
        setProrationLoading(true);
        fetch(`/api/stripe/subscriptions/update/proration/`, {
          signal: abortController.signal,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status === 'ok') {
              setProrationDueToday(response.due_today);
            } else {
              setProrationDueToday(null);
            }
            setProrationLoading(false);
          })
          .catch((error) => {
            logError(error);
            if (isMounted) {
              setProrationLoading(false); // Ensure loading state is reset
            }
          });
      }
    }
    return () => {
      try {
        isMounted = false;
        abortController.abort();
      } catch (e) {
        // ignore AbortError: signal is aborted without reason
        console.log(e);
      }
    };
  }, [rawTotalDue, rawPriceTotal, couponData]);

  /**
   * Determines if proration should be shown, based on the fact we calculate the proration for any upgrade
   * We can revamp this logic to only calculate proration if the user
   * is upgrading from a paid plan, but not from the free one.
   * Note: the rawTotalDueScope is in cents, so we need to divide by 100 to get the dollar amount
   */
  const showProration = prorationDueToday !== null && prorationDueToday !== rawTotalDueScope / 100;

  return isLoading ? (
    <LoadingRectangle height={125} />
  ) : (
    <div className="promo-code-details">
      <p className="promo-code-details__header">Payment details:</p>
      {trialLength > 0 && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">1st {trialLength} days</p>
          <p className="promo-code-details-text promo-code-details-text--light">
            FREE
          </p>
        </div>
      )}
      <div className="promo-code-details__row">
        <p className="promo-code-details-text">
          {showProration ? 'New total' : 'Due today'}:
        </p>
        <p className="promo-code-details-text promo-code-details-text--bold promo-code-details-text--red">
          {currencyFormat(rawTotalDueScope)}
        </p>
      </div>
      {showProration && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">Prorated amount due today:</p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {prorationLoading ? (
              <span className="loading-ellipsis"></span>
            ) : (
              currencyFormat(prorationDueToday, false)
            )}
          </p>
        </div>
      )}
      <div className="promo-code-details__row promo-code-details__row--border-bottom" />
      {couponData.duration_in_months && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            Next {couponData.duration_in_months} months:
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {currencyFormat(rawTotalDueWithDiscountScope)}/mo
          </p>
        </div>
      )}
      {couponData.duration === 'once' && !couponData.duration_in_months && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            First {couponData.duration_in_months}{' '}
            {annualSelected ? 'year' : 'month'}:
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {currencyFormat(rawTotalDueWithDiscountScope)}/
            {annualSelected ? 'yr' : 'mo'}
          </p>
        </div>
      )}
      {couponData.duration && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            {couponData.duration === 'forever'
              ? 'Discounted price:'
              : 'After promo:'}
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {couponData.duration === 'forever' && (
              <>
                {currencyFormat(rawTotalDueWithDiscountScope)}/
                {annualSelected ? 'yr' : 'mo'}
              </>
            )}

            {couponData.duration !== 'forever' && (
              <>
                {currencyFormat(rawPriceTotal)}/{annualSelected ? 'yr' : 'mo'}
              </>
            )}
          </p>
        </div>
      )}
      {sixMonthCommitment && (
        <div className="promo-code-details__row">
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToCommitment}
                onChange={(e) => setAgreedToCommitment(e.target.checked)}
                label=""
                inputProps={{ 'data-testid': 'sixMonthCommitmentCheckbox' }}
              />
            }
            label="I agree to Press Hook’s 6-month minimum term"
            classes={{ label: 'promo-code-details-commitment' }}
          />
        </div>
      )}
    </div>
  );
}

export default CheckoutDetails;
