import Conversation from '../DataTypes/Conversation';

/**
 * A conversation that has a content type and object
 */
export default class ContentTypeConversation extends Conversation {
  constructor(conversationObject) {
    super(conversationObject);
    this.content_object = conversationObject.content_object;
    this.model = conversationObject.content_type.model;
  }

  /**
   * Get the raw content object for a conversation
   * @returns content object
   */
  getContentObject() {
    return this.content_object;
  }

  /**
   * Get the raw model for a conversation's content type
   * @returns string
   */
  getModel() {
    return this.model;
  }
}
