import fetchData from '../../../../../utils/fetchData';
import logError from '../../../../../utils/logError';
import PHIdentityLayer from './PHIdentityLayer';
import createConversation from '../util/createConversation';
import SocketObjectCreationWrapper from '../Socketry/SocketObjectCreationWrapper';
import ErrorRouter from '../ErrorBanners/ErrorBanners';

import areWeTesting from '../../../../../utils/areWeTesting';

/**
 * Conversation operations for api
 * Part of the EventsClass > PHIdentityLayer > ConversationsLayer > PHChatApi flow
 * These files are broken up for clarity
 */
export default class ConversationsLayer extends PHIdentityLayer {
  constructor() {
    super();
    this.conversations = [];
    this.conversationsStatus = 0;
    this.setupConversationEventListeners();
    this.detailUrl = '/api/chat/conversations/';
    this.listUrl = '/api/chat/v2/conversations/';
  }

  /**
   * Handler for when identity is retrieved to start getting conversations.
   */
  getConversationsOnIdentityReady(state) {
    if (state === 1) {
      this.getConversationsFromApi();
    }
  }

  /**
   * Given an array of conversations based on recency.
   * Uses time of last message (if exists) and creation time of conversation.
   * @param {*} conversationsToSort array of conversations to sort
   * @returns sorted array
   */
  static sortConversations(conversationsToSort) {
    conversationsToSort.sort(
      (a, b) => b.getLastUpdatedDate() - a.getLastUpdatedDate()
    );
    return conversationsToSort;
  }

  /**
   * Method to trigger a refresh of conversation list order
   */
  updateConversationOrder() {
    this.conversations = ConversationsLayer.sortConversations(
      this.conversations
    );
    this.fireEvent('conversation_list_updated', this.conversations);
  }

  /**
   * Method for importing conversations from Api response
   * @param {*} rawConversations
   */
  importConversations(rawConversations) {
    const newConversations = rawConversations
      .filter(
        (conversation) =>
          conversation.members.length > 1 &&
          conversation.content_object !== null
      )
      .map((rawConversation) => createConversation(rawConversation, false));

    /*
      Right now these filters make sure that you can only see
      Conversations that you are actually a part of (for admins),
      and not ones that you've been removed from.

      In the future, we will want an admin view of sorts, so we'll have to come
      up with something for that as it arises.
    */
    this.conversations = ConversationsLayer.sortConversations(
      newConversations
    ).filter(
      (conversation) =>
        conversation.getIdentity() &&
        conversation.getIdentity().getActiveState() !== 'REM' &&
        conversation.getIdentity().getActiveState() !== 'DEC' &&
        conversation.getIdentity().getActiveState() !== 'PEN'
    );
    this.conversations.forEach((conversation) => {
      conversation.on(
        'remove_conversation',
        this.conversationRemoveHandler.bind(this)
      );
      conversation.on(
        'new_message_toast',
        this.newMessageToastHandler.bind(this)
      );
      conversation.on('new_message', this.updateConversationOrder.bind(this));
    });
    this.fireEvent('conversation_list_updated', this.conversations);
  }

  /**
   * Method to get conversations from API
   */
  async getConversationsFromApi() {
    this.fireEvent('conversations_ready', 0);
    this.conversationsStatus = 0;
    try {
      const abortController = new AbortController();

      // current_path is irrelevant in JS tests
      const res = await fetchData(
        areWeTesting()
          ? this.listUrl
          : `${this.listUrl}?current_path=${window.location.pathname}`,
        abortController.signal
      );
      this.importConversations(res);
      this.fireEvent('conversations_ready', 1);
      this.conversationsStatus = 1;
    } catch (e) {
      this.fireEvent('conversations_ready', 2);
      this.conversationsStatus = 2;
      ErrorRouter.fireError(
        'general',
        'Failed to retrieve conversations. Please try again.'
      );
      logError(e);
    }
  }

  async getConversationWithDetailsFromApi(pk) {
    try {
      const abortController = new AbortController();
      const res = await fetchData(
        `${this.detailUrl}${pk}/`,
        abortController.signal
      );
      return createConversation(res);
    } catch (e) {
      ErrorRouter.fireError(
        'general',
        'Failed to retrieve conversation details. Please try again.'
      );
      logError(e);
    }
    return {};
  }

  /**
   * Get the ready state of conversations
   * @returns integer, 0 = standby, 1 = ready, 2 = error
   */
  getConversationsStatus() {
    return this.conversationsStatus;
  }

  /**
   * Method to add a conversation to the list
   * @param {*} newConversation
   */
  conversationCreateHandler(newConversation) {
    if (
      !this.conversations.find(
        (conversation) => conversation.getUUID() === newConversation.getUUID()
      )
    ) {
      newConversation.on(
        'remove_conversation',
        this.conversationRemoveHandler.bind(this)
      );
      newConversation.on(
        'new_message_toast',
        this.newMessageToastHandler.bind(this)
      );
      newConversation.on(
        'new_message',
        this.updateConversationOrder.bind(this)
      );
      this.conversations = ConversationsLayer.sortConversations([
        ...this.conversations,
        newConversation,
      ]);
      this.fireEvent('conversation_list_updated', this.conversations);
    }
  }

  /**
   * Method to remove a conversation from the listing
   * @param {*} remConversation
   */
  conversationRemoveHandler(remConversation) {
    this.conversations = this.conversations.filter(
      (conversation) => conversation.getUUID() !== remConversation.getUUID()
    );
    this.fireEvent('conversation_list_updated', this.conversations);
  }

  /**
   * Method to handle invitation
   * @param {*} param0
   */
  conversationInviteHandler({ member, conversation }) {
    if (member.getUser().getPk() === this.getIdentity().getPk()) {
      this.conversationCreateHandler(conversation);
    }
  }

  /**
   * Method to remove a conversation based on member removal (self removed, etc)
   * @param {*} param0
   */
  memberRemovedHandler({ member, conversation }) {
    if (member.getUser().getPk() === this.getIdentity().getPk()) {
      this.conversationRemoveHandler(conversation);
    }
  }

  /**
   * Handler to relay message events out to be accessed for toasts.
   */
  newMessageToastHandler({ message, conversation }) {
    this.fireEvent('new_message_toast', { message, conversation });
  }

  /**
   * Sets up event listeners for conversation events.
   */
  setupConversationEventListeners() {
    this.on(
      'user_identity_ready',
      this.getConversationsOnIdentityReady.bind(this)
    );
    SocketObjectCreationWrapper.on(
      'conversation_created',
      this.conversationCreateHandler.bind(this)
    );
    SocketObjectCreationWrapper.on(
      'member_removed_from_conversation',
      this.memberRemovedHandler.bind(this)
    );
    SocketObjectCreationWrapper.on(
      'member_invited_to_conversation',
      this.conversationInviteHandler.bind(this)
    );
    SocketObjectCreationWrapper.on(
      'member_added_to_conversation',
      this.conversationInviteHandler.bind(this)
    );
  }

  /**
   * Get the list of conversations in its current state
   * @returns array of Conversation objects
   */
  getConversations() {
    return this.conversations;
  }
}
