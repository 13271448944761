import React from 'react';
import ReactDOM from 'react-dom/client';
import CollectionListPage from '../CollectionList/CollectionListPage';
import CollectionDetailPage from '../CollectionDetail/CollectionDetailPage';
import AddToCollectionButton from '../AddToCollection/AddToCollectionButton';
import getPageSetting from '../../../../../../../config/static/js/utils/getPageSetting';
import { LoadingStateProvider } from '../../../../../../core/static/core/js/utils/globalLoadingState';
import { ThemeProvider } from '@emotion/react';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';

/**
 * This file is for injecting the collections into the page.
 */
export default function injectCollectionsComponent() {
  const collectionListReactTarget = document.querySelector(
    'main.react-collection-list-view'
  );

  const collectionDetailReactTarget = document.querySelector(
    'main.react-collection-detail-view'
  );
  const collectionSlug = getPageSetting('collectionSlug', null);

  const addToCollectionButtonTarget = document.querySelectorAll(
    'div.react-add-to-collection-button'
  );

  if (collectionListReactTarget) {
    const root = ReactDOM.createRoot(collectionListReactTarget);
    root.render(<CollectionListPage />);
  }

  if (collectionDetailReactTarget) {
    const root = ReactDOM.createRoot(collectionDetailReactTarget);
    root.render(
      <LoadingStateProvider>
        <CollectionDetailPage collectionSlug={collectionSlug} />
      </LoadingStateProvider>
    );
  }

  addToCollectionButtonTarget.forEach((target) => {
    const { objectType, objectPk, isActive, hasText, color, inline } =
      target.dataset;
    const isActiveBool = isActive === 'True';
    const hasTextBool = hasText === 'True';
    const inlineBool = inline === 'True';

    const root = ReactDOM.createRoot(target);
    root.render(
      <ThemeProvider theme={PressHookTheme}>
        <AddToCollectionButton
          objectPk={objectPk}
          objectType={objectType}
          isActive={isActiveBool}
          hasText={hasTextBool}
          color={color}
          inline={inlineBool}
        />
      </ThemeProvider>
    );
  });
}

document.addEventListener('DOMContentLoaded', () => {
  injectCollectionsComponent();
});
