import React from 'react';
import ReactDOM from 'react-dom/client';
import UpgradeSubscriptionModal from './UpgradeSubscriptionModal';

let rootContainerForUpgradeModal = null;

/**
 * Injects the upgrade modal into its container
 *
 * object_type (str): brand or expert
 * object_id (int): brand id or expert id
 * initialPromoCode (str): optional promo code
 * selectedFeature (str): optional slug for an upgrade feature
 * onSuccess (func): optional function to run after the user successfully finishes
 *                   the flow and clicks on the "Return to browsing" button in the
 *                   success screen
 * isOpen (bool): optional boolean to control the modal visibility on injection
 */
export default function injectUpgradeModalComponent(
  objectType,
  objectId,
  initialPromoCode,
  selectedFeature,
  onSuccess,
  isOpen
) {
  const upgradeModalContainer = document.getElementById(
    'upgradeModalContainer'
  );

  if (upgradeModalContainer) {
    if (!rootContainerForUpgradeModal) {
      rootContainerForUpgradeModal = ReactDOM.createRoot(upgradeModalContainer);
      rootContainerForUpgradeModal.render(
        <UpgradeSubscriptionModal
          objectTypeExternal={objectType}
          objectIdExternal={objectId}
          initialPromoCodeExternal={initialPromoCode}
          selectedFeatureExternal={selectedFeature}
          onSuccess={onSuccess}
          isOpenExternal={isOpen}
        />
      );
    }
  }
}

document.addEventListener('DOMContentLoaded', injectUpgradeModalComponent());
