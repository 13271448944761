import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering brand contact request details for Requested by Brand
 */
function BrandContactRequestDetailsComponentRequestedByBrand({ conversation }) {
  // Strips the brand's website down to just the domain so we
  // can show it in a pleasing fashion
  let brand;
  if (conversation.getModel() == 'mediacontactrequest') {
    brand = conversation.getContentObject().requested_by_brand;
  } else {
    brand = conversation.getContentObject().requested_by_brand_object;
  }
  const url = brand.website;
  let domain = new URL(url);
  let prettyDomain = domain.hostname.replace('www.', '');

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">HQ:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-hq"
      >
        {brand.headquarters}
      </span>
      <h3 className="conversation-detail-view__details-heading">Website:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-website"
      >
        <a href={brand.website} target="_blank">
          {prettyDomain}
        </a>
      </span>
    </div>
  );
}

BrandContactRequestDetailsComponentRequestedByBrand.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default BrandContactRequestDetailsComponentRequestedByBrand;
