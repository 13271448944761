// eslint-disable-next-line max-len
import SourceRequestDetailsComponent from '../../ConversationDetailView/DetailsComponents/SourceRequestDetailsComponent';
// eslint-disable-next-line max-len
import SourceRequestPitchDetailsComponent from '../../ConversationDetailView/DetailsComponents/SourceRequestPitchDetailsComponent';

import BrandRelatedConversation from './BrandRelatedConversation';

/**
 * Class that implements custom header detail text and icon determination for Source Request conversations.
 */
export default class SourceRequestConversation extends BrandRelatedConversation {
  constructor(conversationObject) {
    super(conversationObject);

    const userIsRequester =
      this.getIdentity() &&
      this.getContentObject().created_by.pk ===
        this.getIdentity().getUser().getPk();

    this.typeText = 'Source Request';

    this.headerDetailText = this.getContentObject().source_request.title;

    this.icon = this.getOtherMembersExcludingStaff()[0]
      .getUser()
      .getProfileImage();

    this.relatedLink = userIsRequester
      ? {
          title: 'View Source Request',
          href: this.getContentObject().source_request.url,
        }
      : {
          title: 'View Pitch',
          href: this.getContentObject().url,
        };

    this.detailSections = {
      'Request Details': SourceRequestDetailsComponent,
      'Pitch Details': SourceRequestPitchDetailsComponent,
    };

    this.setupWelcomeMessageEventHandler();
    this.setCurrentSampleRequestWelcomeMessage();
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getActiveMembers() {
    return this.getMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getOtherActiveMembers() {
    return this.getOtherMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Setup the event listeners required to handle the dynamic welcome message
   */
  setupWelcomeMessageEventHandler() {
    this.on(
      'member_list_updated',
      this.setCurrentSampleRequestWelcomeMessage.bind(this)
    );
  }

  /**
   * Set the welcome message based on current user invite state.
   * @returns void
   */
  setCurrentSampleRequestWelcomeMessage() {
    const otherMember = this.getOtherMembersExcludingStaff()[0];
    if (otherMember.getActiveState() === 'PEN') {
      this.setWelcomeMessage(
        `This is the beginning of your conversation with ${otherMember
          .getUser()
          .getFullName()}`,
        'The other user will be invited after you send the first message'
      );
      return;
    }
    this.setWelcomeMessage(
      `This is the beginning of your conversation with ${otherMember
        .getUser()
        .getFullName()}`,
      "You'll be notified for every new message in this conversation."
    );
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns
   */
  getCustomFlavorTextForMember(member) {
    let brandUserSubtitle = '';
    if (this.getContentObject().pitched_brands.length === 1) {
      const { brand } = this.getContentObject().pitched_brands[0];
      brandUserSubtitle = `Brand Rep @ ${brand.title}`;
    }

    return this.getContentObject().created_by.pk === member.getUser().getPk()
      ? brandUserSubtitle
      : 'Media Member';
  }
}
