const newsFilterBtn = document.querySelector('.news-select');

if (newsFilterBtn) {
  newsFilterBtn.addEventListener('click', () => {
    newsFilterBtn.classList.toggle('open');
  });

  document.addEventListener('click', (event) => {
    if (!newsFilterBtn.contains(event.target)) {
      newsFilterBtn.classList.remove('open');
    }
  });
  
}
