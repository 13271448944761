import React from 'react';
import ApiConnection from '../PHChatApi';
import SocketConnection from '../Socketry/ChatSocketConnector';
import SocketObjectCreationWrapper from '../Socketry/SocketObjectCreationWrapper';

/**
 * Hook that returns true if the API is ready and usable.
 * @returns boolean
 */
function useApiReadyState() {
  const [apiReady, setApiReady] = React.useState(0);
  const [conversationsReady, setConversationsReady] = React.useState(0);
  const [socketReady, setSocketReady] = React.useState(0);
  const [identityReady, setIdentityReady] = React.useState(0);

  /**
   * Every time a ready state changes, determine new summary
   */
  React.useEffect(() => {
    const readySummary = [conversationsReady, socketReady, identityReady];
    if (readySummary.includes(2)) {
      setApiReady(2);
    } else if (readySummary.includes(0)) {
      setApiReady(0);
    } else {
      setApiReady(1);
    }
  }, [conversationsReady, socketReady, identityReady]);

  /**
   * Register event listeners that impact ready state
   */
  React.useEffect(() => {
    function handleConversationStateChange(value) {
      setConversationsReady(value);
    }
    function handleSocketStateChange(value) {
      setSocketReady(value);
    }
    function handleIdentityStateChange(value) {
      setIdentityReady(value);
    }

    ApiConnection.on('conversations_ready', handleConversationStateChange);
    SocketObjectCreationWrapper.on('socket_ready', handleSocketStateChange);
    ApiConnection.on('user_identity_ready', handleIdentityStateChange);

    setIdentityReady(ApiConnection.getIdentityStatus());
    setConversationsReady(ApiConnection.getConversationsStatus());
    setSocketReady(SocketConnection.getSocketReadyState());

    return () => {
      ApiConnection.off('conversations_ready', handleConversationStateChange);
      ApiConnection.off('socket_ready', handleSocketStateChange);
      ApiConnection.off('user_identity_ready', handleIdentityStateChange);
    };
  }, []);

  return apiReady;
}

export default useApiReadyState;
