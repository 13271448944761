// handles edit page's mobile menu position on scroll
if (window.location.href.indexOf('edit') > -1) {
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    const stickyNav = document.getElementById('stickyNavMobile');
    if (stickyNav) {
      if (prevScrollpos > currentScrollPos) {
        stickyNav.style.top = '64px';
      } else {
        stickyNav.style.top = '-110px';
      }
      prevScrollpos = currentScrollPos;
    }
  };
}
