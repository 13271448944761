import React from 'react';
import PlanSelectionDetail from '../planSelection/PlanSelectionDetail';
import PlanSelectionList from '../planSelection/PlanSelectionList';
import UpgradeModalPremiumGradiant from './UpgradeModalPremiumGradiant';
import Checkout from '../checkout/Checkout';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import SlotSelectionForm from '../planSelection/SlotSelectionForm';
import ObjectSelectionForm from './ObjectSelectionForm';

function UpgradeSubscriptionModal({
  objectTypeExternal,
  objectIdExternal,
  initialPromoCodeExternal,
  selectedFeatureExternal,
  onSuccess,
  isOpenExternal,
  setIsOpenExternal,
  planSelectionSection,
  showPremiumGradiant,
  isUpgrade,
}) {
  const [objectId, setObjectId] = React.useState(objectIdExternal);
  const [objectType, setObjectType] = React.useState(objectTypeExternal);
  const [initialPromoCode, setInitialPromoCode] = React.useState(
    initialPromoCodeExternal
  );
  const [selectedFeature, setSelectedFeature] = React.useState(
    selectedFeatureExternal
  );
  const [redirectUrl, setRedirectUrl] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(
    isOpenExternal !== undefined ? isOpenExternal : false
  );
  const [section, setSection] = React.useState(
    planSelectionSection ? planSelectionSection : 'PlanSelectionDetail'
  );
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [selectedBillingPeriod, setSelectedBillingPeriod] =
    React.useState('yearly');
  const [subscriptionStatusCheck, setSubscriptionStatusCheck] =
    React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [freeSlots, setFreeSlots] = React.useState([]);
  const outsideModalRef = React.useRef(null);

  /**
   * Clicking on an element with .js-upgrade-modal-open will trigger the modal
   */
  const triggerButtonClickHandler = (event) => {
    const target = event.target.closest('.js-upgrade-modal-open');
    if (target) {
      if (target.dataset.objectType) setObjectType(target.dataset.objectType);
      if (target.dataset.objectId) setObjectId(target.dataset.objectId);
      if (target.dataset.promoCode)
        setInitialPromoCode(target.dataset.promoCode);
      if (target.dataset.feature) setSelectedFeature(target.dataset.feature);
      if (target.dataset.next) setRedirectUrl(target.dataset.next);
      setIsOpen(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', triggerButtonClickHandler);
    return () => window.removeEventListener('click', triggerButtonClickHandler);
  });

  /**
   * Sync the external isOpen prop with the internal one
   */
  React.useEffect(() => {
    if (
      setIsOpenExternal &&
      isOpen !== undefined &&
      isOpen !== isOpenExternal
    ) {
      setIsOpenExternal(isOpen);
    }
  }, [isOpen]);

  /**
   * Handles closing the modal when clicking outside of it
   */
  const handleClick = (event) => {
    if (outsideModalRef.current && outsideModalRef.current == event.target) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  });

  /**
   * Checks the current subscription status for that specific object
   */
  React.useEffect(() => {
    if (!subscriptionStatusCheck && isOpen) {
      if (objectType && objectId) {
        fetch(`/api/stripe/subscriptions/${objectType}/${objectId}/status/`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status == 'active' || data.status == 'trialing') {
              setIsLoading(false);
              setSection(
                planSelectionSection
                  ? planSelectionSection
                  : 'PlanSelectionDetail'
              );
            }
            setSubscriptionStatusCheck(data.status);
          });
      } else {
        setSection('ObjectSelectionForm');
        setIsLoading(false);
      }
    }
  }, [objectType, objectId, subscriptionStatusCheck, isOpen]);

  /**
   * Retrieves slots for the current organization
   */
  React.useEffect(() => {
    if (
      subscriptionStatusCheck &&
      subscriptionStatusCheck !== 'active' &&
      subscriptionStatusCheck !== 'trialing'
    ) {
      fetch(`/api/subscription-slots/?free=${objectType}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => {
          if (!res.ok) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .then((data) => {
          if (data.length > 0) {
            setFreeSlots(data);
            setSection('SlotSelectionForm');
          } else {
            setFreeSlots([]);
          }
        })
        .catch((error) => {
          logError(error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [subscriptionStatusCheck]);

  /**
   * Opens the Add Brand Modal for when there are no brands nor experts
   */
  React.useEffect(() => {
    if (section === 'AddBrandModal') {
      setIsOpen(false);
      const target = document.getElementsByClassName(
        'js-add-brand-modal-open'
      )[0];
      if (target) {
        target.click();
      } else {
        // create the element and click it
        React.createElement(
          'a',
          { href: '#', className: 'js-add-brand-modal-open' },
          ''
        );
        const madeUpTarget = document.getElementsByClassName(
          'js-add-brand-modal-open'
        )[0];
        if (madeUpTarget) {
          madeUpTarget.click();
        }
      }
    }
  }, [section]);

  return (
    isOpen && (
      <div className="modal modal--active" ref={outsideModalRef}>
        <div
          className={
            section == 'SlotSelectionForm' || section == 'ObjectSelectionForm'
              ? 'modal__box add-brand-modal'
              : 'modal__box'
          }
        >
          <button className="modal__close-btn">
            <img
              className="modal__close-btn-img"
              onClick={() => setIsOpen(false)}
              src={`${staticUrl}/images/svg/modal-close-black.svg`}
              alt="close"
            />
          </button>

          {isLoading ? (
            <LoadingRectangle height={300} />
          ) : (
            <>
              {section === 'ObjectSelectionForm' && (
                <ObjectSelectionForm
                  selectedFeature={selectedFeature}
                  setObjectType={setObjectType}
                  setObjectId={setObjectId}
                  setSection={setSection}
                />
              )}
              {section === 'SlotSelectionForm' && (
                <SlotSelectionForm
                  objectType={objectType}
                  objectId={objectId}
                  freeSlots={freeSlots}
                  setSection={setSection}
                />
              )}
              {section === 'PlanSelectionDetail' && (
                <>
                  <PlanSelectionDetail
                    objectType={objectType}
                    objectId={objectId}
                    setSection={setSection}
                    setSelectedPlan={setSelectedPlan}
                    selectedBillingPeriod={selectedBillingPeriod}
                    setSelectedBillingPeriod={setSelectedBillingPeriod}
                    isUpgrade={isUpgrade === undefined ? true : isUpgrade}
                  />
                  {showPremiumGradiant && (
                    <UpgradeModalPremiumGradiant
                      selectedFeature={selectedFeature}
                      selectedPlan={selectedPlan}
                    />
                  )}
                </>
              )}
              {section === 'PlanSelectionList' && (
                <>
                  {showPremiumGradiant && (
                    <UpgradeModalPremiumGradiant
                      selectedFeature={selectedFeature}
                      selectedPlan={selectedPlan}
                    />
                  )}
                  <PlanSelectionList
                    objectType={objectType}
                    objectId={objectId}
                    setSection={setSection}
                    setSelectedPlan={setSelectedPlan}
                    setSelectedBillingPeriod={setSelectedBillingPeriod}
                    isUpgrade={isUpgrade === undefined ? true : isUpgrade}
                  />
                </>
              )}
              {section === 'Checkout' && (
                <Checkout
                  objectType={objectType}
                  objectId={objectId}
                  selectedPlan={selectedPlan}
                  selectedBillingPeriod={selectedBillingPeriod}
                  setSection={setSection}
                  initialPromoCode={initialPromoCode}
                  isNewSubscription={
                    subscriptionStatusCheck === 'not_found' ||
                    subscriptionStatusCheck === 'canceled'
                  }
                  backButtonSection={
                    planSelectionSection
                      ? planSelectionSection
                      : 'PlanSelectionDetail'
                  }
                />
              )}
              {section === 'CheckoutSuccess' && (
                <div className="price__top-content">
                  <h2 className="price__title modal-detail-plan__price-title">
                    Operation succeeded!
                  </h2>

                  <p className="subhead price__desc modal-detail-plan__price-desc">
                    Thanks for being part of the Press Hook!
                  </p>

                  <a
                    className="cta-btn cta-btn--wider price-tab__card-btn price__cta-btn"
                    onClick={() => {
                      setIsOpen(false);
                      if (onSuccess) {
                        onSuccess();
                      } else if (redirectUrl) {
                        window.location.href = redirectUrl;
                      }
                    }}
                  >
                    Return to browsing
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  );
}

export default UpgradeSubscriptionModal;
