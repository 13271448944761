import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering brand to media request reason
 */
function MediaContactReuqestSubject({ conversation }) {
  return (
    <div>
      <span
        className="conversation-detail-view__details-text"
        data-testid="media-request-request-reason"
      >
        {conversation.getContentObject().subject}
      </span>
    </div>
  );
}

MediaContactReuqestSubject.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default MediaContactReuqestSubject;
