import EmblaCarousel from 'embla-carousel';

const initSliders = (context = document.body) => {
  // sliders on home page and 404 page, from NodeList to array so we can add items later
  const sliders = [...context.querySelectorAll('.slider')];

  // Check if the context has the .slider class itself
  // This is useful for HTMX content that may have the .slider class
  // in top-level element.
  if (context.classList.contains('slider')) {
    sliders.push(context);
  }

  sliders.forEach((slider) => {
    // Check if the slider is already initialized or marked to be ignored
    // This prevents re-initialization of the slider
    // and allows for ignoring specific sliders
    // by adding the 'slider--ignore' class
    if (
      slider.classList.contains('slider--initialized') ||
      slider.classList.contains('slider--ignore')
    )
      return;

    const prevBtnNewProducts = slider.querySelector('.slider__previous');
    const nextBtnNewProducts = slider.querySelector('.slider__next');
    const emblaWrapNewProducts = slider.querySelector('.slider__content');
    const viewPortNewProducts =
      emblaWrapNewProducts.querySelector('.slider__viewport');
    const emblaNewProducts = EmblaCarousel(viewPortNewProducts, {
      loop: true,
      align: 'start',
      skipSnaps: false,
    });

    // Safely add event listeners if buttons are present
    if (prevBtnNewProducts) {
      prevBtnNewProducts.addEventListener(
        'click',
        emblaNewProducts.scrollPrev,
        false
      );
    }

    if (nextBtnNewProducts) {
      nextBtnNewProducts.addEventListener(
        'click',
        emblaNewProducts.scrollNext,
        false
      );
    }

    slider.classList.add('slider--initialized');
  });
};

// Initialize sliders on page load
document.addEventListener('DOMContentLoaded', () => {
  initSliders();
});

// Re-initialize sliders after HTMX content load
document.addEventListener('htmx:load', (event) => {
  initSliders(event.target);
});
