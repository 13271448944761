import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Box,
  Typography,
  ThemeProvider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';
import PitchAIForm from './PitchAIForm';
import updateData from '../../../../../../../config/static/js/utils/updateData';
import logError from '../../../../../../../config/static/js/utils/logError';
import updatePitch from '../../utils/updatePitch';

/**
 * React component for the modal with the form to add a pitch using AI.
 * @param {Object} props - The props of the component.
 * @param {boolean} props.isOpen - Whether the modal is open.
 * @param {function} props.setIsOpen - Function to set the modal open state.
 * @param {number} props.preSelectedMediaPk - The preslected media pk to pitch (optional).
 */
export default function PitchAIModal({
  isOpen,
  onClose = () => {},
  preSelectedMediaPk = undefined,
}) {
  const [sendingData, setSendingData] = React.useState(false);
  const [pitchAiData, setPitchAiData] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [formData, setFormData] = React.useState({});

  const abortController = new AbortController();

  /**
   * Closes the modal and abort any ongoing request.
   */
  const handleClose = () => {
    abortController.abort();
    onClose();
  };

  /**
   * Submit the data to the API
   */
  React.useEffect(() => {
    const sendData = async (data) => {
      setSendingData(true);
      try {
        const res = await updateData(
          '/api/pitches/ai/',
          { json: data },
          'POST',
          abortController.signal,
          true
        );
        setPitchAiData(res);
      } catch (error) {
        logError(error);
        setErrorMessage(
          error.cause || 'An error occurred while generating the pitch.'
        );
      }
    };

    if (formData && Object.keys(formData).length > 0) {
      sendData(formData);
    }
  }, [formData]);

  /**
   * Convert the data to the pitch api format, having the pitch text and the pitched items.
   */
  const convertToPitchData = (data) => ({
    title: data.pitch_title,
    pitch_text: data.pitch_body,
    pitched_brands: formData.pitchingOptions
      .filter((item) => item.type === 'brand')
      .map((item) => item.pk),
    pitched_products: formData.pitchingOptions
      .filter((item) => item.type === 'product')
      .map((item) => item.pk),
    pitched_experts: formData.pitchingOptions
      .filter((item) => item.type === 'expert')
      .map((item) => item.pk),
    selected_media: preSelectedMediaPk ? [preSelectedMediaPk] : [],
  });

  /**
   * Effect to create the pitch after the AI data is received
   */
  React.useEffect(() => {
    if (pitchAiData && pitchAiData.generated_pitch) {
      const pitchObject = convertToPitchData(pitchAiData);

      updatePitch(pitchObject, undefined, abortController).then((res) => {
        if (res?.code && res.code !== 201) {
          if (res.body?.non_field_errors) {
            setErrorMessage(res.body.non_field_errors);
          } else {
            setErrorMessage('An error occurred while creating your pitch');
          }
        } else if (res.pk) {
          // success redirects to the pitch page or source request page
          // This must be updated to redirect to the correct page by DEV-2344
          // Be sure to take preSelectedMediaPk into account
          window.location.href = `/pitches/${res.pk}/edit/`;
        }
      });
    }
  }, [pitchAiData]);

  const renderMainComponent = React.useCallback(() => {
    if (sendingData) {
      return (
        <center>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={192}
            m={3}
          >
            <img
              width="68px"
              height="68px"
              src={`${staticUrl}/images/gif/brand-editor-loader.gif`}
              alt="Loading"
            />
            <Typography variant="subtitle3">
              {errorMessage ||
                'Please wait. We are creating a Pitch for you...'}
            </Typography>
          </Box>
        </center>
      );
    }
    return (
      <PitchAIForm
        saveMethod={setFormData}
        preSelectedMediaPk={preSelectedMediaPk}
      />
    );
  }, [errorMessage, sendingData]);

  return (
    <ThemeProvider theme={PressHookTheme}>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle variant="modal">New Pitch</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          variant="modal-close"
          size="large"
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Divider variant="dashed" />
        {renderMainComponent()}
      </Dialog>
    </ThemeProvider>
  );
}

PitchAIModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  preSelectedMediaPk: PropTypes.number,
};
