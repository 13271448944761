// BRAND ACCOUNT JS
const brandGeneralInformation = document.querySelector(
  '#brandGeneralInformation > .title'
);
const brandProducts = document.querySelector('#brandProducts > .title');
const brandPressReleases = document.querySelector(
  '#brandPressReleases > .title'
);

if (window.location.href.indexOf('information') > -1) {
  if (brandGeneralInformation) {
    brandGeneralInformation.classList.add('selected');
    brandGeneralInformation.nextElementSibling.classList.remove('hidden');
  }
} else if (window.location.href.indexOf('products') > -1) {
  if (brandProducts) {
    brandProducts.classList.add('selected');
    brandProducts.nextElementSibling.classList.remove('hidden');
  }
} else if (window.location.href.indexOf('press-releases') > -1) {
  if (brandPressReleases) {
    brandPressReleases.classList.add('selected');
    brandPressReleases.nextElementSibling.classList.remove('hidden');
  }
}

// mobile brand menu titles
// find page title and use that for the mobile menu text
// Note: This script used not only by brand app, but also
// by sample-request and accounts, to set the title of the menu
const targetTitle = document.getElementById('currentPageTitle');
const currentPageTitle = document.querySelector(
  '.inner > .title-container > h1'
);

if (currentPageTitle && targetTitle) {
  targetTitle.textContent = currentPageTitle.textContent;
}
