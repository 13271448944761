import React from 'react';

/**
 * Custom hook to get the latest message in a conversation
 * @param {*} conversation conversation to watch
 * @returns conversation object
 */
function useLatestMessage(conversation) {
  const [latestMessage, setLatestMessage] = React.useState(
    conversation.getLastMessage()
  );

  React.useEffect(() => {
    setLatestMessage(conversation.getLastMessage());

    conversation.on('new_message', setLatestMessage);

    return () => {
      conversation.off('new_message', setLatestMessage);
    };
  }, [conversation]);

  return latestMessage;
}

export default useLatestMessage;
