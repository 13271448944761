import React from 'react';

const CollectionContext = React.createContext();

export const useCollectionContext = () => React.useContext(CollectionContext);

export const CollectionProvider = ({ children, refreshCollectionData }) => {
  return (
    <CollectionContext.Provider value={refreshCollectionData}>
      {children}
    </CollectionContext.Provider>
  );
};
