import React from 'react';
import PropTypes from 'prop-types';

function CollectionForm({
  collection,
  createHandler,
  editHandler,
  extraCssClasses,
  dismissFormHandler,
}) {
  const [title, setTitle] = React.useState(
    collection && collection.title ? collection.title : ''
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (collection) {
      editHandler(event);
    } else {
      createHandler(event);
    }
  };

  return (
    <form
      className={`collections-modal__form ${extraCssClasses}`}
      onSubmit={handleSubmit}
    >
      <input
        className="collections-modal__input"
        data-testid="CollectionTitleInput"
        type="text"
        value={title || ''}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={collection ? '' : 'New collection title'}
        autoFocus
        required
      />
      <button
        className={
          dismissFormHandler
            ? 'collections-modal__button collections-modal__button--multiple'
            : 'collections-modal__button'
        }
        type="submit"
      >
        {collection ? 'Save Changes' : 'Create Collection'}
      </button>
      {dismissFormHandler && (
        <button
          className="collections-modal__button collections-modal__button--secondary collections-modal__button--multiple"
          onClick={dismissFormHandler}
        >
          Cancel
        </button>
      )}
    </form>
  );
}

CollectionForm.propTypes = {
  collection: PropTypes.object,
  createHandler: PropTypes.func,
  editHandler: PropTypes.func,
  extraCssClasses: PropTypes.string,
  dismissFormHandler: PropTypes.func,
};

export default CollectionForm;
