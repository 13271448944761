import { Globals } from '@react-spring/web';

/*
    Toggles @react-spring/web animations globally based on system reduce motion setting.
*/

const ANIMATE = true; // if set to false, all animations will be disabled.

/**
 * Disables or enables @react-spring/web animations globally.
 * @param {*} shouldAnimate boolean indicating whether or not animations should run.
 */
function setAnimation(shouldAnimate) {
  Globals.assign({
    skipAnimation: ANIMATE ? !shouldAnimate : false,
  });
}

// Get mediaquery form browser.
const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

// If mediaquery exists, perform our logic.
if (mediaQuery) {
  // Set initial state
  if (mediaQuery.matches) {
    setAnimation(false);
  } else {
    setAnimation(true);
  }

  // Detect changes
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', () => {
      if (mediaQuery.matches) {
        setAnimation(false);
      } else {
        setAnimation(true);
      }
    });
  }
}
