import React, { useState, useEffect } from 'react';
import MediaProfileCard from '../../../../../../../media_profiles/static/media_profiles/js/components/MediaProfileList/MediaProfileCard';
import getUserFeatureAccess from '../../../../../../../core/static/core/js/utils/userFeatureAccess';
import checkWaffleFlag from '../../../../../../../../config/static/js/utils/checkWaffleFlag';

/**
 * This component is used to render the MediaProfileCard component in the collection detail page.
 * It should use the collectionItem object to get any data needed to render the MediaProfileCard.
 * @param {object} collectionItem - The collection item object.
 * @returns
 */
function MediaProfileCardBuilder({ collectionItem }) {
  const [hasMediaDatabase, setHasMediaDatabase] = useState(false);
  const [pitchFormReactFlag, setPitchFormReactFlag] = useState(false);

  useEffect(() => {
    const fetchFeatureAccess = async () => {
      try {
        const access = await getUserFeatureAccess('pitchable-media-database');
        setHasMediaDatabase(access);
      } catch (error) {
        // Nothing to do here, error is already logged in getUserFeatureAccess
      }
    };

    const fetchFeatureFlag = async () => {
      try {
        const flag = await checkWaffleFlag('pitch-form-react');
        setPitchFormReactFlag(flag);
      } catch (error) {
        // Nothing to do here, error is already logged in checkWaffleFlag
      }
    };

    fetchFeatureAccess();
    fetchFeatureFlag();
  }, []);

  return (
    <div className="collection-item-card__container">
      <div className="js-content">
        <MediaProfileCard
          mediaProfilePk={collectionItem.getRelatedObjectPk()}
          hasMediaDatabase={hasMediaDatabase}
          pitchFormReactFlag={pitchFormReactFlag}
          loadingNamespace="collections"
          cardStyle={{ width: '100%', height: 'auto' }}
        />
      </div>
    </div>
  );
}

export default MediaProfileCardBuilder;
