import React from 'react';

function BilledPeriodSwitch({
  billingPeriod,
  setBillingPeriod,
  annualDiscountText,
}) {
  function toggleBillingPeriod() {
    if (billingPeriod === 'yearly') {
      setBillingPeriod('monthly');
    } else {
      setBillingPeriod('yearly');
    }
  }

  return (
    <div className="price-tab__billed modal-detail-plan__price-tab-billed">
      <p
        className={
          billingPeriod === 'monthly'
            ? 'price-tab__billed-text price-tab__billed-text--active'
            : 'price-tab__billed-text'
        }
      >
        Billed Monthly
      </p>

      {/*<!-- Billed Checkbox -->*/}
      <label className="price-billed-checkbox">
        <input
          id="modalPlanDetailsCheckInput"
          className="price-billed-checkbox__input"
          type="checkbox"
          onChange={() => {
            toggleBillingPeriod();
          }}
          checked={billingPeriod === 'yearly'}
        />

        <span className="price-billed-checkbox__slide"></span>
      </label>
      {/*<!-- Billed Checkbox -->*/}

      <div className="price-tab__billed-container">
        <p
          className={
            billingPeriod === 'yearly'
              ? 'price-tab__billed-text price-tab__billed-text--active'
              : 'price-tab__billed-text'
          }
        >
          Billed Annually
        </p>

        {annualDiscountText && (
          <div className="price-tab__billed-tags">
            <p className="price-tab__billed-tags-text">{annualDiscountText}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BilledPeriodSwitch;
