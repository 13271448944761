import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Sample request products component
 */
function SampleRequestProductsComponent({ conversation, setTitleDisplay }) {
  React.useEffect(() => {
    setTitleDisplay(
      `Products (${conversation.getContentObject().products.length})`
    );
  }, [conversation]);
  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="sample-request-products"
    >
      <div className="tags tags--card tags--wrap">
        {conversation.getContentObject().products.map((product) => {
          return (
            <a href={product.url} className="tags__tag" key={product.pk}>
              {product.title}
            </a>
          );
        })}
      </div>
    </span>
  );
}

SampleRequestProductsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SampleRequestProductsComponent;
