function setCookie(cookieName, cookieValue, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${cookieName}=${encodeURIComponent(
    cookieValue
  )}${expires}; path=/`;
}

export default setCookie;
