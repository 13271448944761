import React, { useImperativeHandle, forwardRef } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Button, Box, Typography } from '@mui/material';
import logError from '../../../../../../../config/static/js/utils/logError';

const PaymentMethodForm = forwardRef(
  (
    {
      setSelectedPaymentMethodId = () => {},
      showPaymentSelector = () => {},
      isThereExistingPaymentMethods = false,
    },
    ref
  ) => {
    const stripe = useStripe();
    const elements = useElements();

    const [nextAction, setNextAction] = React.useState(null);

    const submitPaymentElement = () => {
      // Submit the PaymentElement for validation only
      elements
        .submit()
        .then((_result) => {
          // The PaymentElement is valid
          // We don't need to do anything here, but we can set the selected payment method to null just in case
          setSelectedPaymentMethodId(null);
        })
        .catch((_error) => {
          // Stripe handles errors for each input field in the PaymentElement
          // We don't need to show them, leaving this here for future reference
          // setError(_error.message);
        });
    };

    /**
     * Sets the payment method id to null when the component mounts
     */
    React.useEffect(() => {
      setSelectedPaymentMethodId(null);
    }, []);

    useImperativeHandle(ref, () => ({
      async confirmSetup() {
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });

        // Handle setup errors
        if (
          error?.type === 'card_error' ||
          error?.type === 'validation_error'
        ) {
          // Error handling follows stripe's recommendations
          // https://docs.stripe.com/js/payment_intents/confirm_payment
          logError(error.message);
          throw error;
        }

        // If setupIntent returns with next_action, we need to handle it
        if (setupIntent.next_action) {
          if (setupIntent.next_action.type === 'verify_with_microdeposits') {
            // Redirect to the hosted_verification_url
            const url =
              setupIntent.next_action.verify_with_microdeposits
                .hosted_verification_url;
            setNextAction(url);

            // Return the next action to the parent component
            return { nextAction: url };
          } else {
            // Type should only be 'verify_with_microdeposits'
            // If it's not, we throw an error
            throw new Error(
              'Unexpected next_action type, please contact support.'
            );
          }
        }

        return { paymentMethod: setupIntent.payment_method };
      },
    }));

    // Style for the next action box component as a modal
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 660,
      bgcolor: 'background.paper',
      p: 10,
      borderRadius: '4px',
      border: '1px solid #262121',
    };

    return (
      <>
        {isThereExistingPaymentMethods && (
          <h3 className="checkout__subtitle">Add a new payment method</h3>
        )}
        <div className="registration-info-row registration-info-row">
          <div className="registration-info-row__column">
            <PaymentElement onBlur={submitPaymentElement} />
          </div>
        </div>
        {isThereExistingPaymentMethods && (
          <div className="checkout__add-new">
            or{' '}
            <span
              className="checkout__add-new--link"
              onClick={() => showPaymentSelector()}
            >
              use existing ones
            </span>
          </div>
        )}
        {nextAction && (
          <Box sx={style}>
            <Typography variant="h4" gutterBottom>
              Please verify your bank account
            </Typography>
            <Typography gutterBottom>
              To finalize your setup, we need to verify your bank account.
            </Typography>
            <Typography gutterBottom>
              Please check your bank account for microdeposits, which may take
              up to three days to appear.{' '}
            </Typography>
            <Typography gutterBottom>
              If you have received the microdeposits, click the button below to
              verify your bank account. If you haven't received them yet, please
              follow the instructions in your email later.
            </Typography>
            <Typography gutterBottom>
              Once your account is verified, you can try again and select it
              instead of filling out the form again.
            </Typography>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => window.open(nextAction, '_blank')}
            >
              Verify my bank account
            </Button>
          </Box>
        )}
      </>
    );
  }
);

export default PaymentMethodForm;
