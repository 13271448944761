// handles "Details" collapsible section on top product details
const detailsTab = document.querySelectorAll('.details-container');

if (detailsTab) {
  detailsTab.forEach((item) => {
    const details = item.querySelector('.sub-filter');
    item.addEventListener('click', (event) => {
      if (details.classList.contains('dnone') && item.firstChild) {
        details.classList.remove('dnone');
        if (!item.classList.contains('opened')) {
          item.classList.add('opened');
        }
      } else if (!details.classList.contains('dnone')) {
        details.classList.add('dnone');
        if (item.classList.contains('opened')) {
          item.classList.remove('opened');
        }
      }
    });
  });
}
