import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import PriceTab from '../planSelection/PriceTab';
import {
  createSubscriptionWithPaymentMethod,
  getTotalUnitAmountFromQuantity,
  getUserAuthStatus,
} from '../../../../../../accounts/static/accounts/js/utils';
import PromoCodeField from './PromoCodeField';
import CheckoutDetails from './CheckoutDetails';
import TierQuantityField from './TierQuantityField';
import StripeElementsContainer from './StripeElementsContainer';
import logError from '../../../../../../../config/static/js/utils/logError';

function Checkout({
  objectType,
  objectId,
  setObjectId,
  selectedPlan,
  selectedBillingPeriod,
  setSection,
  initialPromoCode,
  isNewSubscription,
  backButtonSection,
  newObjectData,
}) {
  const stripeDataset = document.querySelector('[data-stripe-api-key]').dataset;
  const sixMonthCommitmentFlag =
    stripeDataset.sixMonthCommitmentFlag === 'true';

  const [promoCode, setPromoCode] = React.useState(initialPromoCode);
  const [couponData, setCouponData] = React.useState({});
  const [rawPriceTotal, setRawPriceTotal] = React.useState(0);
  const [checkoutLoading, setCheckoutLoading] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState('');
  const [subscriptionId, setSubscriptionId] = React.useState(null);
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [quantityError, setQuantityError] = React.useState(false);
  const [trialLength, setTrialLength] = React.useState(0);
  const [userIsExistingCustomer, setUserIsExistingCustomer] =
    React.useState(false);
  const [agreedToCommitment, setAgreedToCommitment] = React.useState(
    !sixMonthCommitmentFlag
  );
  const [sixMonthCommitment, setSixMonthCommitment] = React.useState(false);
  const [metadata, setMetadata] = React.useState({});
  const isStatusChecking = React.useRef(false);

  // Stripe Elements states
  const paymentFormRef = React.useRef(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    React.useState(null);

  /**
   * Calculates if the six month commitment checkbox must be displayed
   * Note that we only calculate this if the flag is set to true
   */
  if (sixMonthCommitmentFlag) {
    React.useEffect(() => {
      const planMetadata =
        selectedBillingPeriod === 'yearly'
          ? selectedPlan.yearly_price.metadata
          : selectedPlan.monthly_price.metadata;
      const displaySixMonthCommitment = planMetadata['six_month_commitment'];
      setSixMonthCommitment(displaySixMonthCommitment);
      // If we don't display the commitment, we set the agreedToCommitment to true
      setAgreedToCommitment(!displaySixMonthCommitment);
    }, [selectedPlan, selectedBillingPeriod]);
  }

  /**
   * Sets the metadata for the subscription
   */
  if (sixMonthCommitmentFlag) {
    React.useEffect(() => {
      setMetadata({
        six_month_commitment: sixMonthCommitment && agreedToCommitment,
      });
    }, [sixMonthCommitment, agreedToCommitment]);
  }

  /**
   * First thing on rendering is to get the payment methods
   * and to set the base quantity as the plan minimun quantity
   */
  React.useEffect(() => {
    getUserAuthStatus().then((data) => {
      if (data.status === true) {
        setUserIsExistingCustomer(data.is_existing_customer);
      }
    });

    setQuantity(selectedPlan.minimum_quantity);
  }, []);

  /**
   * Handles default promos and trial lengths
   */
  React.useEffect(() => {
    if (selectedPlan) {
      const url_based_promos = selectedPlan.promos.filter((promo) => {
        if (
          promo.url_path_requirement &&
          window.location.pathname.includes(promo.url_path_requirement) &&
          promo.subscription_term ===
            (selectedBillingPeriod === 'yearly' ? 'annual' : 'monthly')
        ) {
          return promo;
        }
      });

      let promo = null;

      if (url_based_promos.length > 0) {
        promo = url_based_promos[0];
      } else {
        const default_promos = selectedPlan.promos.filter((promo) => {
          if (
            promo.is_default &&
            promo.subscription_term ===
              (selectedBillingPeriod === 'yearly' ? 'annual' : 'monthly')
          ) {
            return promo;
          }
        });
        if (default_promos.length > 0) {
          promo = default_promos[0];
        }
      }
      if (promo && !initialPromoCode && !userIsExistingCustomer) {
        if (promo.trial_days && (trialLength <= 0 || !trialLength)) {
          setTrialLength(promo.trial_days);
        }
        if (promo.promo_code && !promoCode) {
          setPromoCode(promo.promo_code);
        }
      }

      if (
        selectedPlan.is_tiered &&
        selectedPlan.minimum_quantity &&
        quantity < selectedPlan.minimum_quantity
      ) {
        setQuantity(selectedPlan.minimum_quantity);
      }
    }
  }, [selectedPlan]);

  /**
   * Everytime quantity changes we need
   * to recalculate the raw price
   */
  React.useEffect(() => {
    setRawPriceTotal(
      getTotalUnitAmountFromQuantity(
        selectedPlan,
        quantity,
        selectedBillingPeriod === 'yearly'
      )
    );
  }, [quantity]);

  /**
   * When we already have a new subscriptionId,
   * Checks for the sub status during an time interval, if
   * it's ACTIVE redirects to the success message section
   */
  React.useEffect(() => {
    const intervalHandler = () => {
      if (isStatusChecking.current === false) return;
      fetch(
        `/api/stripe/subscriptions/${subscriptionId}/status/?object_type=${objectType}&object_id=${objectId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'active') {
            isStatusChecking.current = false;
            setSection('CheckoutSuccess');
          }
        });
    };
    if (subscriptionId && objectId) setInterval(intervalHandler, 5000);
  }, [subscriptionId, objectId]);

  /**
   * Executes the checkout and upgrades the subscription
   */
  const handleCheckout = async () => {
    setCheckoutLoading(true);

    let paymentMethod = null;

    if (selectedPaymentMethodId === null) {
      try {
        if (paymentFormRef.current) {
          const res = await paymentFormRef.current.confirmSetup();

          if (res.paymentMethod) {
            setSelectedPaymentMethodId(res.paymentMethod);
            paymentMethod = res.paymentMethod;
          }
          if (res.nextAction) {
            // Do nothing, StripeElementsContainer will handle the frontend, and submit is now blocked due to checkoutLoading == true.
            return;
          }
        }
      } catch (error) {
        setCheckoutError(error.message);
        setCheckoutLoading(false);
        return;
      }
    } else {
      paymentMethod = selectedPaymentMethodId;
    }

    // Upgrading using an existing payment method
    const price =
      selectedBillingPeriod === 'yearly'
        ? selectedPlan.yearly_price
        : selectedPlan.monthly_price;

    if (isNewSubscription === true) {
      createSubscriptionWithPaymentMethod(
        price.id,
        trialLength,
        quantity,
        paymentMethod,
        null,
        null,
        null,
        couponData ? couponData.id : null,
        objectId,
        objectType,
        newObjectData,
        metadata
      ).then((data) => {
        // If the createSubscriptionWithPaymentMethod function caught the error the data.error is set
        if (data.error !== undefined) {
          setCheckoutLoading(false);
          setCheckoutError(data.error);
        } else {
          // on success, the loading state is handled by the status checking function
          setCheckoutError('');
          if (setObjectId) {
            setObjectId(data.metadata[`${objectType}_pk`]);
          }
          setSubscriptionId(data.id);
          isStatusChecking.current = true;
        }
      });
    } else {
      const data = {
        object_id: objectId,
        object_type: objectType,
        quantity,
        coupon_id: couponData ? couponData.id : null,
        payment_method_id: paymentMethod,
        price_id:
          selectedBillingPeriod === 'yearly'
            ? selectedPlan.yearly_price.id
            : selectedPlan.monthly_price.id,
        metadata,
      };

      fetch(`/api/stripe/subscriptions/`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => {
          if (res.ok !== true) {
            return Promise.reject(res);
          }
          return res.json();
        })
        .then((data) => {
          if (data.status === 'ok') {
            setCheckoutError('');
            setSubscriptionId(data.subscription_id);
            isStatusChecking.current = true;
          } else {
            setCheckoutError(
              'There was an error trying to execute this operation. Please try again or contact support.'
            );
            if (data.field === 'quantity') {
              setQuantityError(data.error_message);
            }
          }
        })
        .catch(async (data) => {
          logError(data);
          const errorData = await data.json();
          if (errorData.error_message) {
            setCheckoutError(errorData.error_message);
          } else {
            setCheckoutError(
              'There was an error trying to execute this operation. Please try again or contact support.'
            );
          }
        })
        .finally(() => {
          setCheckoutLoading(false);
        });
    }
  };

  return (
    <div className="checkout">
      <h2 className="checkout__title">Add your billing information</h2>
      {checkoutError && (
        <div className="checkout__error-message">{checkoutError}</div>
      )}
      <div className="checkout__body">
        <div className="checkout__plan-container">
          <PriceTab plan={selectedPlan} billingPeriod={selectedBillingPeriod} />
        </div>
        <div className="checkout__payment-method">
          {!rawPriceTotal ? (
            ''
          ) : (
            <div className="checkout__block">
              <StripeElementsContainer
                ref={paymentFormRef}
                selectedPaymentMethodId={selectedPaymentMethodId}
                setSelectedPaymentMethodId={setSelectedPaymentMethodId}
              />
            </div>
          )}
          <div className="checkout__block">
            <h3 className="checkout__subtitle">Finish your checkout</h3>
            {selectedPlan.is_tiered && (
              <TierQuantityField
                minimumQuantity={selectedPlan.minimum_quantity}
                quantity={quantity}
                setQuantity={setQuantity}
                setHasValidationError={setHasValidationError}
                quantityError={quantityError}
                setQuantityError={setQuantityError}
              />
            )}
            <PromoCodeField
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              setCouponData={setCouponData}
              setHasValidationError={setHasValidationError}
              setCheckoutLoading={setCheckoutLoading}
            />
            <CheckoutDetails
              objectType={objectType}
              objectId={objectId}
              priceId={
                selectedBillingPeriod === 'yearly'
                  ? selectedPlan.yearly_price.id
                  : selectedPlan.monthly_price.id
              }
              quantity={quantity}
              rawPriceTotal={rawPriceTotal}
              couponData={couponData}
              annualSelected={selectedBillingPeriod === 'yearly' ? true : false}
              isNewSubscription={isNewSubscription}
              sixMonthCommitment={sixMonthCommitment}
              agreedToCommitment={agreedToCommitment}
              setAgreedToCommitment={setAgreedToCommitment}
            />
          </div>
        </div>
      </div>
      <div className="checkout__footer">
        <div
          className="checkout__back-btn"
          onClick={() => setSection(backButtonSection)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle
              cx="16"
              cy="16"
              r="15.5"
              transform="rotate(-180 16 16)"
              fill="white"
              stroke="#262121"
            />
            <path
              d="M18.3994 10.3984L13.0661 16.2651L18.3994 21.5984"
              stroke="#262121"
              strokeWidth="2"
            />
          </svg>
          back
        </div>
        <button
          className="checkout__submit-btn"
          onClick={handleCheckout}
          disabled={
            checkoutLoading ||
            hasValidationError ||
            !agreedToCommitment ||
            isStatusChecking.current
          }
        >
          {checkoutLoading || isStatusChecking.current
            ? 'Loading...'
            : agreedToCommitment
            ? 'Finish Payment'
            : 'Please agree to the commitment'}
        </button>
      </div>
    </div>
  );
}

export default Checkout;
