import updateData from "../../../../../../config/static/js/utils/updateData";

/**
 * Create or updates a pitch with the given content
 * @param {Object} pitchObject - The content of the pitch.
 * @param {string} pitchObject.title - The title of the pitch.
 * @param {string} pitchObject.pitch_text - The text of the pitch.
 * @param {string} [pitchObject.source_request] - The slug of the source request.
 * @param {number[]} pitchObject.pitched_brands - A list of the selected brands pks.
 * @param {number[]} pitchObject.pitched_products - A list of the selected products pks.
 * @param {number[]} pitchObject.pitched_experts - A list of the selected experts pks.
 * @param {number} [pitchPk] - The pk of the pitch to update (optional).
 * @param {AbortController} [abortController] - The abort controller to cancel the request (optional).
 */
const updatePitch = (pitchObject, pitchPk, abortController) => {
  const url = pitchPk ? `/api/pitches/${pitchPk}/` : '/api/pitches/';
  const method = pitchPk ? 'PUT' : 'POST';

  return updateData(
    url,
    { json: pitchObject },
    method,
    abortController?.signal,
    true
  );
}

export default updatePitch;