import React from 'react';
import PropTypes from 'prop-types';
import Collection from '../../DataModels/Collection';
import CollectionCardCoverImages from './CollectionCardCoverImages';

/**
 * @param {{ collection: Collection }} prop
 * @returns {React.Component}
 */
function CollectionCard({ collection }) {
  const cardRef = React.useRef(null);

  const extraClassModifier = () => {
    switch (collection.getCoverImages().length) {
      case 2:
        return '--two';
      case 3:
        return '--three';
      default:
        return '';
    }
  };

  // Handling click
  const handleClick = (event) => {
    if (cardRef.current && cardRef.current.contains(event.target)) {
      // go to detail view
      window.location.href = `/collections/${cardRef.current.dataset.slug}`;
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div
      className="collection-card__container"
      ref={cardRef}
      data-slug={collection.getSlug()}
    >
      <div className="collection-card__images">
        {collection.getCoverImages().length > 0 ? (
          collection
            .getCoverImages()
            .map((image, key) => (
              <CollectionCardCoverImages
                key={key}
                image={image}
                extraClassModifier={extraClassModifier()}
              />
            ))
        ) : (
          <div className="collection-card__image-container collection-card__image-container--none"></div>
        )}
      </div>
      <div className="collection-card__title">{collection.getTitle()}</div>
      <div className="collection-card__subtitle">
        {collection.getItemCount()}
      </div>
    </div>
  );
}

CollectionCard.propTypes = {
  collection: PropTypes.instanceOf(Collection).isRequired,
};

export default CollectionCard;
