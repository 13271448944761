import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  FormHelperText,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Options Selector
 * -----------------
 *
 * React UI element component for a checkbox with multiple inputs based on TagSelector.
 * The main difference is that this component receives a list of options instead of
 * calling an API to get them.
 *
 * @component
 *
 * @typedef {Object} Option - An option to be displayed in the selector.
 * @property {string} img - The image URL for the option.
 * @property {string} label - The label for the option.
 * @property {string} value - The value for the option.
 *
 * @param {Object} props - The props of the component.
 * @param {Option[]} props.options - The options to be displayed in the selector.
 * @param {Function} props.onChange - The callback function to be called when the selected options change.
 * @param {boolean} [props.loading=false] - Indicates whether the options are currently being loaded.
 * @param {string} [props.loadingText='Loading options...'] - The text to be displayed while loading the options.
 * @param {boolean} [props.disabled=false] - Indicates whether the selector is disabled.
 * @param {string} [props.helperText=undefined] - The helper text to be displayed below the selector.
 *
 * @returns {JSX.Element} The rendered OptionsSelector component.
 */
function OptionsSelector({
  options,
  onChange,
  loading = false,
  loadingText = 'Loading options...',
  disabled = false,
  helperText = undefined,
}) {
  const [sortedOptions, setSortedOptions] = React.useState(options);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  /**
   * Sorts options by its label
   */
  const sortOptions = (a, b) => {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  };

  /**
   * Update sorted options when options change
   * This is necessary because the options are sorted by label
   * and the parent component may change the options
   */
  React.useEffect(() => {
    // Removing selected options from the list if they are no longer in the options
    setSelectedOptions((selection) => {
      const newSelection = selection.filter((selected) =>
        options.find((opt) => opt.value === selected)
      );
      if (newSelection.length !== selection.length) {
        return newSelection;
      }
      return selection;
    });

    // Sorting options by label only if they are different
    const sorted = [...options].sort(sortOptions);
    setSortedOptions((prevSortedOptions) => {
      if (JSON.stringify(prevSortedOptions) !== JSON.stringify(sorted)) {
        return sorted;
      }
      return prevSortedOptions;
    });
  }, [options]);

  /**
   * Update parent component with selected options
   */
  React.useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  /**
   * Handles deletions of the option by clicking in the chip
   */
  const handleDelete = (option) => {
    const newSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption !== option
    );
    setSelectedOptions(newSelectedOptions);
  };

  const loadingComponent = () => <MenuItem disabled>{loadingText}</MenuItem>;
  const optionsComponents = React.useCallback(
    () =>
      sortedOptions.map((option) => (
        <MenuItem
          key={option.label}
          value={option.value}
          data-testid="optionsSelector-option"
        >
          {option.label}
        </MenuItem>
      )),
    [sortedOptions]
  );

  const getOptionsOrLoadingText = React.useCallback(() => {
    if (loading) return loadingComponent();
    return optionsComponents();
  }, [sortedOptions, loading]);

  return (
    <FormControl fullWidth>
      <Select
        multiple
        value={selectedOptions}
        input={<OutlinedInput />}
        disabled={disabled}
        onChange={(event) => setSelectedOptions(event.target.value)}
        data-testid="optionsSelector"
        inputProps={{
          'data-testid': 'optionsSelector-input',
          onClick: console.log,
        }}
        renderValue={(selection) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            {selection.map((selected) => {
              // selected is just the value of the option, while selectedObj is the whole option object
              const selectedObj = sortedOptions.find(
                (option) => option.value === selected
              );
              return (
                <Chip
                  variant="outlined"
                  key={selected}
                  label={selectedObj.label}
                  onDelete={() => handleDelete(selected)}
                  onMouseDown={(event) => {
                    event.stopPropagation(); // Prevents propagation to the Select component
                  }}
                  deleteIcon={<CloseIcon />}
                  avatar={
                    selectedObj.img ? <Avatar src={selectedObj.img} /> : null
                  }
                  data-testid="optionsSelector-selectedOption"
                />
              );
            })}
          </Box>
        )}
      >
        {getOptionsOrLoadingText()}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

OptionsSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      selected: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

export default OptionsSelector;
