// hides success message/banner after 5 seconds
const successBanner = document.querySelector('li.success');
// eslint-disable-next-line no-unused-vars
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

if (successBanner) {
  successBanner.classList.add('start-fade');
  setTimeout(() => {
    if (!successBanner.classList.contains('end-fade')) {
      successBanner.classList.add('end-fade');
    }
  }, 5000);
}
