// Get all bios
const bios = document.querySelectorAll('.expert-card__biography');

/**
 * Get text content from html, in this case the richtext from the server.
 * This is ONLY safe because we're running it on HTML sent down from the server.
 * @param {*} input rich text html string
 * @returns text content
 */
function extractContentFromRichText(input) {
  const span = document.createElement('span');
  span.innerHTML = input;
  return span.textContent || span.innerText;
}

/**
 * Set line clamp depending on how many tags there are.
 */
bios.forEach((bio) => {
  // eslint-disable-next-line no-param-reassign
  bio.textContent = extractContentFromRichText(bio.textContent);

  const container = bio.parentElement;
  const tags = container.querySelector('.expert-card__tag-section');

  if (tags.getBoundingClientRect().height < 40) {
    // eslint-disable-next-line no-param-reassign
    bio.style.webkitLineClamp = 4;
  }
});
