import ContentTypeConversation from './ContentTypeConversation';

/**
 * A conversation that an admin can intervene.
 * Adjusts behavior of title, etc, to exclude the admins
 */
export default class AdminIntervenableConversation extends ContentTypeConversation {
  /**
   * Override title to exclude admins from the title listing
   * @returns string
   */
  getTitle() {
    return this.getOtherMembersExcludingStaff()
      .map((member) => member.getUser().getFullName())
      .join(', ');
  }

  /**
   * Get an array of members excluding any staff
   * @returns array of ConversationMembers
   */
  getMembersExcludingStaff() {
    return this.getMembers().filter((member) => {
      if (
        member.getUser().get_pk === this.getContentObject()?.requested_by ||
        member.getUser().get_pk === this.getContentObject()?.requested_by?.pk // in case value is expanded
      ) {
        return true;
      }

      return !member.getUser().getIfUserIsStaff();
    });
  }

  /**
   * Get an array of members excluding staff and self
   * If the array is empty, we fall back on getting all other members.
   * @returns array of ConversationMembers
   */
  getOtherMembersExcludingStaff() {
    const otherMembersExcludingStaff = this.getMembersExcludingStaff().filter(
      (member) => !member.getUser().getIsSelf()
    );

    return otherMembersExcludingStaff.length > 0
      ? otherMembersExcludingStaff
      : this.getOtherMembers();
  }

  /**
   * Get the text to be displayed in the header of the conversation
   * Has a special condition to still include them if they are the requester for the content object
   * Only if one exists, if there is no requested_by field, it will just be ignored
   * @returns string
   */
  getHeaderDetailText() {
    // Return this no matter what if beyond 2 users to prevent erratic behavior.
    if (this.getMembersExcludingStaff().length > 2) {
      return 'Group Conversation';
    }

    return this.headerDetailText;
  }

  /**
   * Get the icon URL of a conversation
   * @returns string
   */
  getIcon() {
    return this.getOtherMembersExcludingStaff().length === 1
      ? this.icon || this.getOtherMembers()[0].getUser().getProfileImage()
      : `${staticUrl}/images/svg/group_icon_large.svg`;
  }
}
