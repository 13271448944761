import fetchData from '../../../../../../config/static/js/utils/fetchData';
import logError from '../../../../../../config/static/js/utils/logError';

// This will store all requested features promises.
const userFeatureAccess = {};

/**
 * Fetches the user access for the given feature.
 * It will save any return value to avoid multiple requests.
 * @param {string} featureName
 * @return {Promise<boolean>}
 */
const getUserFeatureAccess = async (featureName) => {
  // Check if the feature was requested and stored in userFeatureAccess
  if (!userFeatureAccess[featureName]) {
    // Store the promise in userFeatureAccess
    userFeatureAccess[featureName] = fetchData(
      `/api/users/current-user/feature-access/?feature=${featureName}`
    )
      .then((response) => response.has_access)
      .catch((error) => {
        logError(`Error fetching feature access for ${featureName}: ${error}`);
        throw error;
      });
  }

  return userFeatureAccess[featureName];
};

export default getUserFeatureAccess;
