import React from 'react';
import ReactDOM from 'react-dom/client';
import NavInbox from './jsx/chat/NavInbox/NavInbox';

/**
 * This file is for injecting react components into the DOM
 */
document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname.startsWith('/messaging')) {
    return;
  }

  const chatTarget = document.querySelector('#inboxDesktopContainer');
  if (chatTarget) {
    const root = ReactDOM.createRoot(chatTarget);
    root.render(<NavInbox showToasts targetElement={chatTarget} />);
  }

  const chatMobileTarget = document.querySelector('#inboxMobileContainer');
  if (chatMobileTarget) {
    const root = ReactDOM.createRoot(chatMobileTarget);
    root.render(<NavInbox targetElement={chatMobileTarget} />);
  }
});
