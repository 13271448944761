import React from 'react';
import ApiConnection from '../PHChatApi';

/**
 * React hook for subscribing to user identity.
 */
function useUserIdentity() {
  const [userIdentity, setUserIdentity] = React.useState(
    ApiConnection.getIdentity()
  );

  React.useEffect(() => {
    function handleUserIdentityReady() {
      setUserIdentity(ApiConnection.getIdentity());
    }

    setUserIdentity(ApiConnection.getIdentity());

    ApiConnection.on('user_identity_ready', handleUserIdentityReady);

    return () => {
      ApiConnection.off('user_identity_ready', handleUserIdentityReady);
    };
  }, []);

  return userIdentity;
}

export default useUserIdentity;
