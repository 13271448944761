import areWeTesting from './areWeTesting';

/**
 * Log an error to our Error Tracking tool (if found)
 * @param {*} error error to log
 */
export default function logError(error) {
  // Send to console if staging or localhost
  if (
    (window.location.host.indexOf('localhost') > -1 ||
      window.location.host.indexOf('staging') > -1) &&
    !areWeTesting()
  ) {
    console.error(error);
  }
  if (window.Sentry) {
    window.Sentry.captureException(error);
  }
}
