/**
 * Track share events with segment.
 * Destination is only detailed on desktop. The WebShare API doesn't return any destination
 * Destination for mobile shares will simply be mobile
 */
function trackShareEvent(
  destination,
  title,
  url,
  trackObjectPk,
  trackObjectType
) {
  let finalDestination = destination;

  if (destination.startsWith('https://www.twitter.com'))
    finalDestination = 'twitter';
  else if (destination.startsWith('https://www.facebook.com'))
    finalDestination = 'facebook';
  else if (destination.startsWith('https://www.linkedin.com'))
    finalDestination = 'linkedin';
  else if (destination.startsWith('mailto:')) finalDestination = 'email';
  else if (destination.startsWith('clipboard')) finalDestination = 'clipboard';

  const trackData = {
    destination: finalDestination,
    title,
    url,
    id: trackObjectPk,
    type: trackObjectType,
  };

  if (window.analytics) {
    window.analytics.track(`${trackObjectType} Shared`, trackData);
  }
}

/* flag for share dialog toggling */
let showShareDialog = false;

/**
 *  Toggles the share dialog and dimmed background on/off.
 */
function toggleShareDialog() {
  const shareDialogElements = document.getElementsByClassName('share-dialog');
  for (let i = 0; i < shareDialogElements.length; i += 1) {
    if (showShareDialog) {
      shareDialogElements[i].classList.remove('show-dialog');
      shareDialogElements[i].classList.add('hide-dialog');
    } else {
      shareDialogElements[i].classList.remove('hidden');
      shareDialogElements[i].classList.remove('hide-dialog');
      shareDialogElements[i].classList.add('show-dialog');
    }
  }

  if (showShareDialog) {
    document.body.classList.remove('share-dialog-open');
  } else {
    document.body.classList.add('share-dialog-open');
  }

  showShareDialog = !showShareDialog;
}

const toggleShareDialogElement = document.getElementById('toggle-share-dialog');
if (toggleShareDialogElement) {
  toggleShareDialogElement.addEventListener('click', () => {
    toggleShareDialog();
  });
}

/**
 * Ran when share button pressed.
 * Launches system share dialog (if available)
 * Else, Opens our fallback share modal.
 */
const shareButtonElements = document.getElementsByClassName(
  'js-share-button-trigger'
);
for (let i = 0; i < shareButtonElements.length; i += 1) {
  const button = shareButtonElements[i];
  button.addEventListener('click', () => {
    const { title, url, text, trackObjectPk, trackObjectType } = button.dataset;
    if (navigator.share) {
      navigator
        .share({
          title,
          url,
          text,
        })
        .then(() => {
          trackShareEvent('mobile', title, url, trackObjectPk, trackObjectType);
        });
    } else {
      toggleShareDialog();
    }
  });
}

/**
 * Opens specified link in new small window, if link is mailto:, changes window location.
 */
const openLinkElements = document.getElementsByClassName(
  'share-button-open-link'
);
if (openLinkElements) {
  for (let i = 0; i < openLinkElements.length; i += 1) {
    const openLinkElement = openLinkElements[i];
    openLinkElement.addEventListener('click', () => {
      const { link, title, url, trackObjectPk, trackObjectType } =
        openLinkElement.dataset;
      trackShareEvent(link, title, url, trackObjectPk, trackObjectType);

      if (link.startsWith('mailto:')) {
        window.location.href = link;
        return;
      }
      window.open(
        link,
        `Share ${title}`,
        'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
      );
    });
  }
}

/**
 * Copies specified text to clipboard and updates button text to show confirmation.
 */
const copyToClipboardElements = document.getElementsByClassName(
  'share-button-copy-to-clipboard'
);
if (copyToClipboardElements) {
  for (let i = 0; i < copyToClipboardElements.length; i += 1) {
    const copyToClipboardElement = copyToClipboardElements[i];
    copyToClipboardElement.addEventListener('click', () => {
      const { title, url, text, trackObjectPk, trackObjectType } =
        copyToClipboardElement.dataset;
      navigator.clipboard.writeText(text).then(() => {
        trackShareEvent(
          'clipboard',
          title,
          url,
          trackObjectPk,
          trackObjectType
        );
        const copyURLMessage = document.getElementById('copyUrlMessage');
        copyURLMessage.innerHTML = 'Copied!';
        setTimeout(() => {
          copyURLMessage.innerHTML = 'Copy URL';
        }, 5000);
      });
    });
  }
}
