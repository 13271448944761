import React from 'react';
import ApiConnection from '../PHChatApi';

/**
 * Custom hook to get the conversation list as it updates.
 * @returns list of conversation objects
 */
function useConversationList() {
  const [conversations, setConversations] = React.useState(
    ApiConnection.getConversations()
  );

  /**
   * Register event listeners that impact conversation list
   */
  React.useEffect(() => {
    function conversationUpdateHandler(newConversations) {
      setConversations([...newConversations]);
    }

    ApiConnection.on('conversation_list_updated', conversationUpdateHandler);

    return () => {
      ApiConnection.off('conversation_list_updated', conversationUpdateHandler);
    };
  }, []);

  return conversations;
}

export default useConversationList;
