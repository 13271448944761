// handles filter/search for list view

function closeAllSelect(currentSelector) {
  /* a function that will close all select boxes in the document,
  except the current select box: */

  const allDropdownItems = document.querySelectorAll('.select-items');

  allDropdownItems.forEach((dropdown) => {
    const selectorContainer = dropdown.parentElement;

    if (!selectorContainer.contains(currentSelector)) {
      dropdown.classList.add('select-hide');

      const chevronUp = selectorContainer.querySelector('.chevron_up');
      const chevronDown = selectorContainer.querySelector('.chevron_down');
      if (chevronUp) chevronUp.classList.add('d-none');
      if (chevronDown) chevronDown.classList.remove('d-none');
    }
  });
}

// looks for any elements with the class "custom-select":
const customSelectContainers = document.getElementsByClassName('custom-select');
if (customSelectContainers) {
  for (let i = 0; i < customSelectContainers.length; i += 1) {
    const selElmnt =
      customSelectContainers[i].getElementsByTagName('select')[0];
    if (!selElmnt) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const selectElementCount = selElmnt.length;
    /* for each element, create a new DIV that will act as the selected item: */
    const selectedItemDiv = document.createElement('DIV');
    selectedItemDiv.setAttribute('class', 'select-selected');
    selectedItemDiv.innerHTML =
      selElmnt.options[selElmnt.selectedIndex].innerHTML;
    customSelectContainers[i].appendChild(selectedItemDiv);
    /* for each element, create a new DIV that will contain the option list: */
    const optionListContainerDiv = document.createElement('DIV');
    optionListContainerDiv.setAttribute('class', 'select-items select-hide');
    for (let j = 0; j < selectElementCount; j += 1) {
      /* for each option in the original select element,
      create a new DIV that will act as an option item: */
      const optionDiv = document.createElement('DIV');
      optionDiv.innerHTML = selElmnt.options[j].innerHTML;
      optionDiv.addEventListener('click', (e) => {
        /* when an item is clicked, update the original select box,
        and the selected item: */
        let y;
        const selectHtmlTag =
          e.target.parentNode.parentNode.getElementsByTagName('select')[0];

        const selectedTextDiv = e.target.parentNode.previousSibling;

        for (let i2 = 0; i2 < selectHtmlTag.length; i2 += 1) {
          if (selectHtmlTag.options[i2].innerHTML === e.target.innerHTML) {
            selectHtmlTag.selectedIndex = i2;
            selectHtmlTag.options[i2].selected = 'selected';
            selectHtmlTag.dispatchEvent(
              new CustomEvent('custom-select-change')
            );
            selectHtmlTag.value = selectHtmlTag.options[i2].value;
            selectedTextDiv.innerHTML = e.target.innerHTML;
            // y == previous selected option?
            y = e.target.parentNode.getElementsByClassName('same-as-selected');
            for (let k = 0; k < y.length; k += 1) {
              y[k].removeAttribute('class');
            }
            e.target.setAttribute('class', 'same-as-selected');
            const clickEvent = new Event('click');
            selectHtmlTag.options[i2].dispatchEvent(clickEvent);
            break;
          }
        }
        selectedTextDiv.click();
      });
      optionListContainerDiv.appendChild(optionDiv);
    }
    customSelectContainers[i].appendChild(optionListContainerDiv);
    selectedItemDiv.parentElement.addEventListener('click', (e) => {
      /* when the select box is clicked, close any other select boxes,
        and open/close the current select box: */
      e.stopPropagation();
      const element = e.target.parentElement.querySelector(
        'div.select-selected'
      );

      if (element) {
        closeAllSelect(element);
        element.nextSibling.classList.toggle('select-hide');

        const chevrons =
          element.parentElement.querySelectorAll('[class^=chevron]');
        chevrons.forEach((chevron) => chevron.classList.toggle('d-none'));
      }
    });
  }

  /* close if the user clicks anywhere outside the select box */
  document.addEventListener('click', (e) => closeAllSelect(e.target));
}
