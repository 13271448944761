// BRAND DETAIL PAGE

// detect when top of body content has reached top of viewport
// and reveal condensed/persistent header treatment
// (also applies to small screen sidenav layout)
const detailBody = document.querySelector('.js-brand-detail-body');
const detailSidenav = document.querySelector('.js-brand-detail-sidenav');
const detailHeaderCondensed = document.querySelector(
  '.js-brand-detail-header-condensed'
);
if (detailBody && detailSidenav && detailHeaderCondensed) {
  const positionHeader = () => {
    const bodyPos = detailBody.getBoundingClientRect().top;
    if (bodyPos < 65) {
      detailHeaderCondensed.classList.add('in-view');
      detailHeaderCondensed.setAttribute('aria-hidden', false);
      detailSidenav.classList.add('sticky');
    } else {
      detailHeaderCondensed.classList.remove('in-view');
      detailHeaderCondensed.setAttribute('aria-hidden', true);
      detailSidenav.classList.remove('sticky');
    }
  };
  window.addEventListener('scroll', positionHeader);
}

// use scroll position to determine which Brand Detail tab item is "active"
// we'll call a section "active" if its anchor spacer has almost hit the top of the viewport
// feel free to adjust "active" window - seems like a fair compromise 
// for "squarely in view" between mobile and desktop layout
const sectionAnchors = document.querySelectorAll('.js-brand-detail-anchor');
const sectionLinks = document.querySelectorAll('.js-brand-detail-link');
if (sectionAnchors) {
  let isScrolling;
  const positionSection = () => {
    // clear scroll timeout
    window.clearTimeout(isScrolling);
    // instead of executing on scroll, we'll run our checks when the user has *stopped* scrolling
    // (this prevents some jumpy active treatments on non-active links)
    isScrolling = setTimeout(() => {
      Array.from(sectionAnchors).forEach((section) => {
        // has the section's anchor reached or passed the top of the viewport?
        const sectionPos = section.getBoundingClientRect().top;
        if (sectionPos < 200) {
          // clear out any existing active class
          Array.from(sectionLinks).forEach((link) =>
            link.classList.remove('menu__link--active')
          );
          // find the section's corresponding link and apply active class
          const targetId = section.id;
          const targetLink = Array.from(sectionLinks).find(
            (link) => link.dataset.section === targetId
          );
          if (targetLink) {
            targetLink.classList.add('menu__link--active');
          }
        }
      });
    }, 100);
  };
  window.addEventListener('scroll', positionSection);
}

// New JS for toggle functionality
const loadMoreButtons = document.querySelectorAll('.js-load-more-button');
if (loadMoreButtons && loadMoreButtons.length) {
  loadMoreButtons.forEach((loadMoreButton) => {
    const loadMore = loadMoreButton;
    let elementList = []
    const id = loadMore.getAttribute('id');
    if(id === 'loadMore') {
      elementList = [...document.querySelectorAll('.js-press-article-load-more')]
    } else if(id === 'loadMoreTestimonials') { 
      elementList = [...document.querySelectorAll('.js-testimonials-load-more')];
    }
    let currentItems = 5;
    for (let i = 0; i < currentItems; i += 1) {
      if (elementList[i]) {
        elementList[i].style.display = 'block';
      }
    }
    if (elementList.length > 5) {
      loadMore.addEventListener('click', (loadMoreEvent) => {
        const event = loadMoreEvent;
        event.preventDefault();
        for (let i = currentItems; i < currentItems + 5; i += 1) {
          if (elementList[i]) {
            elementList[i].style.display = 'block';
          }
        }
        currentItems += 5;
        if (currentItems >= elementList.length) {
          event.target.style.display = 'none';
        }
      });
    } else {
      loadMore.style.display = 'none';
    }
  });
}
