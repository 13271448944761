import React from 'react';
import PropTypes from 'prop-types';
import CollectionLoadingCard from './CardComponents/CollectionLoadingCard';
import CollectionCard from './CardComponents/CollectionCard';
import EmptyStateCollection from '../CommonComponents/EmptyStateCollection';
import utils from '../utils';

function CollectionListRender({ refreshSignal }) {
  const API_RESPONSE_PAGE_SIZE = 12;
  const { getContentFromAPI } = utils;
  const [isLoading, setIsLoading] = React.useState(true);
  const [collections, setCollections] = React.useState([]);
  const [nextPageURL, setNextPageURL] = React.useState(null);

  /**
   * Handles refresh signal and first mount
   */
  React.useEffect(() => {
    const isRefresh = refreshSignal > 0;
    getContentFromAPI(
      `/api/foldering/folders/?page_size=${API_RESPONSE_PAGE_SIZE}`,
      isRefresh,
      collections,
      setIsLoading,
      setNextPageURL,
      setCollections
    );
  }, [refreshSignal]);

  /**
   * Handles infinite scroll
   */
  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight =
      document.querySelectorAll('.collections__list')[0].scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (nextPageURL && !isLoading) {
        getContentFromAPI(
          nextPageURL,
          false,
          collections,
          setIsLoading,
          setNextPageURL,
          setCollections
        );
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [collections, isLoading]);

  return (
    <div className="collections__list">
      {collections && collections.length > 0
        ? collections.map((collection, key) => (
            <CollectionCard key={key} collection={collection} />
          ))
        : !isLoading && <EmptyStateCollection component="component-list" />}
      {isLoading &&
        [...Array(3)].map((_, i) => <CollectionLoadingCard key={i} />)}
    </div>
  );
}

CollectionListRender.propTypes = {
  refreshSignal: PropTypes.number.isRequired,
};

export default CollectionListRender;
