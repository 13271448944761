import SocketConnector from '../utils/Socketry/SocketConnector';

class NotificationsSocketConnector extends SocketConnector {
  constructor(testUrl = null) {
    super('notification-server', 'notification_event', testUrl);
  }

  /**
   * Router for channel events
   * @param {*} event
   */
  handleChannelEvent(event) {
    if (event.action === 'new_notification') {
      this.fireEvent('new_notification', event.payload);
    }
  }
}

const SocketConnection = new NotificationsSocketConnector();

export default SocketConnection;
