import React from 'react';
import PropTypes from 'prop-types';
import CollectionBaseModal from '../CommonComponents/CollectionModal';
import AddToCollectionForm from './AddToCollectionForm';
import { useModalContext } from './AddToCollectionContext';

/**
 * React component for the modal that allows users to add items to collection.
 * @param {object} props - React props.
 * @param {boolean} props.modalIsOpen - Whether the modal is open.
 * @param {number} props.objectPk - The primary key of the object to add to collection.
 * @param {string} props.objectType - The type of the object to add to collection.
 * @param {function} props.modalOnClose - Function to close the modal.
 * @returns {JSX.Element}
 */
function AddToCollectionModal({ objectPk, objectType }) {
  const { modalIsOpen, setModalIsOpen } = useModalContext();

  return (
    <CollectionBaseModal
      title="Save to Collection"
      closeOnClickOutside={true}
      showCloseButton={true}
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
    >
      <AddToCollectionForm objectType={objectType} objectPk={objectPk} />
    </CollectionBaseModal>
  );
}

AddToCollectionModal.propTypes = {
  objectPk: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
};

export default AddToCollectionModal;
