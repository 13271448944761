import React from 'react';
import PropTypes from 'prop-types';
import Collection from '../DataModels/Collection';
import CollectionItemLoadingCard from './CardComponents/CollectionItemLoadingCard';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import LoadingCircle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingCircle';
import CollectionItemCard from './CardComponents/CollectionItemCard';
import CollectionItem from '../DataModels/CollectionItem';
import CollectionBaseModal from '../CommonComponents/CollectionModal';
import CollectionForm from '../CommonComponents/CollectionForm';
import fetchData from '../../../../../../../config/static/js/utils/fetchData';
import updateData from '../../../../../../../config/static/js/utils/updateData';
import deleteData from '../../../../../../../config/static/js/utils/deleteData';
import EmptyStateCollection from '../CommonComponents/EmptyStateCollection';
import utils from '../utils';
import MediaProfileCardBuilder from './CardComponents/MediaProfileCardBuilder';
import {
  useLoadingState,
  useGlobalLoadingState,
} from '../../../../../../core/static/core/js/utils/globalLoadingState';
import { CollectionProvider } from './CollectionContextProvider';
import { ThemeProvider } from '@mui/material';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';

const API_RESPONSE_PAGE_SIZE = 12;

function CollectionDetailPage({ collectionSlug }) {
  const { showMessage } = utils;
  const [isItemsLoading, setIsItemsLoading] = useLoadingState(
    'collections',
    'items'
  );
  const [isCollectionLoading, setIsCollectionLoading] = useLoadingState(
    'collections',
    'collection'
  );
  const isSomethingLoading = useGlobalLoadingState('collections');

  const [collection, setCollection] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [nextPageURL, setNextPageURL] = React.useState(null);
  const [EditModalIsOpen, setEditModalIsOpen] = React.useState(false);
  const [removeModalIsOpen, setRemoveModalIsOpen] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const backBtnRef = React.useRef(null);
  const editBtnRef = React.useRef(null);
  const removeBtnRef = React.useRef(null);

  const collectionBaseUrl = `/api/foldering/folders/${collectionSlug}/`;
  const itemsBaseUrl = `${collectionBaseUrl}items/?page_size=${API_RESPONSE_PAGE_SIZE}`;

  const getItemsFromAPI = async (url) => {
    setIsItemsLoading(true);
    try {
      const abortController = new AbortController();
      const res = await fetchData(url, abortController.signal);
      setNextPageURL(res.next);
      setItems((prevItems) => [
        ...prevItems,
        ...res.results?.map((item) => new CollectionItem(item)),
      ]);
    } catch (error) {
      showMessage(
        `There was an error trying to retrieve data from the collection. ${error}`,
        true
      );
    }
    setIsItemsLoading(false);
  };

  const getCollectionFromAPI = async () => {
    setIsCollectionLoading(true);
    try {
      const abortController = new AbortController();
      const res = await fetchData(collectionBaseUrl, abortController.signal);
      setCollection(new Collection(res));
    } catch (error) {
      setHasError(true);
      showMessage(
        `There was an error trying to retrieve items from the collection. ${error}`,
        true
      );
    }
    setIsCollectionLoading(false);
  };

  const refreshCollectionData = React.useCallback(
    ({ objectPk, objectType }) => {
      // Refresh the collection data (primarily for the item count)
      getCollectionFromAPI();

      // Check if the item was removed from the collection
      const item = items.find(
        (item) =>
          item.getRelatedObjectPk() === objectPk &&
          item.getItemType() === objectType
      );
      if (item) {
        const specificItemBaseUrl = `${collectionBaseUrl}items/${item.getPk()}/`;
        fetchData(specificItemBaseUrl).catch((error) => {
          // If the item was removed from the collection, let's hide it
          // We can't remove from the list because it would mess with the pagination
          if (error.message === '404') {
            // Setting the items state to a new array to trigger a re-render
            setItems((prevItems) => {
              const newItems = [...prevItems];
              newItems
                .find((newItem) => newItem.getPk() === item.getPk())
                .hide();
              return newItems;
            });
          }
        });
      }
    },
    [items]
  );

  /**
   * On mount perform initial request
   */
  React.useEffect(() => {
    getCollectionFromAPI();
    getItemsFromAPI(itemsBaseUrl);
  }, []);

  /**
   *  Handles clicks on specific ref items
   */
  const handleClicks = (event) => {
    // Clicked on the back button
    if (backBtnRef.current && backBtnRef.current.contains(event.target)) {
      window.location.href = '/collections';
    }

    // Clicked on the edit button should toggle the modal
    if (editBtnRef.current && editBtnRef.current.contains(event.target)) {
      setEditModalIsOpen(true);
    }

    // Clicked on the remove button should toggle the modal
    if (removeBtnRef.current && removeBtnRef.current.contains(event.target)) {
      setRemoveModalIsOpen(true);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClicks, true);
    return () => {
      document.removeEventListener('click', handleClicks, true);
    };
  }, []);

  /**
   * Handles infinite scroll
   */
  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.querySelectorAll('.collections__container')[0]
      .scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (nextPageURL && !isItemsLoading) {
        // Remove the event listener to avoid multiple calls
        window.removeEventListener('scroll', onScroll);
        getItemsFromAPI(nextPageURL);
      }
    }
  };

  React.useEffect(() => {
    if (!isItemsLoading) window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [items, isItemsLoading]);

  /**
   *  Applies the masonry effect on the grid
   */
  React.useEffect(() => {
    if (!isSomethingLoading) {
      const grid = document.getElementsByClassName(
        'collections__body--masonry'
      )[0];
      const rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
      );
      const rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
      );
      const theItems = document.getElementsByClassName(
        'collection-item-card__container'
      );
      for (let i = 0; i < theItems.length; i += 1) {
        const item = theItems[i];
        if (item.querySelector('.js-content')) {
          const rowSpan = Math.ceil(
            (item.querySelector('.js-content').getBoundingClientRect().height +
              rowGap) /
              (rowHeight + rowGap)
          );
          item.style.gridRowEnd = 'span ' + rowSpan;
        }
      }
    }
  }, [items, isSomethingLoading]);

  /**
   *  Handles the collection edition
   */
  const editHandler = async (event) => {
    event.preventDefault();

    const target = event.target;
    if (target.classList.contains('collections-modal__form')) {
      const formTextInput = target.querySelector('input');
      const newTitle = formTextInput.value;
      const formSubmitButton = target.querySelector('button');

      // Disabling the submit button while loading
      formSubmitButton.disabled = true;

      try {
        const res = await updateData(
          collectionBaseUrl,
          { json: { title: newTitle } },
          'PATCH',
          null,
          true
        );

        if (res?.code === 400) {
          showMessage(res.body.title[0], true);
        }

        setEditModalIsOpen(false);
        showMessage(`Changes saved.`);

        if (res.slug !== collectionSlug) {
          // If slug really changed, let's reload the page
          // to make sure everything is going to  work
          window.location.href = `/collections/${res.slug}`;
        } else {
          getCollectionFromAPI();
        }
      } catch (error) {
        showMessage(`There was an error trying to edit item. ${error}`, true);
      }

      // Re-enabling the button after finish loading
      formSubmitButton.disabled = false;
    }

    return false;
  };

  /**
   * Handles deleting the collection
   */
  const removeItem = () => {
    // Buttons disabled
    const buttons = document.querySelectorAll('.collections-modal__button');
    for (let i = 0; i > buttons.length; i += 1) {
      buttons.disabled = true;
    }

    try {
      const res = deleteData(collectionBaseUrl);

      // Redirect to index
      window.location.href = '/collections';
    } catch (error) {
      showMessage(`There was an error trying to remove item. ${error}`, true);

      // Buttons re-enabled
      for (let i = 0; i > buttons.length; i += 1) {
        buttons.disabled = false;
      }
    }
  };

  /**
   * Handles clicks on the remove confirmation modal buttons
   */
  const removeModalClickHandler = (event) => {
    if (event.target.classList.contains('js-close-remove-modal')) {
      setRemoveModalIsOpen(false);
    }

    if (event.target.classList.contains('js-do-remove-item')) {
      removeItem();
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', removeModalClickHandler);
    return () => window.removeEventListener('click', removeModalClickHandler);
  });

  // Builds the removal confirmation buttons to be injected into the modal
  const removeModalContent = (
    <div className="collections-modal__content">
      <p className="collections-modal__text">
        Are you sure you want to remove this collection?
      </p>
      <div className="collections-modal__buttons-container">
        <button
          className="collections-modal__button collections-modal__button--multiple collections-modal__button--secondary js-close-remove-modal"
          type="button"
        >
          No, I'll keep it
        </button>
        <button
          className="collections-modal__button collections-modal__button--multiple js-do-remove-item"
          type="button"
        >
          Yes, Remove
        </button>
      </div>
    </div>
  );

  const getCardComponentBuilder = React.useCallback(
    (collectionItem) => {
      if (collectionItem.isHidden()) return null;
      const builders = {
        default: CollectionItemCard,
        mediaprofile: MediaProfileCardBuilder,
      };
      return React.createElement(
        builders[collectionItem.getItemType()] || builders['default'],
        {
          key: collectionItem.getRelatedObjectPk(),
          collectionItem: collectionItem,
        }
      );
    },
    [items]
  );

  return (
    <ThemeProvider theme={PressHookTheme}>
      <CollectionProvider refreshCollectionData={refreshCollectionData}>
        <div className="collections__container">
          <CollectionBaseModal
            isOpen={EditModalIsOpen}
            setIsOpen={setEditModalIsOpen}
            title={'Edit Collection'}
          >
            <CollectionForm collection={collection} editHandler={editHandler} />
          </CollectionBaseModal>
          <CollectionBaseModal
            isOpen={removeModalIsOpen}
            setIsOpen={setRemoveModalIsOpen}
            title={'Remove Collection'}
            closeOnClickOutside={false}
            showCloseButton={false}
          >
            {removeModalContent}
          </CollectionBaseModal>
          <div className="collections__header">
            <div className="collections__header-button" ref={backBtnRef}>
              <img
                className="collections__button"
                src={`${staticUrl}/foldering/images/circle-arrow-left.svg`}
              />
            </div>
            <div className="collections__header-centralized">
              {isCollectionLoading ? (
                <>
                  <h3 className="collections__header-text">
                    <LoadingRectangle height={32} width={200} />
                  </h3>
                  <div className="collections__header-subtitle">
                    <LoadingRectangle height={26} width={70} />
                  </div>
                </>
              ) : (
                <>
                  <h3 className="collections__header-text">
                    {hasError ? '' : collection.getTitle()}
                  </h3>
                  <p className="collections__header-subtitle">
                    {hasError ? '' : collection.getItemCount()}
                  </p>
                </>
              )}
            </div>
            <div className="collections__header-button collections__header-button--multiple">
              {isCollectionLoading ? (
                <>
                  <LoadingCircle diameter={32} />
                  <LoadingCircle diameter={32} />
                </>
              ) : (
                <>
                  <img
                    className="collections__button"
                    src={`${staticUrl}/foldering/images/circle-garbage-can.svg`}
                    ref={removeBtnRef}
                    alt="Remove Collection"
                  />
                  <img
                    className="collections__button"
                    src={`${staticUrl}/foldering/images/circle-pencil.svg`}
                    ref={editBtnRef}
                    alt="Edit Collection"
                  />
                </>
              )}
            </div>
          </div>
          <div className="collections__body collections__body--masonry">
            {items && items.length > 0
              ? items.map((collectionItem) =>
                  getCardComponentBuilder(collectionItem)
                )
              : !isItemsLoading && (
                  <EmptyStateCollection component="component-item" />
                )}
            {isItemsLoading &&
              [...Array(5)].map((_, i) => (
                <CollectionItemLoadingCard key={i} />
              ))}
          </div>
        </div>
      </CollectionProvider>
    </ThemeProvider>
  );
}

CollectionDetailPage.propTypes = {
  collectionSlug: PropTypes.string.isRequired,
};

export default CollectionDetailPage;
