import React from 'react';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function UpgradeModalPremiumGradiant({ selectedFeature, selectedPlan }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [titleContent, setTitleContent] = React.useState('');
  const [descriptionContent, setDescriptionContent] = React.useState('');
  const [relatedFeatures, setRelatedFeatures] = React.useState([]);

  const fallbackToSelectedPlan = () => {
    if (selectedPlan) {
      setTitleContent(`Learn more about ${selectedPlan.name}`);
      setDescriptionContent(selectedPlan.description);
      setRelatedFeatures(
        selectedPlan.features.map((feature, i) => feature.description)
      );
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (selectedFeature) {
      fetch(`/api/pricing-plans/features/${selectedFeature}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.upgrade_cta_title) {
            setTitleContent(data.upgrade_cta_title);
            setDescriptionContent(data.upgrade_cta_description);
            setRelatedFeatures(data.upgrade_cta_supporting_features_list);
            setIsLoading(false);
          } else {
            fallbackToSelectedPlan();
          }
        });
    } else {
      fallbackToSelectedPlan();
    }
  }, [selectedFeature, selectedPlan]);

  return (
    !isLoading && (
      <div className="modal-premium-gradient">
        <div className="price-premium-area modal-premium-gradient__bottom-borders modal-premium-gradient__price-premium-area">
          <div className="price-premium modal-premium-gradient__price-premium">
            <div className="price-premium__content">
              <h2 className="headline headline--white price-premium__title modal-premium-gradient__price-premium-title">
                {titleContent}
              </h2>
              <p className="price-premium__desc">{descriptionContent}</p>

              <div className="price-premium__tags-overlay"></div>
              <ul className="price-premium__tags">
                {relatedFeatures.map((feature, key) => (
                  <li className="price-premium__tags-item" key={key}>
                    <a className="price-premium__tag">{feature}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default UpgradeModalPremiumGradiant;
