/**
 * This logic is for tags/certs on expert cards!
 *
 * Because the comps call for a design in which we fill all available space with
 * this content, we need the styles all loaded in and present in order to
 * calculate which ones to show.
 *
 * The way this works is that you define an auto-compress element by adding the class
 * "auto-compress" for expert cards logic or "auto-compress-horizontal" for other tags logic
 *
 * And the dataset elements:
 *
 * data-end-class="name" - which is the class to apply to the container that will contain the +N
 * data-compress-to="int" - which is the max-height in pixels that the final content should fill
 *
 * This code will slice off elements in each of these containers until the desired height is met.
 * After met, it will apply auto-compress__visible, which will bring the opacity up smoothly.
 *
 * Elements eligible for auto-compress should live in a container that is allowed to scale freely.
 * However, in order to prevent this logic from making items jump around, this item should be in a container
 * element that restricts the height down to the desired height.
 *
 * Elements eligible for auto-compress-horizontal should live in a container that is NOT allowed to scale freely.
 * It must have a fixed width with content overflowing it.
 */

const autoCompressTargets = document.querySelectorAll('.auto-compress');
const autoCompressHorizontalTargets = document.querySelectorAll(
  '.auto-compress-horizontal'
);

/**
 * Removes the last child element if it is over the set height,
 * @param {*} container container element to remove from
 * @param {*} targetHeight target height
 * @returns 'empty-removed', 'content-removed', or 'none-removed'
 */
function removeChildIfOverHeight(container, targetHeight) {
  if (container.getBoundingClientRect().height > targetHeight) {
    if (!container.lastChild.textContent.trim()) {
      container.removeChild(container.lastChild);
      return 'empty-removed';
    }
    container.removeChild(container.lastChild);
    return 'content-removed';
  }
  return 'none-removed';
}

/**
 * Removes the last child element and adds to removed list returning it
 * @param {*} container container element to remove from
 * @param {*} removedList list of removed childs
 * @returns removedList
 */
function removeSecondLastChild(container, removedChilds) {
  const removedChild = container.lastChild.previousElementSibling;
  removedChilds.push(removedChild);
  container.removeChild(removedChild);
  return removedChilds;
}

/**
 * Creates a div item with given text content and class name
 * @param {*} className class name to apply
 * @param {*} content content to insert
 * @returns DOMElement
 */
function createDivWithClassNameAndContent(className, content) {
  const div = document.createElement('div');
  div.innerText = content;
  div.className = className;
  return div;
}

/**
 * Automatically compresses all found items to compress.
 */
autoCompressTargets.forEach((container) => {
  let numRemoved = 0;
  const compressTo = parseInt(container.dataset.compressTo, 10);
  const { endClass } = container.dataset;

  let lastResult = 'empty-removed';

  // Go through and strip out items til we hit desired height.
  while (lastResult !== 'none-removed') {
    lastResult = removeChildIfOverHeight(container, compressTo);
    if (lastResult === 'content-removed') {
      numRemoved += 1;
    }
  }

  // If none removed, we're done.
  if (numRemoved === 0) {
    container.classList.add('auto-compress__visible');
    return;
  }

  // Add the "+N" card at the end
  let endElement = createDivWithClassNameAndContent(endClass, `+${numRemoved}`);
  container.appendChild(endElement);

  // Make sure "+N" card didn't push us over
  if (removeChildIfOverHeight(container, compressTo) === 'none-removed') {
    container.classList.add('auto-compress__visible');
    return;
  }

  // If it did, remove one more element and move on.
  container.removeChild(container.lastChild);
  container.removeChild(container.lastChild);

  numRemoved += 2;
  endElement = createDivWithClassNameAndContent(endClass, `+${numRemoved}`);

  container.appendChild(endElement);

  container.classList.add('auto-compress__visible');
});

/**
 * Automatically compresses all found items to compress.
 */
autoCompressHorizontalTargets.forEach((container) => {
  // No need to remove elements, we're done.
  if (container.scrollWidth <= container.clientWidth) {
    container.classList.add('auto-compress__visible');
    return;
  }

  const { endClass } = container.dataset;
  let removedChilds = [];

  // Add the "+N" card at the end first
  const endElement = createDivWithClassNameAndContent(endClass, '+N');
  container.appendChild(endElement);

  // Go through and strip out items til we hit desired width.
  while (container.scrollWidth > container.clientWidth) {
    removedChilds = removeSecondLastChild(container, removedChilds);
  }

  // Add the "+N" card at the end
  endElement.innerText = `+${removedChilds.length}`;

  container.classList.add('auto-compress__visible');
});
