import React from 'react';
import logError from '../../../../../../../config/static/js/utils/logError';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

export default function ObjectSelectionForm({
  selectedFeature,
  setObjectType,
  setObjectId,
  setSection,
}) {
  const [instances, setInstances] = React.useState([]);
  const [selectedInstance, setSelectedInstance] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFeatureName, setSelectedFeatureName] = React.useState('');

  /**
   * Populates the object selection form with the current user's experts and brands
   */
  React.useEffect(() => {
    setIsLoading(true);
    fetch(`/api/users/current-user/brands-and-experts/`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        let objects = [];
        if (data['brands']) {
          for (let i = 0; i < data['brands'].length; i++) {
            const brand = data['brands'][i];
            objects.push({
              id: brand.pk,
              title: brand.title,
              type: 'brand',
            });
          }
        }
        if (data['experts']) {
          for (let i = 0; i < data['experts'].length; i++) {
            const expert = data['experts'][i];
            objects.push({
              id: expert.pk,
              title: expert.name,
              type: 'expert',
            });
          }
        }
        if (objects.length > 1) {
          setSelectedInstance({
            id: objects[0].id,
            type: objects[0].type,
          });
        } else if (objects.length === 1) {
          // Automatically select the only brand/expert
          // which will cause a section redirection
          setObjectId(objects[0].id);
          setObjectType(objects[0].type);
        } else {
          // No brands nor experts
          setSection('AddBrandModal');
        }
        setInstances(objects);
      })
      .catch((error) => {
        logError(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  /**
   * Get the featured name
   */
  React.useEffect(() => {
    if (selectedFeature) {
      if (!isLoading) setIsLoading(true);
      fetch(`/api/pricing-plans/features/${selectedFeature}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setSelectedFeatureName(data.description);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedFeature]);

  /**
   * Handles the object selection
   *
   * Note: There is a useEffect listening for the objectId and objectType
   *       which will redirect to the right section after that
   */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setObjectId(selectedInstance.id);
    setObjectType(selectedInstance.type);
  };

  return isLoading ? (
    <LoadingRectangle height={300} />
  ) : (
    <div className="add-brand-modal__content">
      <h2 className="add-brand-modal__title">
        {selectedFeatureName
          ? `Upgrade to use ${selectedFeatureName}`
          : `Select a brand/expert`}
      </h2>
      <form onSubmit={handleFormSubmit}>
        <div className="add-brand-modal__field-container">
          <label
            htmlFor="subscription_slot"
            className="add-brand-modal__field-label"
          >
            Select a brand/expert to continue:
          </label>
          <select
            id="instance"
            name="instance"
            className="add-brand-modal__field-input"
            onChange={(e) => {
              setSelectedInstance({
                id: e.target.value,
                type: e.target.options[e.target.selectedIndex].dataset
                  .objectType,
              });
            }}
          >
            {instances.map((instance, key) => (
              <option
                value={instance.id}
                key={key}
                data-object-type={instance.type}
              >
                {instance.type === 'brand' ? 'Brand' : 'Expert'}{' '}
                {instance.title}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className={
            isLoading
              ? 'add-brand-modal__submit-btn add-brand-modal__submit-btn--disabled'
              : 'add-brand-modal__submit-btn'
          }
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Next'}
        </button>
      </form>
    </div>
  );
}
