/* global Intercom */
const contactLinks = document.getElementsByClassName('contact-link');

function contactRequest(e) {
  if (typeof Intercom !== 'undefined') {
    e.preventDefault();
    Intercom('show');
    // Send event to segment.io
    if (window.analytics) {
      const trackData = {};
      window.analytics.track(
        'Intercom Opened',
        trackData
      );
    }
  }
  return false;
}

const updateIntercomDisplay = () => {
  if (typeof Intercom !== 'undefined') {
    Intercom('update', {
      hide_default_launcher: window.innerWidth <= 1080,
    });
  }
};

Array.from(contactLinks).forEach((element) => {
  element.addEventListener('click', contactRequest);
});

window.addEventListener('resize', updateIntercomDisplay);
