export function configAddBrandButton() {
  const welcomeModal = document.getElementById('welcomePresshookModal');
  const brandBtn = document.querySelectorAll('.js-add-brand');
  const brandModal = document.getElementById('newBrandModal');

  if (brandModal) {
    const modalClose = document.getElementById('modalClose');

    modalClose.addEventListener('click', () => {
      brandModal.classList.remove('ph-modal--open');
    });

    // If the user clicks the button, open the modal
    if (brandBtn) {
      brandBtn.forEach((button) => {
        button.addEventListener('click', () => {
          brandModal.classList.add('ph-modal--open');
          if (welcomeModal) welcomeModal.style.display = 'none';
        });
      });
    }

    // If the user clicks anywhere outside of the modal, close it
    window.onclick = (event) => {
      if (event.target === brandModal) {
        brandModal.classList.remove('ph-modal--open');
      }
    };
  }
}

/**
 * Add an Expert modal
 */

export function configAddExpertButton() {
  const welcomeModal = document.getElementById('welcomePresshookModal');
  const expertModal = document.getElementById('newExpertModal');
  const expertBtn = document.querySelectorAll('.js-add-expert');

  if (expertModal) {
    const modalClose = expertModal.querySelector('#expertModalClose');

    modalClose.addEventListener('click', () => {
      expertModal.classList.remove('ph-modal--open');
    });

    // If the user clicks the button, open the modal
    if (expertBtn) {
      expertBtn.forEach((button) => {
        button.addEventListener('click', () => {
          expertModal.classList.add('ph-modal--open');
          if (welcomeModal) welcomeModal.style.display = 'none';
        });
      });
    }

    // If the user clicks anywhere outside of the modal, close it
    window.onclick = (event) => {
      if (event.target === expertModal) {
        expertModal.classList.remove('ph-modal--open');
      }
    };
  }
}

/**
 * Opens the modals if the URL has the query string
 * ?add-expert=true
 * ?add-brand=true
 */

export function configAutoOpens() {
  const urlParams = new URLSearchParams(window.location.search);
  const expertBtn = document.querySelectorAll('.js-add-expert');
  if (expertBtn && urlParams.get('add-expert')) {
    setTimeout(() => {
      expertBtn.forEach((button) => {
        const link = button.getElementsByTagName('a');
        if (link.length > 1) {
          link[0].click();
        } else {
          button.click();
        }
      });
    }, 1000);
  }

  const brandBtn = document.querySelectorAll('.js-add-brand');
  if (brandBtn && urlParams.get('add-brand')) {
    setTimeout(() => {
      brandBtn.forEach((button) => {
        const link = button.getElementsByTagName('a');
        if (link.length > 0) {
          link[0].click();
        } else {
          button.click();
        }
      });
    }, 1000);
  }
}
