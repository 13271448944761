import React from 'react';
import PropTypes from 'prop-types';

/**
 * A shimmering circle for a loading state
 */
function LoadingCircle({ diameter }) {
  const pxSize = `${diameter}px`;
  return (
    <div
      className="loadable-element__display"
      style={{
        height: pxSize,
        width: pxSize,
        borderRadius: pxSize,
        overflow: 'hidden',
      }}
    />
  );
}

LoadingCircle.propTypes = {
  diameter: PropTypes.number.isRequired,
};

export default LoadingCircle;
