import React from 'react';
import BilledPeriodSwitch from './BilledPeriodSwitch';
import PriceTab from './PriceTab';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';

function PlanSelectionDetail({
  objectType,
  objectId,
  setSection,
  setSelectedPlan,
  selectedBillingPeriod,
  setSelectedBillingPeriod,
  isUpgrade,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [plan, setPlan] = React.useState(null);
  const [annualDiscountText, setAnnualDiscountText] = React.useState('');
  const [titleContent, setTitleContent] = React.useState('');
  const [descriptionContent, setDescriptionContent] = React.useState('');

  React.useEffect(() => {
    fetch(
      `/api/pricing-plans/?object_type=${objectType}&object_id=${objectId}${
        isUpgrade ? '&is_upgrade=1' : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let promotedPlans = data.filter((e) => e.is_promoted);
        if (promotedPlans.length == 0) {
          promotedPlans = data;
        }
        const plan = promotedPlans.length > 0 ? promotedPlans.at(-1) : null;
        setPlan(plan);
        setSelectedPlan(plan);

        setAnnualDiscountText(promotedPlans[0]?.pricing_annual_discount_text);
        setIsLoading(false);
      });
  }, []);

  /**
   * Get content for the header
   */
  React.useEffect(() => {
    if (!titleContent && !descriptionContent) {
      fetch(`/api/snippets/upgrade-modal/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setTitleContent(data.title);
          setDescriptionContent(data.description);
        });
    }
  }, [titleContent, descriptionContent]);

  return isLoading ? (
    <div className="modal-detail-plan">
      <div className="price__top-content">
        <div className="subhead price__desc modal-detail-plan__price-desc">
          <LoadingRectangle height={70} />
        </div>
        <div className="price-tab__billed modal-detail-plan__price-tab-billed">
          <LoadingRectangle height={30} width={250} />
        </div>
      </div>
      <LoadingRectangle height={145} />
    </div>
  ) : (
    <div className="modal-detail-plan">
      {plan ? (
        <>
          <div className="price__top-content">
            <h2 className="price__title modal-detail-plan__price-title">
              {titleContent ? titleContent : <LoadingRectangle height={70} />}
            </h2>

            {descriptionContent !== undefined ? (
              <p className="subhead price__desc modal-detail-plan__price-desc">
                {descriptionContent}
              </p>
            ) : (
              <LoadingRectangle height={30} width={250} />
            )}

            <BilledPeriodSwitch
              billingPeriod={selectedBillingPeriod}
              setBillingPeriod={setSelectedBillingPeriod}
              annualDiscountText={annualDiscountText}
            />
          </div>

          <PriceTab
            plan={plan}
            isMostSelected={true}
            isLoading={isLoading}
            billingPeriod={selectedBillingPeriod}
            setSection={setSection}
            setSelectedPlan={setSelectedPlan}
          />
          <div className="modal-detail-plan__choose">
            <p className="modal-detail-plan__choose-text">
              Or
              <a
                className="modal-detail-plan__choose-link"
                onClick={() => setSection('PlanSelectionList')}
              >
                choose another plan
                <span className="modal-detail-plan__choose-link-arrow">
                  <img
                    className="modal-detail-plan__choose-link-arrow-img"
                    src={`${staticUrl}/images/svg/modal-plan-arrow.svg`}
                    alt="arrow"
                  />
                </span>
              </a>
            </p>
          </div>
        </>
      ) : (
        <div className="price__top-content">
          <h2 className="price__title modal-detail-plan__price-title">Oops!</h2>
          <p className="subhead price__desc modal-detail-plan__price-desc">
            Sorry, we couldn't find a plan to suggest.
            <br />
            Please contact support to upgrade your account.
          </p>
        </div>
      )}
    </div>
  );
}

export default PlanSelectionDetail;
