import React from 'react';
import PropTypes from 'prop-types';
import CollectionItem from '../../DataModels/CollectionItem';
import AddToCollectionModal from '../../AddToCollection/AddToCollectionModal';
import fetchData from '../../../../../../../../config/static/js/utils/fetchData';

function CollectionItemCard({ collectionItem, refreshCollectionData }) {
  const imageContainerRef = React.useRef(null);
  const cardContainerRef = React.useRef(null);
  const saveButtonRef = React.useRef(null);
  const [isResized, setIsResized] = React.useState(false);
  const [isItemLoading, setIsItemLoading] = React.useState(true);
  const [isAddToCollectionModalOpen, setIsAddToCollectionModalOpen] =
    React.useState(false);
  const [wasRemovedFromCollection, setWasRemovedFromCollection] =
    React.useState(false);

  /**
   * Resizing each item card based on its cover image size,
   * so the masonry layout applies correctly
   */
  React.useEffect(() => {
    if (!isResized) {
      const coverImgData = collectionItem.getCoverImage();
      if (coverImgData.url) {
        const width = coverImgData.width;
        const height = coverImgData.height ? coverImgData.height : 235;
        const clientWidth = imageContainerRef.current.clientWidth;
        const newHeight = Math.ceil((height * clientWidth) / width);

        imageContainerRef.current.style.backgroundImage = `url(${coverImgData.url})`;
        imageContainerRef.current.style.backgroundSize = 'cover';
        imageContainerRef.current.style.backgroundPosition = 'center';

        if (newHeight < 235) {
          imageContainerRef.current.style.height = '235px';
        } else if (newHeight > 418) {
          imageContainerRef.current.style.height = '418px';
        } else {
          imageContainerRef.current.style.height = `${newHeight}px`;
        }
      } else {
        // No image provided
        imageContainerRef.current.classList.add(
          'collection-item-card__image--no-image'
        );
      }
      setIsResized(true);
      setIsItemLoading(false);
    }
  });

  // Handling click
  const handleClicks = (event) => {
    // Clicked on the save icon button
    if (saveButtonRef.current && saveButtonRef.current.contains(event.target)) {
      setIsAddToCollectionModalOpen(true);
    }

    // Clicked on the card, except the save icon button
    else if (
      cardContainerRef.current &&
      cardContainerRef.current.contains(event.target)
    ) {
      window.location.href = collectionItem.getURL();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClicks, true);
    return () => {
      document.removeEventListener('click', handleClicks, true);
    };
  }, []);

  // Handles removing the card from the list if item was removed from the collection
  const onAddToCollectionModalClose = (changed) => {
    if (changed) {
      setIsItemLoading(true);
      fetchData(
        `/api/foldering/${collectionItem.getItemType()}/${collectionItem.getRelatedObjectPk()}/is_a_foldered_item/?folder=${collectionItem.getFolderPk()}`
      ).then((res) => {
        if (!res.is_a_foldered_item) {
          setWasRemovedFromCollection(true);
          if (refreshCollectionData) refreshCollectionData();
        }
        setIsItemLoading(false);
      });
    }
  };

  if (wasRemovedFromCollection) {
    return '';
  } else {
    return (
      <>
        <AddToCollectionModal
          isOpen={isAddToCollectionModalOpen}
          setIsOpen={setIsAddToCollectionModalOpen}
          objectPk={collectionItem.getRelatedObjectPk()}
          objectType={collectionItem.getItemType()}
          externallyControlled={true}
          onClose={onAddToCollectionModalClose}
        />
        <div
          className={
            isItemLoading
              ? 'collection-item-card__container collection-item-card__container--loading'
              : 'collection-item-card__container'
          }
          ref={cardContainerRef}
          data-testid="collectionItemCard"
        >
          <div className="js-content">
            <div
              className="collection-item-card__image"
              ref={imageContainerRef}
            >
              <div
                className="add-to-collection-button add-to-collection-button--collection-item-card"
                ref={saveButtonRef}
                data-testid="addToCollectionButton"
              >
                <div className="add-to-collection-button__icon"></div>
              </div>
            </div>
            <div className="collection-item-card__footer">
              <div className="collection-item-card__icon-container">
                {collectionItem.getIconImage() && (
                  <img
                    src={collectionItem.getIconImage()}
                    className="collection-item-card__icon"
                  />
                )}
              </div>
              <div className="collection-item-card__titles">
                <div className="collection-item-card__title">
                  {collectionItem.getTitle()}
                </div>
                <div className="collection-item-card__subtitle">
                  {collectionItem.getSubtitle()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CollectionItemCard.propTypes = {
  collectionItem: PropTypes.instanceOf(CollectionItem).isRequired,
  refreshCollectionData: PropTypes.func,
};

export default CollectionItemCard;
