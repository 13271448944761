import React from 'react';
import ReactDOM from 'react-dom/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Toastify from 'toastify-js';
import NotificationItem from './NotificationItem';
import SocketConnection from './NotificationsSocketConnection';
import NotificationToast from './NotificationToast';
import NotificationsApi from './NotificationsApiHandler';
import NotificationsDropdown from './NotificationDropdown';
import setCookie from '../../../utils/setCookie';
import getCookie from '../../../utils/getCookie';
// import NotificationTab from './notificationTab';

/**
 * Navigation bar Notifications component
 * Display notifications list for authenticated users
 */

export default function Notifications({ iconRef, bubbleRef }) {
  const ref = React.useRef(null);
  const scrollableEl = React.useRef(null);
  const [notifications, setNotifications] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  // const [categories, setCategories] = React.useState({});
  // const [filter, setFilter] = React.useState('All');

  const getUnreadNotificationsPks = () =>
    notifications.filter((item) => !item.read_at).map((item) => item.pk);
  const getUnreadNotificationsLength = (items) =>
    items.filter((item) => !item.read_at).length;

  // Async function for fetching notifications
  async function getNotifications() {
    setLoading(true);

    NotificationsApi.call().then(() => {
      setNotifications([...NotificationsApi.results]);
      setLoading(false);
    });
  }

  const markNotificationsAsRead = (pkList) => {
    NotificationsApi.patchRead(pkList).then(() => {
      setNotifications([...NotificationsApi.results]);
    });
  };

  // Displays a toast notification
  const showToast = (item) => {
    // Only displays toast if icon is visible (doesn't duplicates from mobile and desktop instances)
    if (iconRef.offsetParent) {
      const notificationNode = document.createElement('div');

      const root = ReactDOM.createRoot(notificationNode);
      root.render(
        <NotificationToast
          notification={item.notification}
          message={item.message}
        />
      );

      Toastify({
        node: notificationNode,
        className: 'notifications__toast',
        destination: item.notification.url,
        newWindow: false,
        close: true,
        gravity: 'bottom',
        position: 'left',
        stopOnFocus: true,
        style: {},
        offset: {
          // Toastify auto adds 15 to these values
          x: 48 - 15,
          y: 34 - 15,
        },
        duration: 5000,
        onClick: () => {
          markNotificationsAsRead([item.pk]);
        },
      }).showToast();
    }
  };

  // Function called when new notification is received by WebSocket
  const addNotification = (notification) => {
    setNotifications((list) => [notification, ...list]);
    showToast(notification);
  };

  // Updates de number of unread notifications
  React.useEffect(() => {
    setUnreadCount(getUnreadNotificationsLength(notifications));
  }, [notifications]);

  /**
   * Handle click outside
   * Thank you: https://blog.logrocket.com/detect-click-outside-react-component-how-to/
   * @param {e} evt document click or touch event
   * @returns
   */

  const handleClickOutside = (event) => {
    if (active && ref.current && !ref.current.contains(event.target)) {
      setActive(false);
    }
  };

  // Handles click outside the notifications dropdown
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, active]);

  // Toggles the notifications list
  const handleIconClick = () => {
    if (!active) {
      setActive(true);
    }
  };

  // initialize Event Listener for notification icon clicks
  React.useEffect(() => {
    if (iconRef) iconRef.addEventListener('click', handleIconClick);
    return () => {
      iconRef.removeEventListener('click', handleIconClick);
    };
  }, [iconRef, active]);

  // Get notifications via API
  React.useEffect(() => {
    getNotifications();
    SocketConnection.init();
    SocketConnection.on('new_notification', addNotification);

    return () => {
      SocketConnection.off('new_notification', addNotification);
    };
  }, []);

  // Set up infinity scroll
  const infinityHandler = () => {
    if (
      Math.abs(
        scrollableEl.current.scrollHeight -
          scrollableEl.current.clientHeight -
          scrollableEl.current.scrollTop
      ) < 1 &&
      NotificationsApi.nextPageURL
    ) {
      NotificationsApi.call(true).then(() => {
        setNotifications([...NotificationsApi.results]);
      });
    }
  };

  React.useEffect(() => {
    if (scrollableEl.current) {
      scrollableEl.current.addEventListener('scroll', infinityHandler);
    }
  }, [scrollableEl, active]);

  // The following commented block of code is related to a filtering function on the
  // notifications panel. After the development, it was decided to leave it out of the
  // notifications MVP. On future call to include this feature, it can be just uncommmented.

  // Get notifications groups
  // React.useEffect(() => {
  //   setCategories(
  //     notifications.reduce(
  //       (list, item) => {
  //         const categoryName = item.notification.content_type
  //           ? item.notification.content_type.app_label
  //           : 'No category';

  //         /* eslint-disable no-param-reassign */
  //         if (!(categoryName in list))
  //           list[categoryName] = { total: 0, unread: 0 };
  //         if (!item.read_at) list[categoryName].unread += 1;
  //         list[categoryName].total += 1;
  //         /* eslint-enable no-param-reassign */

  //         return list;
  //       },
  //       { All: { total: notifications.length, unread: unreadCount } }
  //     )
  //   );
  // }, [notifications, unreadCount]);

  // Shows a toast reminding the user that they have unread notifications
  // but only once a day, so it's not too annoying
  function showUnreadReminderToast() {
    if (
      unreadCount > 0 &&
      window.location.pathname.includes('/dashboard') &&
      iconRef.offsetParent
    ) {
      const alreadyShown = getCookie('unreadNotificationsToastShown');
      if (alreadyShown !== '1') {
        setCookie('unreadNotificationsToastShown', 1, 1);
        Toastify({
          text: 'You have unread notifications! Click the bell icon in the navbar to view them.',
          class: 'ph-toast',
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          offset: {
            // Toastify auto adds 15 to these values
            x: 48 - 15,
            y: 34 - 15,
          },
          duration: 5000,
        }).showToast();
      }
    }
  }

  // Renders bubble with unread count
  const renderBubble = () => (
    <div
      className={cx('notifications__bubble', {
        'notifications__bubble--active': unreadCount > 0,
      })}
    />
  );

  // Updates bell icon with unread count red dot
  React.useEffect(() => {
    const root = ReactDOM.createRoot(bubbleRef);
    root.render(renderBubble());

    showUnreadReminderToast();
  }, [unreadCount]);

  // Make the notifications list or nothing
  const renderNotifications = () => {
    if (loading && notifications.length === 0)
      return <h1 className="notifications__empty">Loading notifications...</h1>;
    if (notifications.length > 0)
      return (
        notifications
          // The following commented block of code is related to a filtering function on the
          // notifications panel. After the development, it was decided to leave it out of the
          // notifications MVP. On future call to include this feature, it can be just uncommmented.
          // .filter(
          //   (item) =>
          //     filter === 'All' ||
          //     item.notification.content_type.app_label === filter
          // )
          .map((item) => (
            <NotificationItem
              key={item.pk}
              notification={item.notification}
              message={item.message}
              readAt={item.read_at}
              markAsRead={() => markNotificationsAsRead([item.pk])}
            />
          ))
      );
    return (
      <h1 className="notifications__empty">
        There are no notifications to display
      </h1>
    );
  };

  return (
    <NotificationsDropdown active={active} containerRef={ref}>
      <img
        src={`${staticUrl}/images/svg/dropdown_caret.svg`}
        className="notifications__dropdown-caret"
        alt="caret"
      />
      <div className="notifications__header">
        <h1 className="notifications__title">Notifications</h1>
        <a
          href="#!"
          className={cx('notifications__all-read-link', {
            'notifications__all-read-link--enable': unreadCount > 0,
          })}
          onClick={() => markNotificationsAsRead(getUnreadNotificationsPks())}
        >
          Mark all as read
        </a>
      </div>
      <div className="notifications__tabs">
        {/*
        The following commented block of code is related to a filtering function on the
        notifications panel. After the development, it was decided to leave it out of the
        notifications MVP. On future call to include this feature, it can be just uncommmented.

        {Object.keys(categories).map((group) => (
          <NotificationTab
            key={group}
            groupName={group}
            amount={categories[group].unread}
            selected={group === filter}
            onClickHandle={setFilter}
          />
        ))}
      */}
      </div>
      <div
        ref={scrollableEl}
        className="notifications__list"
        data-testid="notificationsList"
      >
        {renderNotifications()}
      </div>
    </NotificationsDropdown>
  );
}

Notifications.propTypes = {
  iconRef: PropTypes.instanceOf(Element).isRequired,
  bubbleRef: PropTypes.instanceOf(Element).isRequired,
};

const renderEl = document.querySelector('.js-react-notifications');
const iconRef = document.querySelector('#notificationIcon');
const bubbleRef = document.querySelector('#notificationBubble');

const renderElMobile = document.querySelector('.js-react-notifications-mobile');
const iconRefMobile = document.querySelector('#notificationIconMobile');
const bubbleRefMobile = document.querySelector('#notificationBubbleMobile');

let root;
let rootMobile;

if (renderEl && iconRef && bubbleRef) {
  root = ReactDOM.createRoot(renderEl);
}

if (renderElMobile && iconRefMobile && bubbleRefMobile) {
  rootMobile = ReactDOM.createRoot(renderElMobile);
}

if (root) {
  root.render(<Notifications iconRef={iconRef} bubbleRef={bubbleRef} />);
}

if (rootMobile) {
  rootMobile.render(
    <Notifications iconRef={iconRefMobile} bubbleRef={bubbleRefMobile} />
  );
}
