import fetchData from '../../../utils/fetchData';
import updateData from '../../../utils/updateData';

const cachedGets = {};
const cachedPosts = {};

export async function getWagtailImage(
  pk,
  query = {},
  abortController = new AbortController()
) {
  if (cachedGets[`${pk}`]) {
    return cachedGets[`${pk}`];
  }
  const queryParams = new URLSearchParams(query).toString();
  const res = fetchData(
    `/api/images/${pk}/?${queryParams}`,
    abortController.signal
  );
  if (!res) {
    throw new Error('Image Fetch Error');
  }
  return res;
}

export async function createWagtailImage(image) {
  if (cachedPosts[`${image.name}_${image.size}`]) {
    return cachedPosts[`${image.name}_${image.size}`];
  }

  const formData = new FormData();
  formData.append('file', image);
  const request = { formData };
  const abortController = new AbortController();
  const res = await updateData(
    '/api/images/',
    request,
    'POST',
    abortController.signal
  );
  if (!res) {
    throw new Error('An error occurred.');
  }

  cachedPosts[`${image.name}_${image.size}`] = res;
  return res;
}

/**
 * Returns the image URL from an image serialized object
 *
 * @param {object} image
 * @returns {string || null}
 */
export function getImageURL(image) {
  if (image) {
    if (image.rendition_image_url) return image.rendition_image_url;
    if (image.original_image_url) return image.original_image_url;
  }

  return null;
}
