/**
 * The .ph-tooltip is a simple tooltip implemented using only CSS.
 * This file here adds a single behavior for the --only-once modifier.
 */

const tooltipOnlyOnce = document.querySelectorAll('.ph-tooltip--only-once');
const tooltipStartsOpen = document.querySelectorAll('.ph-tooltip--starts-open');

tooltipOnlyOnce.forEach((tooltip) => {
  tooltip.addEventListener('pointerleave', () => {
    setTimeout(() => {
      const hovers = document.querySelectorAll(':hover');
      const currentHover = hovers.length > 0 ? hovers[hovers.length - 1] : null;

      if (currentHover && !tooltip.contains(currentHover))
        tooltip.classList.add('ph-tooltip--only-once-done');
    }, 500);
  });
  tooltip.addEventListener('click', () => {
    tooltip.classList.add('ph-tooltip--only-once-done');
  });
});

tooltipStartsOpen.forEach((tooltip) => {
  tooltip.classList.add('ph-tooltip--inverted');
  tooltip.addEventListener('pointerover', () => {
    tooltip.classList.remove('ph-tooltip--inverted');
  });
});
