import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Source request pitch detail component
 */
function SourceRequestPitchDetailsComponent({ conversation }) {
  const brands_titles = conversation
    .getContentObject()
    .pitched_brands.map((pitched_brand) => pitched_brand.brand.title);
  const products_titles = conversation
    .getContentObject()
    .pitched_products.map((pitched_product) => pitched_product.product.titles);

  const pitch_has_brands = brands_titles.length > 0;
  const pitch_has_products = products_titles.length > 0;

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">Pitch:</h3>
      <span className="conversation-detail-view__details-text">
        {conversation.getContentObject().pitch_text}
      </span>

      {pitch_has_brands && (
        <h3 className="conversation-detail-view__details-heading">Brands:</h3>
      )}
      {pitch_has_brands && (
        <span className="conversation-detail-view__details-text">
          {brands_titles.join(', ')}
        </span>
      )}

      {pitch_has_products && (
        <h3 className="conversation-detail-view__details-heading">Products:</h3>
      )}
      {pitch_has_products && (
        <span className="conversation-detail-view__details-text">
          {products_titles.join(', ')}
        </span>
      )}
    </div>
  );
}

SourceRequestPitchDetailsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SourceRequestPitchDetailsComponent;
