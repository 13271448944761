import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

const LoadingStateContext = createContext();

export const LoadingStateProvider = ({ children }) => {
  const [loadingStates, setLoadingStates] = useState({});

  const setLoadingState = useCallback((namespace, key, state) => {
    setLoadingStates((prevStates) => {
      const newStates = { ...prevStates };
      if (!newStates[namespace]) {
        newStates[namespace] = {};
      }
      newStates[namespace][key] = state;
      return newStates;
    });
  }, []);

  const getGlobalLoadingState = (namespace, defaultValue) => {
    const namespaceStates = loadingStates[namespace] || {};
    if (Object.keys(namespaceStates).length === 0) return defaultValue;
    return Object.values(namespaceStates).some((state) => state);
  };

  return (
    <LoadingStateContext.Provider
      value={{ loadingStates, setLoadingState, getGlobalLoadingState }}
    >
      {children}
    </LoadingStateContext.Provider>
  );
};

export const useLoadingState = (namespace, key, initial = true) => {
  const context = useContext(LoadingStateContext);
  if (!context) {
    throw new Error(
      'useLoadingState must be used within a LoadingStateProvider'
    );
  }

  const { loadingStates, setLoadingState } = context;
  const namespaceStates = loadingStates[namespace] || {};
  const localState =
    namespaceStates[key] !== undefined ? namespaceStates[key] : initial;

  useEffect(() => {
    // Set the initial state in the global state when the component mounts
    setLoadingState(namespace, key, initial);
  }, [namespace, key, initial, setLoadingState]);

  const setLocalState = (state) => {
    setLoadingState(namespace, key, state);
  };

  return [localState, setLocalState];
};

export const useGlobalLoadingState = (namespace) => {
  const context = useContext(LoadingStateContext);
  if (!context) {
    throw new Error(
      'useGlobalLoadingState must be used within a LoadingStateProvider'
    );
  }

  const globalLoadingState = useMemo(
    () => context.getGlobalLoadingState(namespace),
    [context, namespace]
  );

  return globalLoadingState;
};
