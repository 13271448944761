/*
  Gets the AI generated headline for a given contentType and objectPk.
  It makes an API call to the passed in url and returns headline data from OpenAI.

  Parameters:

  contentType - The string value of the Django Model class name
  objectPk - The objects pk that we are generating a headline for
  url - The URL that we are making our request to for the headline
*/
async function getAIHeadlineContentByObject(
  contentType,
  objectPk,
  url,
  sendAi
) {
  // Make a call to the API
  const query = `?content_type=${contentType}&object_pk=${objectPk}&send_ai=${sendAi}`;
  return fetch(url + query, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  .then((response) => {
    if (!response.ok) {
      if (response.status === 429) {
        return response.json();
      }
      throw new Error(response.statusText || response);
    }
    return response.json();
  })
  .then((data) => data.headline)
  .catch((error) => {
    if (window.LogRocket) window.LogRocket.captureException(error.statusText);
    return "";
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const aiHeadlineRefreshButton = document.getElementById(
    'ai-headline-refresh-button'
  );
  const aiCopyToClipboardButton = document.getElementById(
    'ai-copy-to-clipboard-button'
  );
  const aiHeadlineDisplay = document.getElementById('ai-headline-display');

  if (aiHeadlineRefreshButton && aiCopyToClipboardButton && aiHeadlineDisplay) {
    // Initialize all the necessary objects and variables
    const { contentType, objectPk, url, sendAi } = aiHeadlineRefreshButton.dataset;
    const { defaultErrorText } = aiHeadlineDisplay.dataset;
    let { loadingText } = aiHeadlineDisplay.dataset;

    if (!loadingText) {
      loadingText = "Loading";
    }

    const trackData = {
      'object_type': contentType,
      'object_id': objectPk,
    }
    // Show the loading text and add class for animation
    aiHeadlineDisplay.innerHTML = loadingText;
    aiHeadlineDisplay.classList.add("loading-ellipsis");

    // On initial page load, display the first headline they will see
    getAIHeadlineContentByObject(
      contentType,
      objectPk,
      url,
      sendAi,
    )
    .then((headline) => {
      if (headline) {
        // Stop loading and set the headline text
        aiHeadlineDisplay.classList.remove("loading-ellipsis");
        aiHeadlineDisplay.innerHTML = headline;
      }
      else {
        aiHeadlineDisplay.classList.remove("loading-ellipsis");
        aiHeadlineDisplay.innerHTML = defaultErrorText;
      }
    });

    // Generate a new headline if the user clicks refresh
    aiHeadlineRefreshButton.addEventListener('click', () => {
      aiCopyToClipboardButton.setAttribute('data-text', 'Copy to clipboard');

      // Show the loading text and add class for animation
      aiHeadlineDisplay.innerHTML = loadingText;
      aiHeadlineDisplay.classList.add("loading-ellipsis");

      getAIHeadlineContentByObject(
        contentType,
        objectPk,
        url,
        sendAi,
      )
      .then((headline) => {
        if (headline) {
          aiHeadlineDisplay.classList.remove("loading-ellipsis");
          aiHeadlineDisplay.innerHTML = headline;

          // Send 'Headline Refreshed' event to Segment
          if (window.analytics) {
            window.analytics.track(
              'AI Headline Refreshed',
              trackData
            );
          }
        }
        else {
          aiHeadlineDisplay.classList.remove("loading-ellipsis");
          // Not setting the error text here so that the previous content sticks
        }
      });
    });

    // Copy the current headline to the clipboard if the user clicks
    // the Copy to clipboard button and show success
    aiCopyToClipboardButton.addEventListener('click', () => {
      const headlineToCopy = aiHeadlineDisplay.innerText;

      navigator.clipboard.writeText(headlineToCopy);

      aiCopyToClipboardButton.setAttribute('data-text', 'Copied!');

      // Send 'Headline Copied' event to Segment
      if (window.analytics) {
        trackData.headline = headlineToCopy;
        window.analytics.track(
          'AI Headline Copied',
          trackData
        );
      }
    });
  }
});
