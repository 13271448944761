// handles zooming over images for Products Details

if (document.querySelector('.product_details')) {
  (function (window) {
    function define_library() {
      const vanillaZoom = {};
      vanillaZoom.init = function (el) {
        const container = document.querySelector(el);
        if (!container) {
          console.error(
            'No container element. Please make sure you are using the right markup.'
          );
          return;
        }

        const firstSmallImage = container.querySelector('.small-preview');
        const zoomedImage = container.querySelector('.zoomed-image');
        const zoomedImageDownloadButton =
          container.querySelector('.download-button');
        const zoomedImageMobile = document.querySelector(
          '.zoomed-image-mobile'
        );

        // Global variable to store the full size image URL on click and on first load.
        // This allows us to load/render it only on zoom.
        let activeImageFullSize;

        if (!zoomedImage) {
          console.error(
            'No zoomed image element. Please make sure you are using the right markup.'
          );
          return;
        }

        if (!zoomedImageMobile) {
          console.error(
            'No zoomed image element for mobile device. Please make sure you are using the right markup.'
          );
          return;
        }

        // Set the source of the zoomed image.
        if (!firstSmallImage) {
          console.error(
            'No preview images on page. Please make sure you are using the right markup.'
          );
          return;
        }
        zoomedImage.style.backgroundImage = `url(${firstSmallImage.src})`;
        zoomedImageMobile.style.backgroundImage = `url(${firstSmallImage.src})`;
        activeImageFullSize = firstSmallImage.dataset.original;

        // Change the selected image to be zoomed when clicking on the previews.
        container.addEventListener('click', (event) => {
          const elem = event.target;
          if (elem.classList.contains('small-preview')) {
            const imageSrc = elem.src;
            zoomedImage.style.backgroundImage = `url(${imageSrc})`;
            activeImageFullSize = elem.dataset.original;
            // Modify the download link for the zoomed image if download available to user
            if (zoomedImageDownloadButton) {
              zoomedImageDownloadButton.href = elem.dataset.download;
            }
          }
        });

        // Zoom image on mouse enter.
        zoomedImage.addEventListener(
          'mouseenter',
          function (e) {
            this.style.backgroundImage = `url(${activeImageFullSize})`;
            this.style.backgroundSize = '150%';
          },
          false
        );

        // Show different parts of image depending on cursor position.
        zoomedImage.addEventListener(
          'mousemove',
          function (e) {
            // getBoundingClientReact gives us various information about the position of the element.
            const dimentions = this.getBoundingClientRect();

            // Calculate the position of the cursor inside the element (in pixels).
            const x = e.clientX - dimentions.left;
            const y = e.clientY - dimentions.top;

            // Calculate the position of the cursor as a percentage of the total width/height of the element.
            const xpercent = Math.round(100 / (dimentions.width / x));
            const ypercent = Math.round(100 / (dimentions.height / y));

            // Update the background position of the image.
            this.style.backgroundPosition = `${xpercent}% ${ypercent}%`;
          },
          false
        );

        // When leaving the container zoom out the image back to normal size.
        // No need to reset this to the cropped version, as it won't affect performance here
        zoomedImage.addEventListener(
          'mouseleave',
          function (e) {
            this.style.backgroundSize = 'cover';
            this.style.backgroundPosition = 'center';
          },
          false
        );
      };
      return vanillaZoom;
    }

    // Add the vanillaZoom object to global scope.
    if (typeof vanillaZoom === 'undefined') {
      window.vanillaZoom = define_library();
    } else {
      console.log('Library already defined.');
    }
  })(window);

  vanillaZoom.init('#my-gallery');
}
