// Handles the dismiss functionality for the schedule a call banner

document.addEventListener('DOMContentLoaded', () => {
  const exitButtonDesktop = document.querySelector(
    '#scheduleACallDesktopClose'
  );
  const exitButtonMobile = document.querySelector('#scheduleACallMobileClose');
  const container = document.querySelector('#scheduleACallDesktop');
  const mobileContainer = document.querySelector('#scheduleACallMobile');

  if (exitButtonDesktop && container) {
    exitButtonDesktop.addEventListener('click', () => {
      container.style.display = 'none';
    });
  }

  if (exitButtonMobile && mobileContainer) {
    exitButtonMobile.addEventListener('click', () => {
      mobileContainer.style.display = 'none';
    });
  }
});
