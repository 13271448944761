// Handles sending events to segment for any element with the class 'trackable'
// The element must also have a data-event-name attribute
document.addEventListener('DOMContentLoaded', () => {
  if (window.analytics) {
    const trackables = document.querySelectorAll('.trackable');
    trackables.forEach((trackable) => {
      trackable.addEventListener('click', () => {
        const { dataset } = trackable;
        const { eventName } = dataset;

        if (eventName) {
          const eventProperties = {};
          Object.entries(dataset).forEach(([key, value]) => {
            const stringKey = key.toString();
            if (stringKey.startsWith('event') && stringKey !== 'eventName') {
              // Remove the event prefix
              const eventPrefixRemoved = stringKey.replace('event', '');
              const finalCamelCaseKey =
                eventPrefixRemoved.charAt(0).toLowerCase() +
                eventPrefixRemoved.slice(1);
              eventProperties[finalCamelCaseKey] = value;
            }
          });
          window.analytics.track(eventName, eventProperties);
        }
      });
    });
  }
});
