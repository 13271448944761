const page_drawers = document.getElementsByClassName('drawer__cover');
for (let i = 0; i < page_drawers.length; i++) {
  page_drawers[i].addEventListener('click', toggleDrawer);
}

function toggleDrawer(event) {
  const targetString = event.target.dataset.target;

  if (targetString.startsWith('drawer--')) {
    toggleClassDrawer(targetString);
    return;
  }

  const target = document.getElementById(event.target.dataset.target);

  const open = target.classList.contains('open');

  if (open) {
    target.classList.remove('open');
    target.classList.add('closed');
  } else {
    target.classList.remove('closed');
    target.classList.add('open');
  }
}

function toggleClassDrawer(target) {
  const targets = document.getElementsByClassName(target);

  const open = targets[0].classList.contains('open');

  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];

    if (open) {
      target.classList.remove('open');
      target.classList.add('closed');
    } else {
      target.classList.remove('closed');
      target.classList.add('open');
    }
  }
}
