import SampleRequestAddressComponent from '../../ConversationDetailView/DetailsComponents/SampleRequestAddressComponent';
import SampleRequestNotesComponent from '../../ConversationDetailView/DetailsComponents/SampleRequestNotesComponent';

import SampleRequestProductsComponent from '../../ConversationDetailView/DetailsComponents/SampleRequestProductsComponent';

import SampleRequestNeededByComponent from '../../ConversationDetailView/DetailsComponents/SampleRequestNeededByComponent';
import SampleRequestReasonComponent from '../../ConversationDetailView/DetailsComponents/SampleRequestReasonComponent';
import BrandRelatedConversation from './BrandRelatedConversation';
import { getImageURL } from '../../../utils/wagtailImageUtils';

/**
 * Methd for determining detail text for a Sample Reques conversation
 * @param {*} contentObject sample request
 * @param {*} userIsRequester bool determining if the current chat user is the one requesting access or not
 * @returns string
 */
function determineHeaderDetailText(contentObject, userIsRequester) {
  if (userIsRequester) {
    return '';
  }

  return contentObject.outlets.map((outlet) => outlet.name).join(', ');
}

/**
 * Class that implements custom header detail text and icon determination for Sample Request conversations.
 */
export default class SampleRequestConversation extends BrandRelatedConversation {
  constructor(conversationObject) {
    super(conversationObject);

    const userIsRequester =
      this.getIdentity() &&
      this.getContentObject().requested_by ===
        this.getIdentity().getUser().getPk();

    this.typeText = 'Sample Request';
    this.headerDetailText = determineHeaderDetailText(
      this.getContentObject(),
      userIsRequester
    );

    if (
      !userIsRequester &&
      this.getOtherMembersExcludingStaff()[0].getUser().getIfUserIsStaff()
    ) {
      this.headerDetailText = 'Press Hook Staff on behalf of a Media Member';
    }

    const brandIcon =
      this.getContentObject().brand.logo &&
      getImageURL(this.getContentObject().brand.logo);

    this.icon =
      userIsRequester && brandIcon
        ? brandIcon
        : this.getOtherMembersExcludingStaff()[0].getUser().getProfileImage();

    this.detailSections = {
      'Contact Information': SampleRequestAddressComponent,
      Notes: [SampleRequestNotesComponent, true],
      Products: SampleRequestProductsComponent,
      'Request Reason': SampleRequestReasonComponent,
      'Needed By': SampleRequestNeededByComponent,
    };
    this.relatedLink = null;
    this.setupWelcomeMessageEventHandler();
    this.setCurrentSampleRequestWelcomeMessage();
  }
  /**
     * Returns related link for conversation
     * @returns
     */
  getRelatedLink(member) {
    const userProfileLink = member.user.getProfileURL()
      ? [
          { title: 'View Sample Requests', href: "/sample-requests/" },
          {
            title: 'View Profile',
            href: member.user.getProfileURL(),
          }
        ]
      : { title: 'View Sample Requests', href: "/sample-requests/" };
    return this.relatedLink || userProfileLink;
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getActiveMembers() {
    return this.getMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getOtherActiveMembers() {
    return this.getOtherMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Setup the event listeners required to handle the dynamic welcome message
   */
  setupWelcomeMessageEventHandler() {
    this.on(
      'member_list_updated',
      this.setCurrentSampleRequestWelcomeMessage.bind(this)
    );
  }

  /**
   * Set the welcome message based on current user invite state.
   * @returns void
   */
  setCurrentSampleRequestWelcomeMessage() {
    const otherMember = this.getOtherMembersExcludingStaff()[0];
    if (otherMember.getActiveState() === 'PEN') {
      this.setWelcomeMessage(
        `This is the beginning of your conversation with ${
          otherMember.getUser().getPk() === this.getContentObject().brand.owner
            ? this.getContentObject().brand.title
            : otherMember.getUser().getFullName()
        }`,
        'The other user will be invited after you send the first message'
      );
      return;
    }
    this.setWelcomeMessage(
      `This is the beginning of your conversation with  ${
        this.getContentObject().brand.title
      }`,
      "You'll be notified for every new message in this conversation."
    );
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns
   */
  getCustomFlavorTextForMember(member) {
    return this.getContentObject().requested_by !== member.getUser().getPk()
      ? `Brand Rep @ ${this.getContentObject().brand.title}`
      : 'Media Member';
  }

  /**
   * Returns the role text to be shown underneath a users name in the messaging area
   * @param {*} member
   * @returns string
   */
  getCustomRoleTextForMember(member) {
    return this.getContentObject().requested_by !== member.getUser().getPk()
      ? this.getContentObject().brand.title
      : '';
  }
}
