import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering brand contact request details for Media
 */
function BrandContactRequestDetailsComponentMedia({ conversation }) {
  /*
  Date comes down as YYYY-MM-DD
  Chrome will parse as UTC, Firefox parses local.
  Add T12:00:00Z to force it to be recognized as noon UTC.
  */
  const deadlineDate = new Date(
    `${conversation.getContentObject().deadline_date}T12:00:00Z`
  );

  // Strips the brand's website down to just the domain so we
  // can show it in a pleasing fashion
  const url = conversation.getContentObject().brand.website;
  let domain = new URL(url);
  let prettyDomain = domain.hostname.replace('www.', '');

  // Neutralize time zone adjustment when converted to string, since the original date doesn't come with one
  deadlineDate.setTime(
    deadlineDate.getTime() + deadlineDate.getTimezoneOffset() * 60 * 1000
  );

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">HQ:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-hq"
      >
        {conversation.getContentObject().brand.headquarters}
      </span>
      <h3 className="conversation-detail-view__details-heading">Website:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-website"
      >
        <a href={conversation.getContentObject().brand.website} target="_blank">
          {prettyDomain}
        </a>
      </span>
    </div>
  );
}

BrandContactRequestDetailsComponentMedia.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default BrandContactRequestDetailsComponentMedia;
