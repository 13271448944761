import React from 'react';
import ReactDOM from 'react-dom/client';
import AddExpertModal from './addExpertModal';

/**
 * Injects the add expert modal into its container
 */
export default function injectAddExpertModalComponent() {
  const addExpertModalContainer = document.getElementById(
    'addExpertModalContainer'
  );

  if (addExpertModalContainer) {
    const rootContainerForAddBrandModal = ReactDOM.createRoot(
      addExpertModalContainer
    );
    rootContainerForAddBrandModal.render(<AddExpertModal />);
  }
}

document.addEventListener('DOMContentLoaded', injectAddExpertModalComponent);
