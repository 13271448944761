import PitchDetailsComponent from '../../ConversationDetailView/DetailsComponents/PitchDetailsComponent';
import PitchSelectedMedia from '../DataTypes/PitchSelectedMedia';

import BrandRelatedConversation from './BrandRelatedConversation';

/**
 * Class that implements custom header detail text and icon determination for Pitch conversations.
 */
export default class PitchConversation extends BrandRelatedConversation {
  constructor(conversationObject) {
    super(conversationObject);

    this.typeText = 'Pitch';

    this.content_object = new PitchSelectedMedia(
      conversationObject.content_object
    );

    this.headerDetailText = this.getContentObject().getTitle();

    this.icon = this.getOtherMembersExcludingStaff()[0]
      .getUser()
      .getProfileImage();

    this.relatedLink = {
      title: 'View Pitch',
      href: this.getContentObject().getURL(),
    };

    this.detailSections = {
      'Pitch Details': PitchDetailsComponent,
    };

    this.setupWelcomeMessageEventHandler();
    this.setCurrentWelcomeMessage();
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getActiveMembers() {
    return this.getMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Get all users who are currently in some level of activity
   * Modified to include Pending members
   * @returns array of ConversationMember
   */
  getOtherActiveMembers() {
    return this.getOtherMembers().filter((member) =>
      ['PEN', 'INV', 'ACC'].includes(member.getActiveState())
    );
  }

  /**
   * Setup the event listeners required to handle the dynamic welcome message
   */
  setupWelcomeMessageEventHandler() {
    this.on('member_list_updated', this.setCurrentWelcomeMessage.bind(this));
  }

  /**
   * Set the welcome message based on current user invite state.
   * @returns void
   */
  setCurrentWelcomeMessage() {
    const otherMember = this.getOtherMembersExcludingStaff()[0];
    if (otherMember.getActiveState() === 'PEN') {
      this.setWelcomeMessage(
        `This is the beginning of your conversation with ${otherMember
          .getUser()
          .getFullName()}`,
        'The other user will be invited after you send the first message'
      );
      return;
    }
    this.setWelcomeMessage(
      `This is the beginning of your conversation with ${otherMember
        .getUser()
        .getFullName()}`,
      "You'll be notified for every new message in this conversation."
    );
  }

  /**
   * Returns the flavor text to be shown underneath the user's name on the sidebar.
   * @param {*} member
   * @returns
   */
  getCustomFlavorTextForMember(member) {
    let brandUserSubtitle = '';
    if (this.getContentObject().pitchedBrands.length === 1) {
      const { brand } = this.getContentObject().pitchedBrands[0];
      brandUserSubtitle = `Brand Rep @ ${brand.title}`;
    }

    return member.getUser().getIfUserIsMediaRep()
      ? 'Media Member'
      : brandUserSubtitle;
  }
}
