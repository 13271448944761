import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering Media Profile Social Media Info
 */
function MediaContactRequestMediaSocialInfo({ conversation }) {
  const media_profile =
    conversation.getContentObject().media_user.media_profile;

  function getPrettyUrl(url) {
    const domain = new URL(url);
    return domain.hostname.replace('www.', '');
  }

  return (
    <div>
      {media_profile.website && (
        <div className="conversation-detail-view__detail-section-sub-component">
          <h3 className="conversation-detail-view__details-heading">
            Website:
          </h3>
          <span
            className="conversation-detail-view__details-text"
            data-testid="media-social-website"
          >
            <a href={media_profile.website} target="_blank">
              {getPrettyUrl(media_profile.website)}
            </a>
          </span>
        </div>
      )}
      {media_profile.linked_in_url && (
        <div className="conversation-detail-view__detail-section-sub-component">
          <h3 className="conversation-detail-view__details-heading">
            LinkedIn:
          </h3>
          <span
            className="conversation-detail-view__details-text"
            data-testid="media-social-linked-in"
          >
            <a href={media_profile.linked_in_url} target="_blank">
              {getPrettyUrl(media_profile.linked_in_url)}
            </a>
          </span>
        </div>
      )}
      {media_profile.instagram_url && (
        <div className="conversation-detail-view__detail-section-sub-component">
          <h3 className="conversation-detail-view__details-heading">
            Instagram:
          </h3>
          <span
            className="conversation-detail-view__details-text"
            data-testid="media-social-instagram"
          >
            <a href={media_profile.instagram_url} target="_blank">
              {getPrettyUrl(media_profile.instagram_url)}
            </a>
          </span>
        </div>
      )}
      {media_profile.twitter_url && (
        <div className="conversation-detail-view__detail-section-sub-component">
          <h3 className="conversation-detail-view__details-heading">
            Twitter:
          </h3>
          <span
            className="conversation-detail-view__details-text"
            data-testid="media-social-twitter"
          >
            <a href={media_profile.twitter_url} target="_blank">
              {getPrettyUrl(media_profile.twitter_url)}
            </a>
          </span>
        </div>
      )}
    </div>
  );
}

MediaContactRequestMediaSocialInfo.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default MediaContactRequestMediaSocialInfo;
