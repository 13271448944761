/**
 * Method for getting a setting set in the #pageSettings element
 *
 * #pageSettings should be any html tag with display: none; and id="pageSettings"
 *
 * The settings should be in that elements dataset.
 * @param {*} key key to find, in camelCase (setting-name becomes settingName)
 * @param {*} defaultValue value to return if key not found
 * @returns any
 */
export default function getPageSetting(key, defaultValue = null) {
  const settings = document.querySelector('#pageSettings');

  if (!settings) {
    return defaultValue;
  }

  return settings.dataset[key] || defaultValue;
}
