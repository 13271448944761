import React from 'react';
import PropTypes from 'prop-types';
import CollectionItem from '../../DataModels/CollectionItem';
import AddToCollectionButton from '../../AddToCollection/AddToCollectionButton';
import { Box, Card, CardContent } from '@mui/material';

/**
 * Component for the collection item card
 * @param {CollectionItem} collectionItem - CollectionItem object
 * @returns {JSX.Element}
 */
function CollectionItemCard({ collectionItem }) {
  const imageContainerRef = React.useRef(null);
  const [isResized, setIsResized] = React.useState(false);
  const [isItemLoading, setIsItemLoading] = React.useState(true);

  /**
   * Resizing each item card based on its cover image size,
   * so the masonry layout applies correctly
   */
  React.useEffect(() => {
    if (!isResized) {
      const coverImgData = collectionItem.getCoverImage();
      if (coverImgData.url) {
        const width = coverImgData.width;
        const height = coverImgData.height ? coverImgData.height : 235;
        const clientWidth = imageContainerRef.current.clientWidth;
        const newHeight = Math.ceil((height * clientWidth) / width);

        imageContainerRef.current.style.backgroundImage = `url(${coverImgData.url})`;
        imageContainerRef.current.style.backgroundSize = 'cover';
        imageContainerRef.current.style.backgroundPosition = 'center';

        if (newHeight < 235) {
          imageContainerRef.current.style.height = '235px';
        } else if (newHeight > 418) {
          imageContainerRef.current.style.height = '418px';
        } else {
          imageContainerRef.current.style.height = `${newHeight}px`;
        }
      } else {
        // No image provided
        imageContainerRef.current.classList.add(
          'collection-item-card__image--no-image'
        );
      }
      setIsResized(true);
      setIsItemLoading(false);
    }
  }, [isResized, collectionItem]);

  const [elevation, setElevation] = React.useState(0);

  const cardStyle = React.useMemo(() => {
    const style = {
      position: 'relative',
      overflow: 'visible',
    };

    if (isItemLoading) {
      const animation = {
        animation: 'pulse 0.5s ease-in-out infinite',
        '@keyframes pulse': {
          '0%': { opacity: 0.1 },
          '50%': { opacity: 0.5 },
          '100%': { opacity: 0.1 },
        },
      };

      return { ...style, ...animation };
    }

    return style;
  }, [isItemLoading]);

  return (
    <div
      className="collection-item-card__container"
      data-testid="collectionItemCard"
    >
      <div className="js-content">
        <Card
          variant="no-paper"
          sx={cardStyle}
          onMouseOver={() => setElevation(5)}
          onMouseOut={() => setElevation(0)}
        >
          <Box
            component="a"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              overflow: 'visible',
            }}
            href={collectionItem.getURL()}
          >
            <CardContent sx={{ padding: 0, overflow: 'visible' }}>
              <Card
                sx={{
                  position: 'relative',
                  borderRadius: '8px',
                }}
                ref={imageContainerRef}
                elevation={elevation}
              />

              <Box className="collection-item-card__footer">
                <Box className="collection-item-card__icon-container">
                  {collectionItem.getIconImage() && (
                    <img
                      src={collectionItem.getIconImage()}
                      className="collection-item-card__icon"
                    />
                  )}
                </Box>
                <Box className="collection-item-card__titles">
                  <div className="collection-item-card__title">
                    {collectionItem.getTitle()}
                  </div>
                  <div className="collection-item-card__subtitle">
                    {collectionItem.getSubtitle()}
                  </div>
                </Box>
              </Box>
            </CardContent>
          </Box>
          <AddToCollectionButton
            objectPk={collectionItem.getRelatedObjectPk()}
            objectType={collectionItem.getItemType()}
            isActive={true}
            style={{ top: '16px', right: '12px' }}
          />
        </Card>
      </div>
    </div>
  );
}

CollectionItemCard.propTypes = {
  collectionItem: PropTypes.instanceOf(CollectionItem).isRequired,
};

export default CollectionItemCard;
