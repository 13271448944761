import React from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';

function PaymentMethodForm({
  selectedPaymentMethodRef,
  setHasValidationError,
  newPaymentMethodFormRef,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = React.useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    elements
      .submit()
      .then(() => {
        stripe
          .createPaymentMethod({
            elements,
          })
          .then((res) => {
            selectedPaymentMethodRef.current = res.paymentMethod;
            setError(null);
            setHasValidationError(false);
          })
          .catch((error) => {
            selectedPaymentMethodRef.current = {};
            setError(error);
            setHasValidationError(true);
            throw Error(error);
          });
      })
      .catch((error) => {
        setError(error);
        selectedPaymentMethodRef.current = {};
        setHasValidationError(true);
        throw Error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit} ref={newPaymentMethodFormRef}>
      {error && (
        <p className="registration-info-input-element__error-text">{error}</p>
      )}
      <div className="registration-info-row registration-info-row">
        <div className="registration-info-row__column">
          <PaymentElement
            paymentMethodCreation={'manual'}
            onBlur={() =>
              newPaymentMethodFormRef.current.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
              )
            }
          />
        </div>
      </div>
    </form>
  );
}

export default PaymentMethodForm;
