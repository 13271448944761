const boldString = (str, substr) => {
  if (substr) {
    try {
      const strRegExp = new RegExp(substr, 'i');
      return str.replace(
        strRegExp,
        `<b>${substr.charAt(0).toUpperCase() + substr.slice(1)}</b>`
      );
    } catch (e) {
      return substr;
    }
  } else {
    return str;
  }
};

export default boldString;
