import React from 'react';
import ReactDOM from 'react-dom/client';
import Toastify from 'toastify-js';
import MessageToast from './MessageToast';
import useUserIdentity from '../PHChatApi/ReactHooks/useUserIdentity';
import ApiConnection from '../PHChatApi/PHChatApi';

/*
  Renderless react component for messaging toast firing.
*/
function MessageNotificationToasts() {
  const identity = useUserIdentity();

  // Displays a toast notification
  const showMessageToast = ({ message, conversation }) => {
    if (!identity.getPk) {
      return;
    }

    // Ignore messages from self
    if (message.getSender().getUser().getIsSelf()) {
      return;
    }

    const notificationNode = document.createElement('div');
    const root = ReactDOM.createRoot(notificationNode);

    root.render(
      <MessageToast
        createdAtString={message.getSendTime()}
        image={conversation.getIcon()}
        headline={conversation.getTitle()}
        message={message.getContent()}
      />
    );

    Toastify({
      node: notificationNode,
      className: 'notifications__toast',
      destination: `/messaging/?conversation=${conversation.getUUID()}`,
      newWindow: false,
      close: true,
      gravity: 'bottom',
      position: 'left',
      stopOnFocus: true,
      style: {},
      offset: {
        // Toastify auto adds 15 to these values
        x: 48 - 15,
        y: 34 - 15,
      },
      duration: 5000,
    }).showToast();
  };

  // Setup listener
  React.useEffect(() => {
    ApiConnection.on('new_message_toast', showMessageToast);

    return () => {
      ApiConnection.off('new_message_toast', showMessageToast);
    };
  }, [identity]);

  return null;
}

export default MessageNotificationToasts;
