// - handles mobile nav menu
const navToggle = document.getElementById('navigationMenuToggle');

// Toggle open and close nav styles on click
if (navToggle) {
  navToggle.addEventListener('click', (e) => {
    const navUL = document.getElementById('navigationMenuMobile');
    const navDash = document.querySelector('.navigation__dash');

    e.preventDefault();

    // Hamburger to X tranformation
    navDash.classList.toggle('navigation__dash--active');

    // Shows the menu
    navUL.classList.toggle('navigation__menu--active');
  });
}
