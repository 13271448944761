import EventsClass from '../../../utils/Socketry/EventsClass';

/**
 * Class for firing errors around the application.
 */
class ErrorBanners extends EventsClass {
  /**
   * Send an error to a category
   * @param {*} category category to send
   * @param {*} error message
   */
  fireError(category, error) {
    this.fireEvent(category, error);
  }
}

// One instance for the whole app
const ErrorRouter = new ErrorBanners();

export default ErrorRouter;
