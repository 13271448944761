import fetchData from './fetchData';
import logError from './logError';

// This will store all requested flags promises.
const flags = {};

/**
 * Fetches the status of the given waffle flag.
 * It will save any return value to avoid multiple requests.
 * @param {string} flagName - The name of the waffle flag.
 * @param {AbortSignal} [signal=null] - Optional AbortSignal to cancel the request.
 * @return {Promise<boolean>} - A promise that resolves to the flag status.
 */
const checkWaffleFlag = async (flagName, signal = null) => {
  if (!flags[flagName]) {
    flags[flagName] = fetchData(`/api/flags/${flagName}/`, signal)
      .then((response) => response.flag_status)
      .catch((error) => {
        logError(`Error fetching flag status for ${flagName}: ${error}`);
        throw error;
      });
  }

  return flags[flagName];
};

export default checkWaffleFlag;
