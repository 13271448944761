import React from 'react';
import PropTypes from 'prop-types';
import fromNow from '../utils/fromNow';

/**
 * Notification Item component
 * Each notification item on notifications list
 */

function NotificationToast({ notification, message }) {
  const createdAt = new Date(notification.created_at);

  const getProfileImage = () => {
    const defaultProfile = `${staticUrl}/images/svg/default_profile_thin.svg`;
    const PHLogo = `${staticUrl}/images/svg/company_logo.svg`;

    if (!notification.actor || notification.actor.is_staff) return PHLogo;

    return notification.actor.profile_image || defaultProfile;
  };

  return (
    <div className="notifications__toast-container">
      <h1 className="notifications__toast-title">New Notification</h1>
      <div className="notifications__toast-item">
        <div>
          <img
            className="user__avatar"
            src={getProfileImage()}
            alt="user avatar"
          />
        </div>
        <div className="notifications__item-content">
          <p className="notifications__item-headline">
            {notification.headline}
          </p>
          <p
            className="notifications__item-message"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: message ? message : notification.message,
            }}
          />
          <p className="notifications__item-created-at">{fromNow(createdAt)}</p>
        </div>
      </div>
    </div>
  );
}

NotificationToast.propTypes = {
  notification: PropTypes.shape({
    pk: PropTypes.number,
    content_type: PropTypes.number,
    object_id: PropTypes.number,
    actor: PropTypes.shape({
      profile_image: PropTypes.string,
      is_staff: PropTypes.bool,
    }),
    action: PropTypes.string,
    headline: PropTypes.string,
    message: PropTypes.string,
    created_at: PropTypes.string,
    metadata: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  message: PropTypes.string,
};

export default NotificationToast;
