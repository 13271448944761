// Handles mobile menu collapsing logic on Brands and Account apps
const currentPage = document.getElementById('currentPage');
if (currentPage) {
  const mobileChevron = document.querySelector('.mobile-chevron');
  currentPage.addEventListener('click', () => {
    currentPage.classList.toggle('active');
    const content = currentPage.nextElementSibling;
    if (content.style && content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = `${content.scrollHeight}px`;
    }

    // handles rotating the chevron down when the menu opens
    if (currentPage.classList.contains('active')) {
      mobileChevron.style.transform = 'rotate(90deg)';
    } else {
      mobileChevron.style.transform = 'none';
    }
  });
}
