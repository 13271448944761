/**
 * Returns a value from a current URL's query param
 *
 * @param {string} key
 * @returns {string || null}
 */
export default function getValueFromQueryParams(key) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}
