import AdminIntervenableConversation from './AdminIntervenableConversation';

/**
 * A conversation that involves a brand
 * Objectively, the conversation has a ContentType object with a brand defined
 * Sets behaviors for displaying the brand name
 */
export default class BrandRelatedConversation extends AdminIntervenableConversation {
  /**
   * Get an array of members excluding any staff
   * Has a special condition to still include them if they are the requester/brand owner for the content object
   * Only if one exists, if there is no requested_by or brand field, it will just be ignored
   * @returns array of ConversationMembers
   */
  getMembersExcludingStaff() {
    return super.getMembersExcludingStaff().filter((member) => {
      if (
        member.getUser().get_pk === this.getContentObject()?.requested_by ||
        member.getUser().get_pk === this.getContentObject()?.requested_by?.pk || // in case value is expanded
        member.getUser().get_pk === this.getContentObject()?.brand?.owner ||
        member.getUser().get_pk === this.getContentObject()?.brand?.owner?.pk // in case value is expanded
      ) {
        return true;
      }

      return !member.getUser().getIfUserIsStaff();
    });
  }

  /**
   * Get an array of members excluding staff and self
   * Has a special condition to still include them if they are the requester/brand owner for the content object
   * Only if one exists, if there is no requested_by or brand field, it will just be ignored
   * @returns array of ConversationMembers
   */
  getOtherMembersExcludingStaff() {
    return this.getMembersExcludingStaff().filter(
      (member) => !member.getUser().getIsSelf()
    );
  }
}
