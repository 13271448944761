import React from 'react';
import Collection from '../DataModels/Collection';
import LoadingCircle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingCircle';
import logError from '../../../../../../../config/static/js/utils/logError';

function AddToCollectionSelectRow({
  collection,
  addToCollectionHandler,
  removeFromCollectionHandler,
}) {
  const [selected, setSelected] = React.useState(
    collection.getFolderedItemPk() !== null
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [scopeCollection, setScopeCollection] = React.useState(collection);

  const handleRowClick = () => {
    // preventing a double-click
    if (!isLoading) {
      setIsLoading(true);
      if (!selected) {
        addToCollectionHandler(
          scopeCollection.getSlug(),
          scopeCollection.getTitle()
        ).then((res) => {
          setIsLoading(false);
          setSelected(true);
          if (res?.pk) {
            setScopeCollection(
              new Collection({ ...collection, foldered_item_pk: res.pk })
            );
          } else {
            logError(res);
          }
        });
      } else {
        removeFromCollectionHandler(
          scopeCollection.getSlug(),
          scopeCollection.getTitle(),
          scopeCollection.getFolderedItemPk()
        ).then(() => {
          setIsLoading(false);
          setSelected(false);
        });
      }
    }
  };

  return (
    <div
      className="collection-select-row"
      onClick={handleRowClick}
      data-testid="collectionSelectRow"
    >
      <div className="collection-select-row__name">
        {scopeCollection.getTitle()}
      </div>
      {isLoading ? (
        <div className="collection-select-row__icon collection-select-row__icon--loading">
          <LoadingCircle diameter={14} />
        </div>
      ) : (
        <div className="collection-select-row__icon">
          {selected && (
            <div
              className="collection-select-row__icon-selected"
              data-testid="collectionSelectRowSelected"
            ></div>
          )}
        </div>
      )}
    </div>
  );
}

export default AddToCollectionSelectRow;
