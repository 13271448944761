import React, { forwardRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  getPaymentMethods,
  getSetupIntentClientSecret,
} from '../../../../../../accounts/static/accounts/js/utils';
import { ThemeProvider } from '@mui/material/styles';
import { PressHookTheme } from '../../../../../../../config/static/js/theme/mui-theme';
import PaymentMethodForm from './PaymentMethodForm';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import PaymentMethodSelector from './PaymentMethodSelector';

const StripeElementsContainer = forwardRef(
  (
    {
      selectedPaymentMethodId = null,
      setSelectedPaymentMethodId = () => {},
      getExistingPaymentMethods = true,
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [methods, setMethods] = React.useState([]);
    const [clientSecret, setClientSecret] = React.useState('');
    const [showExistingPaymentMethods, setShowExistingPaymentMethods] =
      React.useState(false);

    // Reading the stripe api key from the dataset here avoids reading it before the dataset is available
    const stripeDataset = document.querySelector(
      '[data-stripe-api-key]'
    ).dataset;
    const apiKey = stripeDataset.stripeApiKey;
    // Makes sure the stripePromise is only loaded once
    const [stripePromise, _] = React.useState(loadStripe(apiKey));

    /**
     * Stripe elements styling
     */
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#d2281a',
        colorBackground: '#ffffff',
        colorText: '#262121',
        fontFamily:
          '"Chiswick Grotesque Web", "Helvetica Neue", Arial, sans-serif',
        fontWeightBold: '600',
        fontWeightLight: '400',
        spacingUnit: '2px',
        borderRadius: '4px',
      },
      rules: {
        '.Label': {
          fontWeight: 'var(--fontWeightBold)',
          fontSize: '12px',
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          color: 'var(--colorText)',
          marginBottom: '8px',
          marginTop: '24px',
        },
        '.Input': {
          color: 'var(--colorText)',
          fontSize: '16px',
          fontWeight: 'var(--fontWeightLight)',
          lineHeight: '2',
          borderColor: 'var(--colorText)',
          borderRadius: 'var(--borderRadius)',
        },
        '.Input--invalid': {
          color: '#D2281A',
        },
      },
    };

    /**
     * First render hook
     */
    React.useEffect(() => {
      const fetchData = async () => {
        try {
          // Let's create stripe's setup intent first to get the client secret
          const secret = await getSetupIntentClientSecret();
          setClientSecret(secret);

          // Then we can get the payment methods
          if (getExistingPaymentMethods) {
            const methods = await getPaymentMethods();
            if (methods.length > 0) {
              setMethods(methods);
              setSelectedPaymentMethodId(methods[0].id);
              setShowExistingPaymentMethods(true);
            }
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }, []);

    const renderPaymentSelectorOrForm = React.useCallback(
      () =>
        showExistingPaymentMethods ? (
          <PaymentMethodSelector
            methods={methods}
            selectedPaymentMethodId={selectedPaymentMethodId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            showPaymentForm={() => setShowExistingPaymentMethods(false)}
          />
        ) : (
          <PaymentMethodForm
            ref={ref}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            showPaymentSelector={() => setShowExistingPaymentMethods(true)}
            isThereExistingPaymentMethods={methods.length > 0}
          />
        ),
      [methods, selectedPaymentMethodId, showExistingPaymentMethods]
    );

    return (
      <ThemeProvider theme={PressHookTheme}>
        {isLoading ? (
          <div className="mulit-step-loader-container">
            <LoadingRectangle height={90} />
          </div>
        ) : (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            {renderPaymentSelectorOrForm()}
          </Elements>
        )}
      </ThemeProvider>
    );
  }
);

export default StripeElementsContainer;
