import React from 'react';
import PropTypes from 'prop-types';
import fromNow from '../../utils/fromNow';

/**
 * Notification Item component
 * Each notification item on notifications list
 */

function MessageToast({ createdAtString, image, headline, message }) {
  const createdAt = new Date(createdAtString);
  const maxLength = 120;
  let content = message.substring(0, maxLength).trim();
  content = message.length > maxLength ? `${content}...` : content;

  return (
    <div
      className="notifications__toast-container"
      data-testid="inbox-message-toast"
    >
      <h1 className="notifications__toast-title">New Message</h1>
      <div className="notifications__toast-item">
        <div>
          <img
            className="user__avatar"
            src={image || `${staticUrl}/images/svg/default_profile_thin.svg`}
            alt="user avatar"
          />
        </div>
        <div className="notifications__item-content">
          <p className="notifications__item-headline">{headline}</p>
          <p
            className="notifications__item-message"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <p className="notifications__item-created-at">{fromNow(createdAt)}</p>
        </div>
      </div>
    </div>
  );
}

MessageToast.defaultProps = {
  image: null,
};

MessageToast.propTypes = {
  createdAtString: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  image: PropTypes.string,
  headline: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default MessageToast;
