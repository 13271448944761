import Conversation from './Conversation';
import Pitch from './Pitch';

/**
 * Object representation of a Pitch Selected Media
 */
export default class PitchSelectedMedia extends Pitch {
  constructor(pitchSelectedMedia) {
    super(pitchSelectedMedia.pitch);

    this.readAt = pitchSelectedMedia.read_at;
    this.createdAt = pitchSelectedMedia.created_at;
    this.status = pitchSelectedMedia.status;
    this.pitchHtml = pitchSelectedMedia.pitch_html;
    this.introText = pitchSelectedMedia.intro_text;

    this.conversation = pitchSelectedMedia.conversation
      ? new Conversation(pitchSelectedMedia.conversation)
      : null;
  }

  /**
   * Get the date a pitch was created
   * @returns Date
   */
  getCreatedAt() {
    return new Date(this.createdAt);
  }

  /**
   * Get the date a pich was read
   * @returns Date | null
   */
  getReadAt() {
    return this.readAt ? new Date(this.readAt) : null;
  }

  /**
   * Set the pitch status
   * @param status
   */
  setStatus(status) {
    this.status = status;
  }

  /**
   * Get the selected media status
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get the HTML representation of the pitch
   */
  getHTML() {
    return this.pitchHtml === null ? '' : this.introText + this.pitchHtml;
  }

  /**
   * Get the intro text of the pitch
   */
  getIntroText() {
    return this.introText;
  }

  /**
   * Get the conversation
   */
  getConversation() {
    return this.conversation;
  }
}
