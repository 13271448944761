const COLLECTION_ITEM_TYPES = [
  'brand',
  'product',
  'expert',
  'media_profile',
  'brand_guide',
  'product_guide',
];

export default class CollectionItem {
  constructor(data) {
    this.pk = data.pk;
    this.folderPk = data.folder;
    this.objectId = data.object_id;
    this.itemType = data.content_type_name;
    this.itemDisplayType = data.item_display_type;
    this.url = data.item_url;
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.coverImage = {
      url: data.cover_image_url,
      width: data.cover_image_width,
      height: data.cover_image_height,
    };
    this.iconImage = Object.hasOwn(data, 'icon_image_url')
      ? data.icon_image_url
      : null;
  }

  getPk() {
    return this.pk;
  }

  getFolderPk() {
    return this.folderPk;
  }

  getItemType() {
    return this.itemType;
  }

  getItemDisplayType() {
    return this.itemDisplayType;
  }

  getURL() {
    return this.url;
  }

  getTitle() {
    return this.title;
  }

  getSubtitle() {
    return this.subtitle;
  }

  getCoverImage() {
    return this.coverImage;
  }

  getIconImage() {
    return this.iconImage;
  }

  getRelatedObjectPk() {
    return this.objectId;
  }
}
