import React from 'react';
import logError from '../../../../../../../config/static/js/utils/logError';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function SlotSelectionForm({ objectType, objectId, freeSlots, setSection }) {
  const [slot, setSlot] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [slotError, setSlotError] = React.useState(null);

  /**
   * Handles the brand creation data
   */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSlotError(null);

    const subscription_slot = slot == 'Select a subscription...' ? null : slot;

    if (subscription_slot === null) {
      setSlotError('Please select a subscription');
      setIsLoading(false);
      return;
    }

    const data = {
      object_type: objectType,
      object_id: objectId,
    };

    fetch(`/api/subscription-slots/${subscription_slot}/`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(res);
        }
        return res.json();
      })
      .then((data) => {
        setSection('CheckoutSuccess');
      })
      .catch((data) => {
        // General error handling
        if (data.status === 500) {
          setSlotError(`There was an internal error selecting the subscription.
                        Try again. If the problem persists, please contact support.`);
          logError(data);
        } else {
          return data.json();
        }
      })
      .then((error_data) => {
        // Handleable errors
        if (error_data) {
          let handled = false;
          if (error_data.brands) {
            setSlotError(error_data.brands);
            handled = true;
          }
          if (error_data.experts) {
            setSlotError(error_data.experts);
            handled = true;
          }
          if (!handled) {
            setSlotError(`There was an internal error selecting the subscription.
                          Try again. If the problem persists, please contact support.`);
            logError(error_data);
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    freeSlots.length > 0 && (
      <div className="add-brand-modal__content">
        <h2 className="add-brand-modal__title">
          Choose an existing subscription
        </h2>
        <form onSubmit={handleFormSubmit}>
          <div className="add-brand-modal__field-container">
            <label
              htmlFor="subscription_slot"
              className="add-brand-modal__field-label"
            >
              Select available subscription:
            </label>
            <select
              id="subscription_slot"
              name="subscription_slot"
              className="add-brand-modal__field-input"
              onChange={(e) => setSlot(e.target.value)}
            >
              <option value={null}>Select a subscription...</option>
              {freeSlots.map((slot, key) => (
                <option value={slot.id} key={key}>
                  {slot.product_name}{' '}
                  {slot.available_quantity && `x${slot.available_quantity}`}
                </option>
              ))}
            </select>
            {slotError && (
              <span className="add-brand-modal__field-error">{slotError}</span>
            )}
          </div>
          <button
            type="submit"
            className={
              isLoading
                ? 'add-brand-modal__submit-btn add-brand-modal__submit-btn--disabled'
                : 'add-brand-modal__submit-btn'
            }
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Save'}
          </button>
        </form>
        <div className="modal-detail-plan__choose">
          <p className="modal-detail-plan__choose-text">
            Or
            <a
              className="modal-detail-plan__choose-link"
              onClick={() => setSection('PlanSelectionDetail')}
            >
              choose another plan
              <span className="modal-detail-plan__choose-link-arrow">
                <img
                  className="modal-detail-plan__choose-link-arrow-img"
                  src={`${staticUrl}/images/svg/modal-plan-arrow.svg`}
                  alt="arrow"
                />
              </span>
            </a>
          </p>
        </div>
      </div>
    )
  );
}

export default SlotSelectionForm;
