import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';
import TagList from '../../../tags/tagList';

/**
 * Component for rendering brand contact request details
 */
function ExpertDetailsComponent({ conversation }) {
  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">
        Expert Name:
      </h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-subject"
      >
        {conversation.getContentObject().expert.name}
      </span>
      <h3 className="conversation-detail-view__details-heading">Title:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-deadline"
      >
        {conversation.getContentObject().expert.title}
      </span>
      <h3 className="conversation-detail-view__details-heading">
        Certifications:
      </h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-media-name"
      >
        {conversation
          .getContentObject()
          .expert.certifications.map((cert) => cert.name)
          .join(', ')}
      </span>
      <h3 className="conversation-detail-view__details-heading">Expertise:</h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="brand-contact-media-name"
      >
        <TagList tags={conversation.getContentObject().expert.expert_tags} />
      </span>
    </div>
  );
}

ExpertDetailsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default ExpertDetailsComponent;
