function setCTAText(text) {
  const ctaTextSpan = document.getElementById('ctaType');
  if (ctaTextSpan) {
    ctaTextSpan.innerHTML = text;
  }
}

/**
 *  Toggles the signup dialog and dimmed background on/off.
 */
function toggleLoginSignupDialog() {
  const loginSignupModal = document.querySelector('.login-signup-modal');
  const loginSignupDialog = document.querySelector(
    '.login-signup-modal__dialog'
  );
  const loginSignupBackground = document.querySelector(
    '.login-signup-modal__background'
  );
  const modalContentElements = document.querySelectorAll(
    '.login-signup-modal__content'
  );
  const firstContent = document.getElementById('signInToContinue');

  if (loginSignupModal && loginSignupDialog && loginSignupBackground) {
    loginSignupModal.classList.remove('login-signup-modal--hidden');
    loginSignupDialog.classList.toggle('login-signup-modal__dialog--hidden');
    loginSignupBackground.classList.toggle(
      'login-signup-modal__background--hidden'
    );
  }

  // The code bellow removes the scrollbar from the body and sets the initial content
  if (
    loginSignupBackground.classList.contains(
      'login-signup-modal__background--hidden'
    )
  ) {
    document.body.classList.remove('share-dialog-open');
  } else {
    document.body.classList.add('share-dialog-open');

    if (firstContent && modalContentElements) {
      modalContentElements.forEach((element) =>
        element.classList.add('login-signup-modal__content--hidden')
      );
      firstContent.classList.remove('login-signup-modal__content--hidden');
    }
  }
}

/**
 * Track signup events with segment
 */
export function trackSignupEvent(signUp) {
  const trackObjectId = document.getElementById(
    'login-signup-dialog-track-object-id'
  );
  const trackObjectType = document.getElementById(
    'login-signup-dialog-track-object-type'
  );

  if (trackObjectId && trackObjectType) {
    const trackData = {
      origin_id: trackObjectId.value,
      origin_type: trackObjectType.value,
    };

    if (window.analytics) {
      window.analytics.track(
        signUp ? 'media-cta-signup' : 'media-cta-login',
        trackData
      );
    }

    // Hides every content
    const modalContentElements = document.querySelectorAll(
      '.login-signup-modal__content'
    );
    modalContentElements.forEach((element) =>
      element.classList.add('login-signup-modal__content--hidden')
    );

    // Display the proper content
    const content = signUp
      ? document.getElementById('signUpForm')
      : document.getElementById('signInForm');
    if (content)
      content.classList.remove('login-signup-modal__content--hidden');
  }
}

function startCTASignupFlow(text) {
  setCTAText(text);
  toggleLoginSignupDialog();
}

export function handleButtonClick(e) {
  const { title, sampleRequest, action } = e.target.dataset;
  const url = new URL(window.location);

  // updating the url to be used by the signup form
  if (sampleRequest !== undefined) {
    url.searchParams.set('requestSample', true);
    window.history.pushState({}, '', url);
  }

  if (action !== undefined) {
    url.searchParams.set('action', action);
    window.history.pushState({}, '', url);
  }

  // updating the url to be used by the signin form
  const form = document.getElementById('signInFormElement');
  if (form) {
    const actionURL = new URL(form.action);
    actionURL.searchParams.set('next', `${url.pathname}${url.search}`);
    form.action = actionURL.pathname + actionURL.search;
  }

  // updating the hidden input 'next' used by the social account sigin buttons form
  const nextInputs = document.getElementsByName('next');
  nextInputs.forEach((inputElement) => {
    // eslint-disable-next-line no-param-reassign
    inputElement.value = `${url.pathname}${url.search}`;
  });

  startCTASignupFlow(title, action);
}

export const closeModal = () => {
  const url = new URL(window.location);
  url.searchParams.delete('requestSample');
  url.searchParams.delete('action');
  window.history.pushState({}, '', url);
  toggleLoginSignupDialog();
};
