const demoLinks = document.getElementsByClassName('demo-link');

// Check if Calendly script is already loaded
function isCalendlyScriptLoaded() {
  return (
    document.querySelector(
      'script[src="https://assets.calendly.com/assets/external/widget.js"]'
    ) !== null
  );
}

// Inject Calendly script and CSS dynamically
function loadCalendlyScripts(callback) {
  if (!isCalendlyScriptLoaded()) {
    // Load Calendly CSS
    const calendlyCSS = document.createElement('link');
    calendlyCSS.rel = 'stylesheet';
    calendlyCSS.href = 'https://assets.calendly.com/assets/external/widget.css';
    document.head.appendChild(calendlyCSS);

    // Load Calendly JS
    const calendlyScript = document.createElement('script');
    calendlyScript.src =
      'https://assets.calendly.com/assets/external/widget.js';
    calendlyScript.async = true;
    calendlyScript.onload = callback; // Callback after script is loaded
    document.body.appendChild(calendlyScript);
  } else {
    // If script is already loaded, execute callback
    callback();
  }
}

function isCalendlyEvent(e) {
  return (
    e.origin === 'https://calendly.com' &&
    e.data.event &&
    e.data.event.indexOf('calendly.') === 0
  );
}

function demoRequest(e) {
  e.preventDefault();

  loadCalendlyScripts(() => {
    // Open Calendly after script has been loaded
    if (Calendly) {
      Calendly.initPopupWidget({
        url: 'https://calendly.com/presshook/demo?text_color=262121&primary_color=d2281a',
      });
    }

    // Track bookings to segment
    window.addEventListener('message', (message) => {
      if (isCalendlyEvent(message)) {
        if (message.data.event === 'calendly.event_scheduled') {
          const trackData = {
            event: message.data.payload && message.data.payload.event.uri,
            invitee: message.data.payload && message.data.payload.invitee.uri,
          };

          if (window.analytics) {
            window.analytics.track('Demo Booked', trackData);
          }
        }
      }
    });
  });

  return false;
}

Array.from(demoLinks).forEach((element) => {
  element.addEventListener('click', demoRequest);
});
