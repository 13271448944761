import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';
import calendar from '../../../../../../images/svg/calendar.svg';

/**
 * Sample request needed by component
 */
function SampleRequestNeededByComponent({ conversation }) {
  /*
  Date comes down as YYYY-MM-DD
  Chrome will parse as UTC, Firefox parses local.
  Add T12:00:00Z to force it to be recognized as noon UTC.
  */
  const neededByDate = new Date(
    `${conversation.getContentObject().samples_needed_by}T12:00:00Z`
  );

  // Neutralize time zone adjustment when converted to string, since the original date doesn't come with one
  neededByDate.setTime(
    neededByDate.getTime() + neededByDate.getTimezoneOffset() * 60 * 1000
  );

  return (
    <div>
      <h3 className="conversation-detail-view__details-heading">
        Send samples before:
      </h3>
      <span
        className="conversation-detail-view__details-text"
        data-testid="sample-request-needed-by"
      >
        <img
          className="conversation-detail-view__details-text-icon"
          src={calendar}
          alt="needed by date icon"
        />
        {neededByDate.toLocaleDateString()}
      </span>
    </div>
  );
}

SampleRequestNeededByComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default SampleRequestNeededByComponent;
