// Build navigation on page load and build user dropdown nav with brands and experts on htmx load
import {
  configAddBrandButton,
  configAddExpertButton,
  configAutoOpens,
} from './createBrandModal';

const closeDropdowns = (excludeDropdown = null) => {
  const activeDropdown = document.querySelector(
    '.navigation__dropdown--active'
  );
  if (activeDropdown && activeDropdown !== excludeDropdown) {
    activeDropdown.classList.remove('navigation__dropdown--active');
  }
};

const toggleChevron = (chevron, isUp) => {
  if (chevron) {
    chevron.classList.replace(
      isUp ? 'chevron--down' : 'chevron--up',
      isUp ? 'chevron--up' : 'chevron--down'
    );
  }
};

const loadNav = () => {
  const pages = [
    'Products',
    'Brands',
    'Guides',
    'Experts',
    'Media',
    'News',
    'Forum',
  ];

  pages.forEach((page) => {
    const navItem = document.getElementById(`nav${page}`);
    const mobileItem = document.getElementById(`mobile${page}`);

    if (window.location.href.includes(`/${page.toLowerCase()}/`)) {
      if (navItem) navItem.classList.add('navigation__link--active');
      if (mobileItem) mobileItem.classList.add('navigation__link--active');
    }
  });

  document.body.addEventListener('click', (e) => {
    const exceptions = [
      'navigation__dropdown--profile',
      'navigation__menu-item--explore',
      'navigation__menu-item--how-it-works',
      'navigation__link--pending-clock',
    ];

    if (exceptions.some((cls) => e.target.closest(`.${cls}`))) {
      return;
    }

    const checkProfileClick = e.target.getAttribute('alt');
    if (checkProfileClick === 'profile') return;

    closeDropdowns();
    toggleChevron(document.querySelector('.chevron--up'), false);
  });

  const dropDownLinks = document.querySelectorAll(
    '.navigation__menu-item--with-dropdown:not(.navigation__dropdown--profile)'
  );
  dropDownLinks.forEach((ddl) => {
    ddl.addEventListener('click', (e) => {
      const dropdown = e.currentTarget.querySelector('.navigation__dropdown');
      closeDropdowns(dropdown);

      if (dropdown) {
        dropdown.classList.toggle('navigation__dropdown--active');
        toggleChevron(
          ddl.querySelector('.chevron'),
          dropdown.classList.contains('navigation__dropdown--active')
        );
      }
    });
  });
};

const buildBrandsAndExperts = () => {
  const buildSelectedRow = (imageContainerVar, selectedItem) => {
    const imageContainer = imageContainerVar;
    const selectedImage = selectedItem.querySelector('.navigation__brand-img');
    const firstLetter = selectedItem.querySelector(
      '.navigation__brand-first-letter'
    );
    imageContainer.classList.remove('navigation__brand-img-container--icon');
    imageContainer.classList.add('navigation__brand-img-container--selected');
    imageContainer.innerHTML = `<img src="${staticUrl}/images/svg/done.svg" class="navigation__brand-img-selected-icon">`;

    if (selectedImage) {
      imageContainer.innerHTML += `
          <img alt="${selectedImage.getAttribute(
            'alt'
          )}" class="navigation__brand-img" height="72" src="${selectedImage.getAttribute(
        'src'
      )}" width="72">`;
    } else if (firstLetter) {
      imageContainer.innerHTML += `<span class="navigation__brand-first-letter">${selectedItem
        .getAttribute('data-title')
        .charAt(0)}</span>`;
    }
  };

  const handleMenuToggle = (listSelector, type, oppositeType = null) => {
    const arrow = document.querySelector('.navigation__arrow');
    const list = document.querySelectorAll(listSelector);

    arrow.classList.toggle('navigation__arrow--rotate-up');
    arrow.classList.toggle('navigation__arrow--rotate-down');
    list.forEach((item) =>
      item.classList.toggle('navigation__dropdown-item--hide')
    );

    if (oppositeType) {
      const oppositeClassName =
        oppositeType === 'experts'
          ? `.navigation__dropdown-item--${oppositeType}-list`
          : `.navigation__dropdown-item--list`;
      const oppositeList = document.querySelectorAll(oppositeClassName);
      oppositeList.forEach((item) =>
        item.classList.toggle('navigation__dropdown-item--hide')
      );
    }
  };

  const removeSelectedRow = (type, iconName, placeholderText) => {
    const element = document.querySelector(`.js-open-${type}`);
    if (element) {
      const title = element.querySelector('.navigation__brand-title');
      const image = element.querySelector('.navigation__brand-img-container');

      image.classList.toggle('navigation__brand-img-container--icon', true);
      image.classList.toggle(
        'navigation__brand-img-container--selected',
        false
      );
      image.innerHTML = `<img alt="${placeholderText}" src="${staticUrl}/images/svg/${iconName}-icon.svg" >`;
      title.innerHTML = placeholderText;
    }
  };

  const handleSelection = (items, type, oppositeType, itemType, itemName) => {
    items.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const { currentTarget } = e;

        handleMenuToggle(
          '.navigation__dropdown-item--list',
          'brands',
          'experts'
        );

        localStorage.setItem(
          `${itemType}_pk`,
          currentTarget.getAttribute('data-pk')
        );
        localStorage.setItem('last_selected', type);
        localStorage.setItem('slug', currentTarget.dataset.slug);

        const titleElement = document.querySelector(
          `.js-open-${type} .navigation__brand-title`
        );
        const imageContainer = document.querySelector(
          `.js-open-${type} .navigation__brand-img-container`
        );
        titleElement.innerHTML = currentTarget.getAttribute('data-title');
        titleElement.title = currentTarget.getAttribute('data-title');
        buildSelectedRow(imageContainer, currentTarget);
        removeSelectedRow(
          oppositeType,
          `my-${oppositeType}`,
          `My ${oppositeType}`
        );

        // Check the radio to show that is the selected one
        const radios =
          type === 'brands'
            ? document.getElementsByName('active-brand')
            : document.getElementsByName('active-expert');
        radios.forEach((radio) => {
          const theRadio = radio;
          if (radio.dataset.value === currentTarget.dataset.pk) {
            theRadio.checked = true;
          } else {
            theRadio.checked = false;
          }
        });

        // Controls if the view profile link should or should not be displayed
        const links = document.querySelectorAll('.navigation__dropdown-link');
        links.forEach((link) => {
          const theLink = link;
          if (link.dataset.type === 'profile') {
            if (currentTarget.dataset.live === 'False') {
              theLink.style.display = 'none';
            } else {
              theLink.style.display = 'block';
            }
          }
        });
      });
    });
  };

  const brands = document.querySelectorAll(
    '.navigation__dropdown-link--with-brands'
  );
  const experts = document.querySelectorAll(
    '.navigation__dropdown-link--with-experts'
  );

  if (brands.length) {
    handleSelection(brands, 'brands', 'experts', 'brand', 'Brands');
    document
      .querySelector('.js-open-brands')
      .addEventListener('click', () =>
        handleMenuToggle(
          '.navigation__dropdown-item--list',
          'brands',
          'experts'
        )
      );
  }

  if (experts.length) {
    handleSelection(experts, 'experts', 'brands', 'expert', 'Experts');
    document
      .querySelector('.js-open-experts')
      .addEventListener('click', () =>
        handleMenuToggle(
          '.navigation__dropdown-item--expert-list',
          'experts',
          'brands'
        )
      );
  }

  const brandLinks = document.querySelectorAll(
    '.navigation__dropdown-link--with-brand-links'
  );
  brandLinks.forEach((brand) => {
    brand.addEventListener('click', (e) => {
      e.preventDefault();
      const type = e.currentTarget.getAttribute('data-type');
      const brandpk = localStorage.getItem('brand_pk');
      const brandSlug = localStorage.getItem('slug');
      if (type === 'editor') {
        window.location.href = `/portal/brands/edit/${brandpk}/`;
      } else if (type === 'profile') {
        window.location.href = `/brands/${brandSlug}/`;
      } else {
        window.location.href = '/sample-requests/';
      }
    });
  });

  const preloadMenu = (items, itemType) => {
    items.forEach((item) => {
      if (item.dataset.pk === localStorage.getItem(`${itemType}_pk`)) {
        item.click();
      }
    });
  };

  if (localStorage.getItem('last_selected') === 'experts') {
    preloadMenu(brands, 'brand');
    preloadMenu(experts, 'expert');
  } else {
    preloadMenu(experts, 'expert');
    preloadMenu(brands, 'brand');
  }
};

// Attach listener for profile dropdown after htmx loads new content
document.body.addEventListener('htmx:load', (event) => {
  if (event.detail.elt.classList.contains('navigation__user-icon')) {
    buildBrandsAndExperts();
    attachProfileDropdownListener();
    configAddBrandButton();
    configAddExpertButton();
    configAutoOpens();
  }
});

// Call the loadNav function on page load
document.addEventListener('DOMContentLoaded', loadNav);
