// We need to loop thorugh each link contained in the search results here,
// adding a click event listener that sends analytics to segment.
if (window.location.href.includes('search')) {
  document
    .querySelectorAll(
      'ul.search-selector > ul > li > a, ul.search-selector > li > a, ul.search-selector > div > ul > li > a'
    )
    .forEach((link) => {
      link.addEventListener('click', (event) => {
        if (window.analytics) {
          const trackData = {
            // We're pulling dataset values from newly added data attributes on each link.
            object_type: link.dataset.type,
            object_title: link.dataset.title,
            object_url: link.getAttribute('href'),
            object_pk: link.dataset.pk,
          };
          window.analytics.track('Search Result Click', trackData);
        }
      });
    });
}
