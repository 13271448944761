import React from 'react';

const ModalContext = React.createContext();

export const ModalContextProvider = ({
  children,
  modalIsOpen,
  setModalIsOpen,
  onClose,
}) => {
  const [formIsChanged, setFormIsChanged] = React.useState(false);

  React.useEffect(() => {
    if (!modalIsOpen) {
      onClose(formIsChanged);
    }
  }, [modalIsOpen]);

  return (
    <ModalContext.Provider
      value={{ modalIsOpen, setModalIsOpen, formIsChanged, setFormIsChanged }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalContext);
