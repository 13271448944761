/**
 * Class that implements event listeners and event firing.
 */
export default class EventsClass {
  constructor() {
    this.eventHandlers = {};
  }

  /**
   * Register event listenr
   * @param {*} event event to listen for
   * @param {*} callback handler function
   */
  on(event, callback) {
    if (!this.eventHandlers[event]) {
      this.eventHandlers[event] = [];
    }
    this.eventHandlers[event].push(callback);
  }

  /**
   * Remove event listener
   * @param {*} event
   * @param {*} callback handler function to remove
   */
  off(event, callback) {
    if (!this.eventHandlers[event]) {
      return;
    }

    const toRemove = this.eventHandlers[event].indexOf(callback);

    if (toRemove === -1) {
      return;
    }

    this.eventHandlers[event].splice(toRemove, 1);
  }

  /**
   * Fire an event to listeners
   * @param {*} event event to fire
   * @param {*} data data to send
   */
  fireEvent(event, data) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event].forEach((handler) => {
        handler(data);
      });
    }
  }
}
