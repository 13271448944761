import React from 'react';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Brand to Brand Contact Requests tags component
 */
function BrandContactRequestBrandTagsComponent({ conversation }) {
  let brand;
  if (conversation.getModel() == 'mediacontactrequest') {
    brand = conversation.getContentObject().requested_by_brand;
  } else {
    brand = conversation.getContentObject().requested_by_brand_object;
  }

  return (
    <span
      className="conversation-detail-view__details-text"
      data-testid="brand-to-brand-request-tags"
    >
      <div className="tags tags--card tags--wrap">
        {brand.brand_tags.map((tag, index) => {
          return (
            <a
              href={`/brands/?tags=${tag.slug}`}
              target="_blank"
              className="tags__tag"
              key={index}
              data-testid="associated-brand-tags"
            >
              {tag.name}
            </a>
          );
        })}
      </div>
    </span>
  );
}

BrandContactRequestBrandTagsComponent.propTypes = {
  conversation: ChatPropTypes.Conversation.isRequired,
};

export default BrandContactRequestBrandTagsComponent;
