import React from 'react';
import { getImageURL } from '../../../utils/wagtailImageUtils';
import ChatPropTypes from '../../ChatPropTypes/ChatPropTypes';

/**
 * Component for rendering a list of associated brands for a
 * Brand to Brand contact request.
 */
function BrandContactRequestBrandsAssociatedComponent({ member }) {
  const brands = member ? member.getUser().getBrands() : [];
  return (
    <div>
      {brands.map((brand) => (
        <div
          key={brand.pk}
          className="conversation-associated-brands-list"
          data-testid="associated-brand"
        >
          <img
            className="conversation-associated-brands-list__brand-icon"
            data-testid="associated-brand-icon"
            src={getImageURL(brand?.logo)}
            alt={brand?.title}
          />
          <span
            className="conversation-associated-brands-list__brand-name"
            data-testid="associated-brand-name"
          >
            <a target="_blank" href={`/brands/${brand?.slug}/`} rel="noreferrer">
              {brand?.title}
            </a>
          </span>
        </div>
      ))}
    </div>
  );
}

BrandContactRequestBrandsAssociatedComponent.propTypes = {
  member: ChatPropTypes.ConversationMember.isRequired,
};

export default BrandContactRequestBrandsAssociatedComponent;
